import { Form, Typography, Checkbox } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React from 'react';
import { AdditionalOption } from '../../../../../../../../hooks/additionalOptions';
import { Material } from '../../../../../../../../hooks/materials';
import { Service } from '../../../../../../../../hooks/services';
import styles from '../index.module.less';

interface LabStaffServiceRow {
  id: string;
  isDisabled?: boolean;
  service: Service;
  additionalOptions?: AdditionalOption[];
  materials?: Material[];
}

const LabStaffServiceRow: React.FC<LabStaffServiceRow> = (props) => {
  const { id, isDisabled, service, materials, additionalOptions } = props;
  const form = useFormInstance();
  const options = useWatch(id, form);

  const isAdditionalOptionsDisabled = !options?.some((option: string) => (JSON.parse(option)?.type === 'material'));

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography.Title level={5}>{service.name}</Typography.Title>
      </div>
      <div className={styles.content}>
        <Form.Item name={id} className="no-space-form-item">
          <Checkbox.Group>
            {materials?.length ? materials?.map((option) => (
              <Checkbox
                key={option.id}
                value={JSON.stringify({ id: option.id, type: 'material' })}
                style={{ padding: 5, margin: 0 }}
                disabled={isDisabled}
              >
                {option.material.name}
              </Checkbox>
            )) : <Typography style={{ color: 'var(--color-text-disabled)' }}>No active material found!</Typography>}
            {additionalOptions?.map((option) => (
              <Checkbox
                key={option.id}
                value={JSON.stringify({ id: option.id, type: 'additionalOption' })}
                style={{ padding: 5, margin: 0 }}
                disabled={isDisabled || isAdditionalOptionsDisabled}
              >
                {option.additionalOption.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </div>
    </div>

  );
};

LabStaffServiceRow.defaultProps = {
  isDisabled: false,
  additionalOptions: undefined,
  materials: undefined,
};

export default LabStaffServiceRow;
