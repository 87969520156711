import { Button, Col, Form, message, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from '../../../../../../store';
import { Error } from '../../../../../../store/ducks/common';
import {
  createLabStaff as actionCreateLabStaff,
  CreateLabStaff,
  moduleName,
} from '../../../../../../store/ducks/labStaff';
import { RootState } from '../../../../../../store/reducers';
import { isRoleEnough } from '../../../../../../utils/auth';
import DrawerModal from '../../../../../Common/DrawerModal';
import { Send } from '../../../../../Common/Icon';
import LabStaffInfoForm from '../Forms/LabStaffInfo';
import { UserRoles } from '../../../../../../enums/user';
import { DefaultModalProps } from '../../../../../../types';
import { prepareLabStaffBody } from '../../../../../../utils/prepareData';
import LabStaffServices from '../Forms/LabStaffServices';

export interface LabStaffCreateDrawer extends DefaultModalProps {
  isManager?: boolean;
  labId?: string;
  // redux props \/
  createLabStaff: (payload: CreateLabStaff) => Action;
  labStaffLoading: boolean;
  labStaffError: Error | null;
  labStaffCreateError: Error | null;
}

const LabStaffCreateDrawer: React.FC<LabStaffCreateDrawer> = React.memo((props) => {
  const {
    labId, isOpen, close, createLabStaff, labStaffLoading, labStaffError, labStaffCreateError, isManager, onReload,
  } = props;

  const [form] = Form.useForm();
  const [servicesForm] = Form.useForm();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleSubmit = () => {
    form.validateFields().then((staffValues) => {
      const servicesValues = servicesForm.getFieldsValue();

      const body = prepareLabStaffBody(
        staffValues,
        isManager ? UserRoles.labManager : UserRoles.labStaff,
        staffValues?.userId,
        servicesValues,
      );

      return createLabStaff(body);
    });
  };

  useEffect(() => {
    if (!labStaffLoading && !labStaffError && isOpen) {
      close();
      onReload?.();
      message.success('Staff has been created!');
    }
  }, [labStaffLoading]);

  useEffect(() => {
    if (labStaffCreateError?.message) {
      message.error(labStaffCreateError.message, 5);
    }
  }, [labStaffCreateError]);

  return (
    <DrawerModal
      title={isManager ? 'Add New Lab Manager' : 'Add New Lab Staff'}
      open={isOpen}
      onClose={close}
      extra={(
        <Space>
          <Button
            id="sendRegistrationLink"
            type="primary"
            icon={<Icon component={Send} />}
            disabled={!isFormValid}
            onClick={handleSubmit}
            loading={labStaffLoading}
          >
            Send Registration Link
          </Button>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => form.resetFields()}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="staff_create"
              layout="vertical"
              autoComplete="off"
              initialValues={{ lab: labId || undefined }}
              onFieldsChange={() => setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length))}
            >
              <LabStaffInfoForm formType="create" labId={labId} />
              {!isManager && isRoleEnough('lab staff', true) && (
                <LabStaffServices form={servicesForm} labId={labId} />
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </DrawerModal>

  );
});

LabStaffCreateDrawer.displayName = 'LabStaffCreateDrawer';

LabStaffCreateDrawer.defaultProps = {
  isManager: false,
  onReload: undefined,
};

const mapStateToProps = (state: RootState) => ({
  labStaffLoading: state[moduleName].labStaffLoading,
  labStaffError: state[moduleName].labStaffError,
  labStaffCreateError: state[moduleName].labStaffCreateError,
});

const mapDispatchToProps = {
  createLabStaff: actionCreateLabStaff,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabStaffCreateDrawer);
