import { PlusOutlined } from '@ant-design/icons/lib';
import { Button, Form, Space, Typography } from 'antd';
import React from 'react';
import OptionRow from '../OptionRow';
import Prices from '../Prices';

interface Materials {
  name: string;
  serviceName: string;
  isEditing?: boolean;
  withPrices?: boolean;
  hideToggle?: boolean;
  withoutAdding?: boolean;
  nameDisabled?: boolean;
  hidePrice?: boolean;
  materialsRowExtra?: React.ReactNode;
}

const Materials = (props: Materials) => {
  const {
    name, isEditing, serviceName, withPrices, hideToggle, withoutAdding, nameDisabled, hidePrice,
    materialsRowExtra,
  } = props;

  return (
    <Space direction="vertical">
      <Form.List name={name}>
        {(fields, { add }) => (
          <>
            {!!fields.length && fields.map(({ key, name: fieldName, ...restField }) => (
              <div key={key}>
                <OptionRow
                  name={fieldName}
                  restField={restField}
                  isEditing={isEditing}
                  singleType={serviceName !== 'Printing'}
                  type={serviceName !== 'Printing' ? 'per tooth' : ''}
                  nameDisabled={nameDisabled}
                  hidePrice={hidePrice}
                  hideToggle={hideToggle}
                />
                {withPrices && (
                <Form.Item shouldUpdate style={{ width: '100%' }} className="no-space-form-item">
                  {(form) => (
                    <Form.Item className="no-space-form-item">
                      <Prices
                        name={fieldName}
                        restField={restField}
                        isEditing={isEditing}
                        prices={form.getFieldValue(name)?.[fieldName]?.prices}
                        materialsView
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                )}
              </div>
            ))}
            {!!materialsRowExtra && (
              <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                  {materialsRowExtra}
              </div>
            )}
            {!fields.length && !isEditing && (
            <Space style={{ width: '100%' }}>
              <Typography style={{ color: 'var(--color-text-disabled)' }}>No Active Materials</Typography>
            </Space>
            )}
            {!withoutAdding && isEditing && serviceName === 'Printing' && (
            <Form.Item style={{ width: '100%' }}>
              <Button
                type="ghost"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add Material
              </Button>
            </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Space>
  );
};

Materials.defaultProps = {
  isEditing: false,
  withPrices: false,
  hideToggle: false,
  withoutAdding: false,
  nameDisabled: false,
  hidePrice: false,
  materialsRowExtra: undefined,
};

export default Materials;
