import { Button } from 'antd';
import React, { useState } from 'react';
import { JsonResult } from '../../../../types';
import CaseCreate from '../Create';
import AdminCaseCreate from '../AdminCreate';
import { isRoleEnough } from '../../../../utils/auth';

interface CaseCreateButton {
  title: string;
  id: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  type: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text';
  style?: JsonResult;
  isAdmin?: boolean;
}

const CaseCreateButton = (props: CaseCreateButton) => {
  const { title, id, type, children, icon, disabled, style, isAdmin } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        id={id}
        type={type}
        icon={icon}
        disabled={disabled}
        onClick={() => setIsDrawerOpen(true)}
        style={style}
      >
        {children}
      </Button>
      {isRoleEnough('admin') ? (
        <AdminCaseCreate
          title={title}
          drawerOpen={isDrawerOpen}
          close={() => setIsDrawerOpen(false)}
          isAdmin={isAdmin}
        />
      ) : (
        <CaseCreate
          title={title}
          drawerOpen={isDrawerOpen}
          close={() => setIsDrawerOpen(false)}
          isAdmin={isAdmin}
        />
      )}
    </>
  );
};

CaseCreateButton.defaultProps = {
  icon: null,
  disabled: false,
  style: {},
  isAdmin: false,
};

export default CaseCreateButton;
