import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';

import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import { ArrowLeft, Send } from '../../../Common/Icon';
import { RootState } from '../../../../store/reducers';
import { moduleName, cancelCase as actionCancelCase, CancelCase } from '../../../../store/ducks/cases';
import { Error as ErrorCommon } from '../../../../store/ducks/common';
import { Action } from '../../../../store';
import { required } from '../../../../utils/inputRules';

interface Cancellation {
  id: number | string;
  close: () => void;
  isOpen: boolean;
  cancelCase: (payload: CancelCase) => Action;
  caseCancelLoading: boolean;
  caseCancelError: ErrorCommon | null;
}

const Cancellation: React.FC<Cancellation> = (props): JSX.Element => {
  const { isOpen, close, id, cancelCase, caseCancelLoading, caseCancelError } = props;
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      cancelCase({ ...values, id: `${id}` });
    });
  };

  useEffect(() => {
    if (!caseCancelLoading && !caseCancelError && isOpen) {
      message.success('Case was canceled!');
      close();
    }
  }, [caseCancelLoading]);

  useEffect(() => {
    if (caseCancelError?.message && isOpen) {
      message.error(caseCancelError?.message);
    }
  }, [caseCancelError]);

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      title="Case Cancellation"
      okText="Send Cancellation"
      okButtonProps={{
        icon: <Icon component={Send} />,
        id: 'sendCancellationBtn',
      }}
      cancelText="Back"
      cancelButtonProps={{
        icon: <Icon component={ArrowLeft} />,
        id: 'backBtn',
      }}
      onCancel={close}
      afterClose={form.resetFields}
      confirmLoading={caseCancelLoading}
      bodyStyle={{ padding: '12px 24px' }}
      destroyOnClose
      centered
    >
      <Form form={form} name="case_cancellation" layout="vertical">
        <Form.Item
          label="Add cancellation reason"
          name="cancellationReason"
          rules={required}
        >
          <Input.TextArea
            rows={4}
            placeholder="Type here"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  caseCancelLoading: state[moduleName].caseCancelLoading,
  caseCancelError: state[moduleName].caseCancelError,
});

const mapDispatchToProps = {
  cancelCase: actionCancelCase,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cancellation);
