import { EditOutlined, LinkOutlined } from '@ant-design/icons/lib';
import { Button, Col, Collapse, Descriptions, Dropdown, Form, message, Row, Space } from 'antd';
import { DownOutlined, SettingFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { MenuItemGroupType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import { UploadFile } from 'antd/lib/upload/interface';
import { CaseServiceStatus, CaseStatus } from '../../../../enums/case';
import { moduleName as casesModule } from '../../../../store/ducks/cases';
import { moduleName as authModuleName, User } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';

import { prepareCaseInitialData } from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Close } from '../../../Common/Icon';
import { Error as ErrorCommon } from '../../../../store/ducks/common';
import { initialTeethValues, useContextCaseFlow } from '../../../../context/caseFlow';
import ServiceFilesContent from '../../../Common/ServiceFilesContent';
import AssignStaff from '../AssignStaff';
import SummaryInfoForm from '../Forms/SummaryInfoForm';
import Loading from '../../../Common/Loading';
import RenderExpandIcon from '../../../Common/ExpandIcon';
import ServiceCard from '../../../Common/ServiceCard';
import CaseUpdate from '../Update';
import AssignLab from '../AssignLab';
import { isRoleEnough } from '../../../../utils/auth';
import Cancellation from '../Cancellation';
import TableLoggers from '../../../Common/TableLoggers';
import { isCaseServiceStatusEnough, isCaseStatusEnough, isDateInPast } from '../../../../utils';
import { UserRole } from '../../../../enums/user';
import Comments from '../../../Common/Comments';
import { useContextFileDownload } from '../../../../context/fileDownload';
import confirm from '../../../Common/ModalConfirm';
import { getCaseStatusLabel } from '../Table';
import DateColorStatus from '../../../Common/DateColorStatus';
import { useDeleteCaseFile, useUploadCaseFile } from '../../../../hooks/cases';
import { getMessageInError } from '../../../../hooks/fetch';
import PracticeNotificationsReceivers from '../NotificationsReceivers/PracticeView';
import LabNotificationsReceivers from '../NotificationsReceivers/LabView';
import { UploadingFile, IPercentCompleted } from '../../../Common/AttachmentLoader';

export interface DescriptionItem {
  label: string;
  content: string | JSX.Element;
  title?: string;
  roleNeeded?: UserRole;
}

export const stylesClipDesc: React.CSSProperties = {
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  margin: 'auto',
};

export const getCaseServiceStatusLabel = (caseServiceStatus?: CaseServiceStatus, unassignedIfNoStatus = true) => {
  if (caseServiceStatus === 'pending') {
    return 'Pending approval';
  }
  if (caseServiceStatus === 'ready for approval') {
    return 'Preparing files';
  }

  if (unassignedIfNoStatus) {
    return caseServiceStatus || 'unassigned';
  }

  return caseServiceStatus;
};

interface CaseInfo {
  id: string | number;
  onReload?: () => void;
  caseStatus?: CaseStatus;
  title: string;
  drawerOpen: boolean;
  close: () => void;
  isAdmin?: boolean;
  disabled?: {
    [key: string]: boolean;
  };
  loading: boolean;
  error: ErrorCommon | null;
  user: User | null;
  caseCancelLoading: boolean;
  caseCancelError: ErrorCommon | null;
  style?: React.CSSProperties;
}

const CaseInfo = (props: CaseInfo): JSX.Element => {
  const {
    id,
    onReload,
    drawerOpen,
    close,
    isAdmin,
    disabled,
    user,
    loading,
    error,
    caseCancelLoading,
    caseCancelError,
    style,
    caseStatus,
  } = props;

  const { isAllDownloaded, setIsAllDownloaded } = useContextFileDownload();

  const {
    foundPrintingService,
    // Handle context Form values
    setPatientInfo,
    setSelectTreatmentPlan,
    setTeethValues,
    setHistoryTeethValues,
    setFutureTeethValues,
    setImplantInfo,
    setPrintingInfo,
    materialImplantId,
    materialCrownId,
    // Requests byId
    caseByIdInContext,
    clearContextData,
    getCaseServiceByPriceLevelServiceId,
    getServiceNameByPriceLevelServiceId,
  } = useContextCaseFlow();
  const [searchParams] = useSearchParams();

  const [patientSelectForm] = Form.useForm();
  const [selectTreatmentPlanForm] = Form.useForm();
  const [teethTreatmentPlanForm] = Form.useForm();
  const [implantInfoForm] = Form.useForm();
  const [printingInfoForm] = Form.useForm();
  const [summaryInfoForm] = Form.useForm();

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isAssignLabModalOpen, setIsAssignLabModalOpen] = useState<boolean>(false);
  const [isAssignStaffModalOpen, setIsAssignStaffModalOpen] = useState<boolean>(false);

  /** Get case info by id */
  const fetchCaseById = () => {
    caseByIdInContext?.fetch(undefined, id);
  };

  const [triggerReload, setTriggerReload] = useState<number | undefined>();

  useEffect(() => {
    if (id && drawerOpen && !loading && !error) {
      fetchCaseById();
      setTriggerReload(Date.now());
    }
  }, [id, loading]);

  const initialValues = {
    ...prepareCaseInitialData(caseByIdInContext?.data),
  };

  const updateInitialValues = () => {
    setPatientInfo(initialValues.patientInfo || {});
    setSelectTreatmentPlan(initialValues.selectTreatmentPlan || {});
    setTeethValues(initialValues.teethValues || []);
    setImplantInfo(initialValues.implantInfo || {});
    setPrintingInfo(initialValues.printingInfo || {});
    summaryInfoForm.setFieldsValue(initialValues.summaryInfo);
  };

  useEffect(() => {
    if (caseByIdInContext?.data && !caseByIdInContext.error) {
      updateInitialValues();
    }
  }, [caseByIdInContext?.data]);

  /** Handling close/next/back/submit buttons  */
  const handleClose = () => {
    onReload?.();
    close();
    patientSelectForm.resetFields();
    selectTreatmentPlanForm.resetFields();
    teethTreatmentPlanForm.resetFields();
    setTeethValues(initialTeethValues);
    setHistoryTeethValues([]);
    setFutureTeethValues([]);
    implantInfoForm.resetFields();
    printingInfoForm.resetFields();
    summaryInfoForm.resetFields();
    clearContextData();
    setIsEditModalOpen(false);
    setIsAssignLabModalOpen(false);
    setIsAssignStaffModalOpen(false);
    caseByIdInContext?.clearResponse();
  };

  const confirmFileNotDownloaded = (action: 'downloading' | 'uploading') => confirm({
    onOk: () => handleClose(),
    title: 'Confirmation',
    content: `The file ${action} is still in progress. Do you want leave this page?`,
    okText: 'Yes',
    cancelText: 'No',
  });

  useEffect(() => {
    if (error?.message && drawerOpen && !isEditModalOpen && !isAssignLabModalOpen) {
      message.error(error.message, 5);
    }
  }, [error]);

  const descriptionItems: DescriptionItem[] = [
    {
      label: 'Patient',
      content: `${caseByIdInContext?.data?.patient?.firstName || ''} ${
        caseByIdInContext?.data?.patient?.lastName || ''}`,
    },
    {
      label: 'Practice',
      content: caseByIdInContext?.data?.practice?.name || '',
      roleNeeded: 'lab staff',
    },
    {
      label: 'Due Date',
      content: <DateColorStatus date={caseByIdInContext?.data?.dueDate} format="MMM DD, YYYY" />,
      title: moment(caseByIdInContext?.data?.dueDate)?.format('MMM DD, YYYY'),
    },
    {
      label: 'Doctor',
      content: `${caseByIdInContext?.data?.doctor?.user?.firstName || ''} ${
        caseByIdInContext?.data?.doctor?.user?.lastName || ''}`,
      roleNeeded: 'lab staff',
    },
  ];

  const menuItems: (MenuItemType | SubMenuType | MenuItemGroupType)[] = [
    {
      label: 'Edit Case',
      key: '1',
      icon: <EditOutlined />,
      onClick: caseByIdInContext?.data?.status !== 'draft' && !isRoleEnough('admin')
        ? () => message.warning('You can edit the case only while it is in \'New\' status.', 5)
        : () => setIsEditModalOpen(true),
      className: 'editCaseBtn',
    },
    {
      label: 'Cancel Case',
      key: '2',
      icon: <Close />,
      onClick: isCaseStatusEnough(caseByIdInContext?.data?.status, 'in progress') && !isRoleEnough('admin')
        ? () => message.warning('It is impossible to cancel the case while it is in progress. '
          + 'Please contact support for assistance.', 6)
        : () => setIsCancelModalOpen(true),
      className: 'cancelCaseBtn',
    },
  ];

  useEffect(() => {
    /** When canceling case, close his modal */
    if (!caseCancelLoading && !caseCancelError && drawerOpen) {
      close();
    }
  }, [caseCancelLoading]);

  /** Upload file from infoModal view logic */
  const uploadCaseFile = useUploadCaseFile((progressEvent) => {
    setPercentCompleted((prevState) => {
      const prevStateCurrentFile = prevState.find((item) => (
        item.fileSizeBytes === progressEvent.total
      ));
      const newPercentValue = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      return (
        [
          ...prevState.filter((item) => item.fileSizeBytes !== progressEvent.total),
          {
            fileSizeBytes: progressEvent.total,
            percent: prevStateCurrentFile && (newPercentValue < prevStateCurrentFile.percent)
              ? prevStateCurrentFile?.percent : newPercentValue,
          },
        ]
      );
    });
  });
  const deleteFile = useDeleteCaseFile();

  const [filesLoading, setFilesLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [clearFilesStateTrigger, setClearFilesStateTrigger] = useState(false);

  const resetFilesTriggerState = () => {
    setClearFilesStateTrigger(false);
  };

  const handleFilesUpdate = (files: UploadFile[]) => {
    setUploadedFiles(files);
  };

  const handleFileDelete = async (fileId: string) => {
    await deleteFile.fetch(`${id}/file/${fileId}`);

    if (!deleteFile?.error) {
      fetchCaseById();
    }
  };

  const [wLoadingUploadingFiles, setWLoadingUploadingFiles] = useState<UploadingFile[]>([]);
  const [percentCompleted, setPercentCompleted] = useState<IPercentCompleted[]>([]);

  const handleSubmitFiles = () => {
    const uploadingFiles = uploadedFiles.map((file) => ({ ...file, loading: true }));

    setWLoadingUploadingFiles(uploadingFiles);
    setIsAllDownloaded(false);

    setFilesLoading(true);
    Promise.all([
      ...uploadedFiles.map((file) => {
        const formData = new FormData();

        if (file.originFileObj) {
          formData.append('file', file.originFileObj);
        }

        return uploadCaseFile.fetch(formData, `${id}/file`).then(() => {
          const currentFile = uploadingFiles.find((uploadingFile) => uploadingFile.uid === file.uid);
          const currentFileIndex = currentFile ? uploadingFiles.indexOf(currentFile) : -1;

          if (currentFile && currentFileIndex >= 0) {
            uploadingFiles.splice(currentFileIndex, 1, { ...currentFile, loading: false });
          }

          setWLoadingUploadingFiles(uploadingFiles);
        });
      }),
    ])?.then(() => {
      setUploadedFiles([]);
      setClearFilesStateTrigger(true);
      fetchCaseById();
    }).finally(() => {
      setIsAllDownloaded(true);
      setFilesLoading(false);
      setPercentCompleted([]);
      setWLoadingUploadingFiles([]);
    });
  };

  useEffect(() => {
    if (uploadCaseFile.error) {
      message.error(getMessageInError(uploadCaseFile.error));
      uploadCaseFile.clearError();
    }
  }, [uploadCaseFile.error]);

  return (
    <div>
      {isRoleEnough('doctor') && (
        <Cancellation id={id} close={() => setIsCancelModalOpen(false)} isOpen={isCancelModalOpen} />
      )}
      <CaseUpdate
        id={id}
        title={`Update Case #${caseByIdInContext?.data?.id || 'Info'}`}
        isOpen={isEditModalOpen}
        close={() => {
          setTeethValues(caseByIdInContext?.data?.teeth.teeth || []);
          setIsEditModalOpen(false);
          updateInitialValues();
        }}
        isAdmin={isAdmin}
      />
      <AssignLab
        id={id}
        title="Assign Lab"
        isOpen={isAssignLabModalOpen}
        close={() => {
          setIsAssignLabModalOpen(false);
          updateInitialValues();
        }}
      />
      <AssignStaff
        id={id}
        title="Assign Staff"
        isOpen={isAssignStaffModalOpen}
        close={() => {
          setIsAssignStaffModalOpen(false);
          updateInitialValues();
        }}
      />

      <DrawerModal
        title={`Case #${caseByIdInContext?.data?.id || 'Info'}`}
        status={caseByIdInContext?.data?.status
          && getCaseStatusLabel(caseByIdInContext.data.status, caseByIdInContext.data?.caseServices)}
        open={drawerOpen}
        onClose={isAllDownloaded ? handleClose
          : () => confirmFileNotDownloaded(wLoadingUploadingFiles.length ? 'uploading' : 'downloading')}
        destroyOnClose
        contentWrapperStyle={style}
        viewbarContent={caseByIdInContext?.data ? (
          <Row justify="center" style={{ maxWidth: '520px' }}>
            <Col span={24}>
              <Descriptions
                size="small"
                column={2}
                labelStyle={{ color: 'var(--color-main-dark-gray)', opacity: '0.7', fontSize: '14px' }}
                contentStyle={{ color: 'var(--color-main-dark-gray)', fontWeight: '500', fontSize: '15px' }}
              >
                {descriptionItems
                  .filter(({ roleNeeded }) => isRoleEnough(roleNeeded || 'lab staff'))
                  .map(({ label, content, title }) => (
                    <Descriptions.Item key={label} label={label} contentStyle={stylesClipDesc}>
                      <span title={!title && typeof content === 'string' ? content : title}>{content}</span>
                    </Descriptions.Item>
                  ))}
              </Descriptions>
            </Col>
          </Row>
        ) : undefined}
        extra={isRoleEnough('lab manager') && caseByIdInContext?.data?.status !== 'cancel'
          && caseByIdInContext?.data?.status !== 'completed' && (
          <Space>
            {isRoleEnough('doctor')
              && (user?.role === 'doctor' ? caseByIdInContext?.data?.doctor?.id === user?.doctor?.id : true)
              && (
              <Dropdown
                trigger={['click']}
                key="button"
                menu={{ items: menuItems }}
              >
                <Button type="ghost" onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
                  <Space>
                    <SettingFilled />
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              )}
            {isRoleEnough('admin') && (
              <Button
                id="assignLabButton"
                type="primary"
                icon={<LinkOutlined />}
                onClick={() => setIsAssignLabModalOpen(true)}
                disabled={isDateInPast(caseByIdInContext?.data?.dueDate)}
              >
                Assign Lab
              </Button>
            )}
            {isRoleEnough(['lab manager', 'admin', 'root']) && (
              <Button
                id="assignStaffButton"
                type="primary"
                icon={<LinkOutlined />}
                onClick={() => setIsAssignStaffModalOpen(true)}
                disabled={!caseByIdInContext?.data?.caseServices?.find((service) => (
                  !isRoleEnough('admin')
                    ? (user?.labStaff?.lab?.id === service?.labService?.lab?.id
                      && isCaseServiceStatusEnough(service?.status, 'assigning'))
                    : isCaseServiceStatusEnough(service?.status, 'assigning')
                )) || isDateInPast(caseByIdInContext?.data?.dueDate)}
              >
                Assign Staff
              </Button>
            )}
          </Space>
        )}
      >
        <Row justify="center">
          <Loading visible={!!caseByIdInContext?.loading} absolute />
          <Col
            span={24}
            md={22}
            lg={20}
            xxl={16}
          >
            <div>
              <Collapse
                bordered={false}
                className="custom-collapse-view"
                defaultActiveKey={!isCaseStatusEnough(caseStatus, 'assigning')
                  ? [searchParams.get('openTab') || 'summary'] : [searchParams.get('openTab') || 'planning']}
                expandIcon={RenderExpandIcon}
              >
                <Collapse.Panel key="summary" header="Summary">
                  <SummaryInfoForm
                    type="info"
                    form={summaryInfoForm}
                    isAdmin={isAdmin}
                    disabled={disabled?.summaryInfo
                      || (user?.role === 'doctor' ? caseByIdInContext?.data?.doctor?.id !== user?.doctor?.id : false)}
                    initialData={initialValues?.summaryInfo}
                    isInfoView
                    handleFilesUpdate={handleFilesUpdate}
                    handleFileDelete={handleFileDelete}
                    uploadFilesCall={handleSubmitFiles}
                    filesUploadProps={{
                      clearFilesStateTrigger,
                      resetFilesTriggerState,
                      buttonLoading: filesLoading,
                      buttonDisabled: !uploadedFiles.length,
                    }}
                    uploadingFiles={wLoadingUploadingFiles.length ? wLoadingUploadingFiles : undefined}
                    percentCompleted={percentCompleted}
                  />
                </Collapse.Panel>
                <Collapse.Panel key="planning" header="Planning">
                  <Row gutter={[20, 20]} style={{ padding: '0 30px' }}>
                    {caseByIdInContext?.data?.teeth?.services
                      ?.filter((priceService) => (
                        getServiceNameByPriceLevelServiceId(priceService?.id)?.toLowerCase() !== 'printing'))
                      ?.map((priceService) => {
                        const caseService = getCaseServiceByPriceLevelServiceId(priceService?.id);

                        return (
                          <Col key={priceService?.id} span={24}>
                            <ServiceCard
                              title={getServiceNameByPriceLevelServiceId(priceService?.id)}
                              secondaryColorScheme
                              status={getCaseServiceStatusLabel(caseService?.status)}
                              filesContent={(
                                <ServiceFilesContent
                                  caseId={id}
                                  service={caseService}
                                  isProposalView={caseService?.status === 'request'
                                  && isRoleEnough('lab manager', true)
                                  && caseService?.labService?.lab?.id === user?.labStaff?.lab?.id}
                                />
                              )}
                              dueDateExtra={!!caseService?.dueDate
                              && (
                                <DateColorStatus
                                  beforeContent="Due Date"
                                  date={caseService?.dueDate}
                                  format="MMM DD, YYYY"
                                />
                              )}
                              hideDueDateDefaultLabel
                            />
                          </Col>
                        );
                      }) || 'Planning info'}
                  </Row>
                </Collapse.Panel>
                {caseByIdInContext?.data?.teeth?.services.map((service) => service.id)
                  ?.includes(foundPrintingService?.id || '') && (
                  <Collapse.Panel key="printing" header="Printing">
                    Printing
                  </Collapse.Panel>
                )}
                <Collapse.Panel key="comments" header="Comments">
                  <Comments caseId={id} caseStatus={caseByIdInContext?.data?.status} />
                </Collapse.Panel>
                {isRoleEnough('doctor') && (
                  <Collapse.Panel key="notifications" header="Additional Practice notification receivers">
                    <PracticeNotificationsReceivers
                      caseId={id}
                      caseStatus={caseByIdInContext?.data?.status}
                      view="doctor"
                    />
                  </Collapse.Panel>
                )}
                {isRoleEnough(['lab staff', 'lab manager', 'admin', 'root']) && (
                  <Collapse.Panel key="lab-notifications" header="Additional Lab notification receivers">
                    <LabNotificationsReceivers
                      caseServices={caseByIdInContext?.data?.caseServices || []}
                      caseStatus={caseByIdInContext?.data?.status}
                      view="labStaff"
                    />
                  </Collapse.Panel>
                )}
                {isRoleEnough('doctor') && (
                  <Collapse.Panel key="logs" header="Logs">
                    <TableLoggers
                      entity="cases"
                      entityId={`${id}`}
                      isExpandable
                      hideTitle
                      triggerReload={triggerReload}
                      caseAdditionalData={{
                        materialImplantId,
                        materialCrownId,
                        servicesData: caseByIdInContext?.data?.practice?.priceLevelServices,
                      }}
                    />
                  </Collapse.Panel>
                )}
              </Collapse>
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[casesModule].caseLoading,
  error: state[casesModule].caseError,
  caseCancelLoading: state[casesModule].caseCancelLoading,
  caseCancelError: state[casesModule].caseCancelError,
  user: state[authModuleName].user,
});

const mapDispatchToProps = {};

CaseInfo.defaultProps = {
  caseStatus: undefined,
  onReload: undefined,
  isAdmin: false,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
  style: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseInfo);
