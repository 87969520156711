import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { PageHeader, Space } from 'antd';
import { CalendarFilled } from '@ant-design/icons/lib';
import CalendarList from './CalendarList';
import CreateEvent from './CreateEvent';

const Calendar: React.FC = () => (
  <Content>
    <PageHeader
      className="profile-header-content"
      title={(
        <Space size={10}>
          <CalendarFilled />
          Events
        </Space>
        )}
    />
    <CreateEvent />
    <CalendarList />
  </Content>
);

export default Calendar;
