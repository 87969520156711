import React, { useState } from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';

import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode, { PropEditMode } from '../../../../Common/ControlsEditMode';
import { email, confirmPassword, phoneNumber, required, password } from '../../../../../utils/inputRules';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';

interface AdminInfoForm {
  isProfileView?: boolean;
  admin?: boolean;
  cardLoading?: boolean;
  formType?: 'create' | 'update';
  editMode?: PropEditMode;
  hasPassword?: boolean;
  disableForm?: boolean;
}

const AdminInfoForm: React.FC<AdminInfoForm> = (props) => {
  const { isProfileView, formType, cardLoading, editMode, hasPassword, disableForm } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = disableForm || !!(editMode && !isEditing);

  const handleCancel = () => {
    if (!editMode) return;
    editMode.onCancel();

    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!editMode) return;

    editMode.onSubmit();
    setIsEditing(false);
  };

  return (
    <div>
      {!isProfileView && (<Typography.Title level={4}>Personal Information</Typography.Title>)}
      <ContentCard loading={cardLoading}>
        <Row gutter={15}>
          <Form.Item name="id" style={{ height: 0, margin: 0 }}>
            <Input type="hidden" disabled />
          </Form.Item>

          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[...email, ...required]}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Phone"
              name="phone"
              rules={[...required, ...phoneNumber]}
            >
              <PhoneNumberInput disabled={isDisabledField} />
            </Form.Item>
          </Col>

        </Row>
        {hasPassword && (
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[...password]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type here"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[...required, ...confirmPassword]}
                dependencies={['password']}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type here"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {editMode && !disableForm && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleSubmit}
            isFormValid={typeof editMode.isFormValid !== 'undefined' ? editMode.isFormValid : true}
            loading={editMode.loading}
          />
        )}
      </ContentCard>
    </div>
  );
};

AdminInfoForm.defaultProps = {
  isProfileView: false,
  admin: false,
  formType: 'create',
  cardLoading: false,
  editMode: undefined,
  hasPassword: false,
  disableForm: false,
};

export default AdminInfoForm;
