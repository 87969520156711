import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import { Button, Col, Descriptions, Form, message, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Action } from '../../../../store';
import {
  createCase as actionCreateCase,
  CreateCase,
  moduleName as casesModule,
} from '../../../../store/ducks/cases';
import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { JsonResult } from '../../../../types';
import { prepareCaseBody, prepareCaseCalcPriceBody } from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Send } from '../../../Common/Icon';
import { Error as ErrorCommon } from '../../../../store/ducks/common';
import PatientSelectForm from '../Forms/PatientSelectForm';
import SelectTreatmentPlanForm from '../Forms/SelectTreatmentPlanForm';
import TeethTreatmentPlanForm from '../Forms/TeethTreatmentPlanForm';
import ImplantInfoForm from '../Forms/ImplantInfoForm';
import { initialTeethValues, useContextCaseFlow } from '../../../../context/caseFlow';
import PrintingForm from '../Forms/PrintingForm';
import { Case, useCaseCalcPrice, useUploadCaseFile } from '../../../../hooks/cases';
import SummaryInfoForm from '../Forms/SummaryInfoForm';
import { getArrayWithBridgesArrays } from '../../../../utils/teeth';
import { isRoleEnough } from '../../../../utils/auth';
import { getMessageInError } from '../../../../hooks/fetch';
import { DescriptionItem, stylesClipDesc } from '../Info';
import confirm from '../../../Common/ModalConfirm';
import { getFileExtension } from '../../../../utils';
import { UploadingFile, IPercentCompleted } from '../../../Common/AttachmentLoader';

interface CaseCreate {
  title: string;
  drawerOpen: boolean;
  close: () => void;
  isAdmin?: boolean;
  initialData?: JsonResult;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: CreateCase) => void;
  loading: boolean;
  error: ErrorCommon | null;
  createCase: (payload: CreateCase) => Action;
  createdCase: Case | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const imageMimeTypes = ['image/png', 'image/jpeg'];
const stlDcmZipMimeTypes = ['application/dicom',
  'application/zip', 'application/x-zip', 'application/x-zip-compressed', 'multipart/x-zip',
  'application/rar', 'application/x-rar', 'application/x-rar-compressed',
  'application/vnd.ms-pki.stl', 'application/vnd.ms-pki.stl-binary', 'model/stl', 'application/sla'];
const stlDcmExtensions = ['stl', 'dcm', 'dicom', 'neaf'];

const CaseCreate = (props: CaseCreate): JSX.Element => {
  const {
    initialData,
    handleSave,
    title,
    drawerOpen,
    close,
    isAdmin,
    disabled,
    loading,
    error,
    createCase,
    createdCase,
  } = props;

  const {
    foundSurgicalService,
    foundRestorativeService,
    materialCrownId,
    materialImplantId,
    // Form values
    patientInfo,
    selectTreatmentPlan,
    teethValues,
    implantInfo,
    printingInfo,
    // Handle Form values
    setPatientInfo,
    setSelectTreatmentPlan,
    setTeethValues,
    setImplantInfo,
    setPrintingInfo,
    patientByIdInContext,
    clearContextData,
  } = useContextCaseFlow();
  const caseCalcPrice = useCaseCalcPrice();
  const uploadCaseFile = useUploadCaseFile((progressEvent) => {
    setPercentCompleted((prevState) => {
      const prevStateCurrentFile = prevState.find((item) => (
        item.fileSizeBytes === progressEvent.total
      ));
      const newPercentValue = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      return (
        [
          ...prevState.filter((item) => item.fileSizeBytes !== progressEvent.total),
          {
            fileSizeBytes: progressEvent.total,
            percent: prevStateCurrentFile && (newPercentValue < prevStateCurrentFile.percent)
              ? prevStateCurrentFile?.percent : newPercentValue,
          },
        ]
      );
    });
  });
  const navigate = useNavigate();

  const [patientSelectForm] = Form.useForm();
  const [selectTreatmentPlanForm] = Form.useForm();
  const [teethTreatmentPlanForm] = Form.useForm();
  const [implantInfoForm] = Form.useForm();
  const [printingInfoForm] = Form.useForm();
  const [summaryInfoForm] = Form.useForm();

  const [printingIsSelectedId, setPrintingIsSelectedId] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [dateSizeCount, setDateSizeCount] = useState<number>(0);

  const totalSteps = printingIsSelectedId ? 4 : 3;
  const [step, setStep] = useState<number>(1);

  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  /** Request to calculate total price per case  */
  const [caseTotalPrice, setCaseTotalPrice] = useState<number>(0);
  const [leadTimePrice, setLeadTimePrice] = useState<number>(0);

  const servicesArrValues: string[] = Form.useWatch('services', selectTreatmentPlanForm);

  useEffect(() => {
    /** Clean printing data if printing service was unselected before calculating */
    if (!printingIsSelectedId || printingIsSelectedId === '') {
      setPrintingInfo({});
      printingInfoForm.setFieldsValue({ material: undefined });
    }
  }, [printingIsSelectedId]);

  const handleFilesUpdate = (files: UploadFile[]) => {
    setUploadedFiles(files);
  };

  const [wLoadingUploadingFiles, setWLoadingUploadingFiles] = useState<UploadingFile[]>([]);
  const [percentCompleted, setPercentCompleted] = useState<IPercentCompleted[]>([]);

  useEffect(() => {
    if (!createdCase?.id || !drawerOpen) return;

    const uploadingFiles = uploadedFiles.map((file) => ({ ...file, loading: true }));

    setWLoadingUploadingFiles(uploadingFiles);

    Promise.all(
      uploadedFiles.map((file) => {
        const formData = new FormData();

        if (file.originFileObj) {
          formData.append('file', file.originFileObj);
        }

        return uploadCaseFile.fetch(formData, `${createdCase.id}/file`).then(() => {
          const currentFile = uploadingFiles.find((uploadingFile) => uploadingFile.uid === file.uid);
          const currentFileIndex = currentFile ? uploadingFiles.indexOf(currentFile) : -1;

          if (currentFile && currentFileIndex >= 0) {
            uploadingFiles.splice(currentFileIndex, 1, { ...currentFile, loading: false });
          }

          setWLoadingUploadingFiles(uploadingFiles);
        });
      }),
    )
      .catch((err) => {
        navigate(`/cases/${createdCase?.id}`);
        message.error(getMessageInError(err || uploadCaseFile.error) || 'File can\'t be uploaded', 5);
      })
      .finally(() => {
        handleClose();
        setPercentCompleted([]);
        setWLoadingUploadingFiles([]);
      });
  }, [createdCase]);

  const calcTotalCasePrice = () => {
    if (teethValues.filter((item) => item.materials?.length).length && step >= 2) {
      caseCalcPrice.fetch(
        prepareCaseCalcPriceBody(
          patientInfo, // step 1
          step === 2 ? selectTreatmentPlanForm.getFieldsValue() : selectTreatmentPlan, // step 2
          teethValues, // step 2
          printingInfoForm.getFieldsValue()?.material ? printingInfoForm.getFieldsValue() : printingInfo, // step 3
          dateSizeCount,
        ),
      ).then((res) => {
        setCaseTotalPrice(res?.totalPrice || 0);
        setLeadTimePrice(res?.leadTimePrice || 0);
      });
    }
  };

  const [triggerCalcPrice, setTriggerCalcPrice] = useState<JsonResult[]>();

  useEffect(() => {
    if (teethValues.filter((item) => item.materials?.length).length && step >= 2 && (
      servicesArrValues?.length || selectTreatmentPlan?.services?.length)
    ) {
      const timeoutId = setTimeout(calcTotalCasePrice, 1500);

      return () => clearTimeout(timeoutId);
    }

    if (!teethValues.filter((item) => item.materials?.length).length) {
      setCaseTotalPrice(0);
      setLeadTimePrice(0);
    }

    return undefined;
  }, [teethValues, selectTreatmentPlan, printingInfo, triggerCalcPrice, step, dateSizeCount]);

  /** Handling close/next/back/submit buttons  */
  const handleClose = () => {
    close();
    patientSelectForm.resetFields();
    selectTreatmentPlanForm.resetFields();
    teethTreatmentPlanForm.resetFields();
    implantInfoForm.resetFields();
    setTeethValues(initialTeethValues);
    printingInfoForm.resetFields();
    summaryInfoForm.resetFields();
    handleFilesUpdate([]);
    clearContextData();
    caseCalcPrice.clearResponse();
  };

  useEffect(() => {
    if (!drawerOpen) return;
    setStep(1);
  }, [drawerOpen]);

  useEffect(() => {
    if (error?.message && drawerOpen) {
      message.error(error.message, 5);
    }
  }, [error]);

  const isServiceSelected = (serviceId: string | undefined) => !!servicesArrValues?.includes(serviceId || '');

  const [teethErrors, setTeethErrors] = useState<string[]>([]);

  const handleNext = () => {
    if (step === 1) {
      patientSelectForm.validateFields().then((values: JsonResult) => {
        setPatientInfo(values);
        setStep((prev) => prev + 1);
      });
    }
    if (step === 2) {
      const errors: string[] = [];

      Promise.all([
        selectTreatmentPlanForm.validateFields().then((values: JsonResult) => {
          setSelectTreatmentPlan(values);
        }),
        teethTreatmentPlanForm.validateFields(),
        implantInfoForm.validateFields().then((values) => {
          setImplantInfo(values);
        }),
        /** Validating  */
        new Promise((resolve, reject) => {
          if (teethValues.length >= 1 && teethValues.some((tooth) => tooth.materials?.length)) {
            resolve('Success');
          } else {
            const messageErr = 'You have to select at least one tooth on the Treatment plan form.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** if surgical selected, then at least one implant should be chosen. */
          if (!isServiceSelected(foundSurgicalService?.id)) {
            resolve('Success');
          } else if (teethValues.length >= 1
            && teethValues.some((tooth) => tooth.materials?.includes(materialImplantId))) {
            resolve('Success');
          } else {
            const messageErr = 'You have to set at least one implant if you selected Surgical service.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** if restorative selected, then at least one crown should be chosen.  */
          if (!isServiceSelected(foundRestorativeService?.id)) {
            resolve('Success');
          } else if (teethValues.length >= 1
            && teethValues.some((tooth) => tooth.materials?.includes(materialCrownId))) {
            resolve('Success');
          } else {
            const messageErr = 'You have to set at least one crown if you selected Restorative service.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** the bridge with missing crowns in the middle.  */
          if (!teethValues.some((tooth) => tooth.isBridge && !tooth.materials?.length)) {
            resolve('Success');
          } else {
            const messageErr = 'A bridge can\'t have missing crowns in the middle.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** Single crown without an implant that is not inside the bridge (if restorative or both are selected)  */
          if ((isServiceSelected(foundRestorativeService?.id) && isServiceSelected(foundSurgicalService?.id))
            && teethValues.some((tooth) => tooth.materials?.includes(materialCrownId)
              && (!tooth.isBridge && !tooth.materials?.includes(materialImplantId)))) {
            const messageErr = 'A crown must have an \'implant\' or be a part of the bridge if both surgical and '
              + 'restorative plan is selected.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          } else {
            resolve('Success');
          }
        }),
        new Promise((resolve, reject) => {
          /** the bridge without at least one implant (if both surgical and restorative services are selected).  */
          if ((isServiceSelected(foundRestorativeService?.id) && isServiceSelected(foundSurgicalService?.id))
            && getArrayWithBridgesArrays(teethValues)?.some((teethArr) => !teethArr?.some((tooth) => (
              tooth.materials?.includes(materialImplantId || '')
            )))) {
            const messageErr = 'A bridge must have at least one implant if both surgical and restorative plan '
              + 'is selected.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          } else {
            resolve('Success');
          }
        }),
      ]).then(() => {
        setStep((prev) => prev + 1);
        setTeethErrors([]);
      }).catch(() => {
        setTeethErrors(errors);
      });
    }
    if (step === 3 && !!printingIsSelectedId) {
      printingInfoForm.validateFields().then((values: JsonResult) => {
        setPrintingInfo(values);
        setStep((prev) => prev + 1);
      });
    }
  };

  const handleSubmit = () => {
    Promise.all([
      summaryInfoForm.validateFields(),
      /** Validating  */
      new Promise((resolve, reject) => {
        const stlDcmZipFiles = uploadedFiles.filter((file) => (
          [...stlDcmExtensions, 'zip']
            .includes(getFileExtension(file.name)) || stlDcmZipMimeTypes.includes(file.type || '')));

        if (stlDcmZipFiles.length < (selectTreatmentPlan?.services?.length || 0)) {
          reject(new Error('You have to upload attachment for each service!'));
          message.error(
            `You have to upload stl/dicom/neaf/zip attachment for each service! (${
              selectTreatmentPlan?.services?.length})`,
          );
        } else {
          resolve('Success');
        }
      })]).then((values) => {
      const summaryInfoValues = values[0];

      if (!patientByIdInContext?.data?.doctor?.id) {
        message.error('The patient must have a doctor, but the one you chose doesn\'t.');
      }

      const body = prepareCaseBody(
        {
          ...patientInfo,
          practice: patientByIdInContext?.data?.doctor?.practice?.id || '',
          doctor: patientByIdInContext?.data?.doctor?.id || '',
        }, // Step 1 - Patient info + doctor & practice.
        selectTreatmentPlan, // Step 2
        teethValues, // Step 2
        implantInfo,
        printingIsSelectedId ? printingInfo : {}, // Step 3
        summaryInfoValues, // Summary Info { note: string }
        dateSizeCount,
      );

      if (handleSave) {
        handleSave(body);
      } else {
        createCase(body);
      }
    });
  };

  const descriptionItems: DescriptionItem[] = [
    {
      label: 'Patient',
      content: `${patientByIdInContext?.data?.firstName || ''} ${patientByIdInContext?.data?.lastName || ''}`,
    },
    {
      label: 'Practice',
      content: patientByIdInContext?.data?.doctor?.practice?.name || '',
    },
    {
      label: 'Doctor',
      content: `${patientByIdInContext?.data?.doctor?.user?.firstName || ''} ${
        patientByIdInContext?.data?.doctor?.user?.lastName || ''}`,
    },
  ];

  return (

    <div>
      <DrawerModal
        title={title}
        open={drawerOpen}
        onClose={handleClose}
        withSteps
        currentStep={step}
        totalSteps={totalSteps}
        isFormChanged={isFormTouched}
        viewbarContent={step > 1 ? (
          <Row justify="center" style={{ maxWidth: '520px' }}>
            <Col span={24}>
              <Descriptions
                size="small"
                column={2}
                labelStyle={{ color: 'var(--color-main-dark-gray)', opacity: '0.7', fontSize: '14px' }}
                contentStyle={{ color: 'var(--color-main-dark-gray)', fontWeight: '500', fontSize: '15px' }}
              >
                {descriptionItems.map(({ label, content, title }) => (
                  <Descriptions.Item key={label} label={label} contentStyle={stylesClipDesc}>
                    <span title={!title && typeof content === 'string' ? content : title}>{content}</span>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Col>
          </Row>
        ) : undefined}
        extra={(
          <Space>
            {step > 1 && (
              <Button
                type="ghost"
                onClick={() => {
                  if (step === 2) {
                    implantInfoForm.validateFields().then((values) => {
                      setImplantInfo(values);
                    });
                    selectTreatmentPlanForm.validateFields().then((values: JsonResult) => {
                      setSelectTreatmentPlan(values);
                    });
                  }
                  if (((step === 3 && !printingIsSelectedId) || step === 4) && uploadedFiles.length) {
                    confirm({
                      centered: true,
                      title: 'Go back',
                      content: 'Added files will be cleared if you will leave this step!',
                      onOk: () => {
                        handleFilesUpdate([]);
                        setStep((prev) => prev - 1);
                      },
                    });

                    return;
                  }
                  setStep((prev) => prev - 1);
                }}
              >
                <LeftOutlined style={{ fontSize: 12 }} />
                Back
              </Button>
            )}
            {step < totalSteps && (
              <Button
                /** For case creation Next button have type 'primary', when in other places it have 'default' type */
                type="primary"
                onClick={handleNext}
                id="nextBtn"
              >
                Next
                <RightOutlined style={{ fontSize: 12 }} />
              </Button>
            )}
            {step === totalSteps && (
              <Button
                type="primary"
                icon={<Icon component={Send} />}
                loading={loading || uploadCaseFile.loading}
                onClick={handleSubmit}
                disabled={false}
                id="submitBtn"
              >
                Complete Order
              </Button>
            )}
          </Space>
        )}
      >
        <Row justify="center">
          <Col
            span={24}
            md={(step === 3 && !printingIsSelectedId) || step === 4 ? 22 : 20}
            lg={(step === 3 && !printingIsSelectedId) || step === 4 ? 20 : 18}
            xxl={(step === 3 && !printingIsSelectedId) || step === 4 ? 16 : 12}
          >
            <div>
              {step === 1 && (
                <PatientSelectForm
                  isManager={isRoleEnough(['practice manager'])}
                  form={patientSelectForm}
                  disabled={disabled}
                  initialData={initialData?.patient}
                  onFieldsChange={() => setIsFormTouched(true)}
                />
              )}
              {step === 2 && (
                <>
                  <SelectTreatmentPlanForm
                    form={selectTreatmentPlanForm}
                    isAdmin={isAdmin}
                    disabled={disabled?.selectTreatmentPlan}
                    initialData={initialData?.selectTreatmentPlan}
                    caseTotalPrice={{
                      price: caseTotalPrice,
                      leadTimePrice,
                      loading: caseCalcPrice.loading,
                    }}
                    setPrintingId={setPrintingIsSelectedId}
                    setDateSizeCount={setDateSizeCount}
                    handleChange={(e) => setTriggerCalcPrice(e)}
                  />
                  <TeethTreatmentPlanForm
                    form={teethTreatmentPlanForm}
                    isAdmin={isAdmin}
                    disabled={disabled?.teethValues}
                    selectTreatmentPlanForm={selectTreatmentPlanForm}
                    teethErrors={teethErrors}
                    setTeethErrors={setTeethErrors}
                  />
                  <ImplantInfoForm
                    form={implantInfoForm}
                    selectTreatmentPlanForm={selectTreatmentPlanForm}
                  />
                </>
              )}
              {step === 3 && !!printingIsSelectedId && (
                <PrintingForm
                  form={printingInfoForm}
                  isAdmin={isAdmin}
                  disabled={disabled?.printingInfo}
                  initialData={initialData?.printingInfo}
                  caseTotalPrice={{
                    price: caseTotalPrice,
                    leadTimePrice,
                    loading: caseCalcPrice.loading,
                  }}
                  practiceId={patientByIdInContext?.data?.doctor?.practice?.id}
                  handleChange={(e) => {
                    setTriggerCalcPrice(e);
                    /** Update context form values on each form values change */
                    setPrintingInfo(printingInfoForm?.getFieldsValue());
                  }}
                />
              )}
              {((step === 3 && !printingIsSelectedId) || step === 4) && (
                <SummaryInfoForm
                  type="create"
                  form={summaryInfoForm}
                  isAdmin={isAdmin}
                  disabled={disabled?.summaryInfo}
                  initialData={initialData?.summaryInfo}
                  handleFilesUpdate={handleFilesUpdate}
                  caseTotalPrice={{
                    price: caseTotalPrice,
                    leadTimePrice,
                    loading: caseCalcPrice.loading,
                  }}
                  uploadingFiles={wLoadingUploadingFiles.length ? wLoadingUploadingFiles : undefined}
                  percentCompleted={percentCompleted}
                />
              )}
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  createdCase: state[casesModule].case,
  loading: state[casesModule].caseLoading,
  error: state[casesModule].caseError,
  user: state[authModuleName].user,
});

const mapDispatchToProps = {
  createCase: actionCreateCase,
};

CaseCreate.defaultProps = {
  isAdmin: false,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseCreate);
