import { UserRoles, UserStatus } from '../enums/user';
import { FetchSuccess, PagingDataResponse } from '../types';
import { DoctorInterface } from './doctors';
import { LabStaffMember } from './labStaff';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface UserSimple {
  id: string;
  lastName: string;
  firstName: string;
}

export interface User extends UserSimple {
  role: UserRoles;
  email: string;
  status: UserStatus;
  phone: string;
  settings?: {
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  };
  doctor?: DoctorInterface;
  labStaff?: LabStaffMember;
}

export interface CreateUserPayload {
  id?: string;
  lastName: string;
  firstName: string;
  role?: UserRoles;
  email: string;
  password?: string;
  status?: UserStatus;
  phone: string;
  settings?: {
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  };
}

export interface UpdateUserPayload extends CreateUserPayload{
  id?: string;
}

// EXAMPLE. Unused at the moment, because we using redux-saga.
export interface UserCreateParams {
  role: UserRoles;
  login: string;
  email?: string;
  password: string;
  lastName?: string;
  firstName?: string;
}

export const useUserCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, UserCreateParams> => (
  useFetchCreate('users')
);

export interface UsersGetParams {
  role?: string;
  roles?: string[];
  page?: number;
  take?: number;
  email?: string;
  status?: string;
  orderBy?: 'ASC' | 'DESC';
  fullName?: string;
  orderByColumn?: 'id' | 'fullName' | 'email' | 'roles' | 'status' | 'phone'; // | 'created' | 'updated'
}

export interface TableUserRow extends User {
  key: string;
}

interface UsersTableData {
  users: TableUserRow[];
  total: number;
}

export const useTableUserRow = (): FetchGet<
  PagingDataResponse<User>, UsersGetParams, DefaultFetchError, UsersTableData> => (
  useUsersGet((data: PagingDataResponse<User>): UsersTableData => ({
    users: data.data.map((user: User): TableUserRow => ({
      key: user.id,
      ...user,
    })),
    total: data.meta.itemCount,
  }))
);

export function useUsersGet<D = PagingDataResponse<User>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, UsersGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, UsersGetParams, DD>(
    'users',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
      /**
       * example get request with cache -
       cacheLifetime: 10 * 60 * 1000,
       multiple: 'user-list',
       * */
    },
  );
}

// EXAMPLE. Unused at the moment, because we using redux-saga.
export interface UserUpdateParams {
  role: UserRoles;
  login: string;
  email?: string;
  status: string;
  password?: string;
  lastName?: string;
  firstName?: string;
}

export const useUserUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, UserUpdateParams> => (
  useFetchUpdate('users')
);

export const useUserId = (): FetchGetId<User> => useFetchGetId('users', '', { autoStart: false });

export interface UserConfirm {
  secretKey: string;
}

export const useConfirmAdmin = (): FetchCreate<User, DefaultFetchError, UserConfirm> => useFetchCreate(
  'users/confirm-admin',
  {
    authorization: false,
  },
);

export const useUsersRolesGet = (): FetchGet<string[]> => useFetchGet('users/roles');

export const useUpdateUserStatus = (): FetchUpdate<FetchSuccess, DefaultFetchError, { status: UserStatus; }> => (
  useFetchUpdate('users')
);

export const useUserResendLink = (): FetchGetId<User> => useFetchGetId('users', '', {
  autoStart: false, startStateLoading: false });

export const useUserDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('users');
