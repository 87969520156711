import { User } from '../store/ducks/auth';
import { RootState } from '../store/reducers';
import { UserRole, UserRoles } from '../enums/user';

export const isLoginPermitted = (user: User | null) => {
  const isSmileUser = user?.role === UserRoles.doctor && !user.doctor?.practice;

  return !isSmileUser;
};

export const isRoleEnough = (
  requiredRole: UserRole | UserRole[],
  strictComparison?: boolean,
  userRole?: UserRole,
  rolesArr?: string[] | null,
) => {
  const localStorageData: RootState['auth'] = JSON.parse(localStorage.getItem('auth') || '{}')?.accessToken
    ? JSON.parse(localStorage.getItem('auth') || '{}') : JSON.parse(sessionStorage.getItem('auth') || '{}');

  const { user, roleList } = localStorageData;

  const role = userRole || user?.role;

  const listRoles = rolesArr || roleList;

  /** if requiredRole = array, we compare it strictly */
  if (Array.isArray(requiredRole)) {
    return requiredRole?.includes(role || 'patient');
  }

  if (strictComparison) {
    return role === requiredRole;
  }

  if (role === requiredRole) {
    return true;
  }

  if (listRoles && listRoles.length) {
    return role && listRoles?.indexOf(role)
      >= listRoles?.indexOf(requiredRole);
  }

  return false;
};

export const getRedirectRoutePath = (user: User | null): string => {
  const role = user?.role;

  if (!isLoginPermitted(user)) {
    return '/sign-in';
  }
  if (role === 'practice manager') {
    return '/doctors';
  }
  if (role === 'doctor') {
    return '/cases';
  }
  if (role === 'lab manager') {
    return '/cases';
  }
  if (role === 'lab staff') {
    return '/cases';
  }

  return '/cases';
};
