import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, message, Row, Space } from 'antd';
import Icon, { DownOutlined, LockFilled, SettingFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { MenuItemGroupType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import DrawerModal from '../../../../../Common/DrawerModal';
import { ArchiveFilled, Send } from '../../../../../Common/Icon';
import LabStaffInfoForm from '../Forms/LabStaffInfo';
import { LabStaffMember, useLabStaffId, useLabStaffResendLink } from '../../../../../../hooks/labStaff';
import { getMessageInError } from '../../../../../../hooks/fetch';
import { RootState } from '../../../../../../store/reducers';
import {
  clearLabStaffData as actionClearLabStaffData,
  moduleName,
  UpdateLabStaff,
  updateLabStaff as actionUpdateLabStaff,
} from '../../../../../../store/ducks/labStaff';
import { Action } from '../../../../../../store';
import { Error } from '../../../../../../store/ducks/common';
import { capitalizeFirstLetter, isObjEqualLodash, onActionClick } from '../../../../../../utils';
import { useUpdateUserStatus } from '../../../../../../hooks/users';
import { DefaultModalProps, JsonResult } from '../../../../../../types';
import {
  prepareLabStaffBody,
  prepareLabStaffInitialData,
  prepareLabStaffServicesInitialData,
} from '../../../../../../utils/prepareData';
import { usePasswordForgot } from '../../../../../../hooks/password';
import { isRoleEnough } from '../../../../../../utils/auth';
import LabStaffServices from '../Forms/LabStaffServices';
import { UserRoles } from '../../../../../../enums/user';

const getStaffDisabledFields = (staffData?: LabStaffMember)
  : { [key: string]: boolean; } | undefined => {
  if (!staffData) return undefined;

  // if (staffData.user?.role === UserRoles.labStaff && !staffData?.lab) {
  //   // return {
  //   //   lab: true,
  //   //   position: true,
  //   //   firstName: true,
  //   //   lastName: true,
  //   //   email: true,
  //   //   phone: true,
  //   //   role: true,
  //   // };
  //   return undefined;
  // }

  if (staffData.user?.role === UserRoles.labManager && !isRoleEnough('admin')) {
    return {
      email: true,
    };
  }

  return undefined;
};

interface LabStaffInfoDrawer extends DefaultModalProps {
  id: number | string;
  // redux props \/
  updateLabStaff: (payload: UpdateLabStaff) => Action;
  clearLabStaffData: () => Action;
  labStaffLoading: boolean;
  labStaff: LabStaffMember | null;
  labStaffError: Error | null;
}

const LabStaffInfoDrawer: React.FC<LabStaffInfoDrawer> = React.memo((props) => {
  const {
    id, isOpen, close, updateLabStaff, labStaffLoading, labStaff, labStaffError, clearLabStaffData, onReload,
  } = props;
  const [form] = Form.useForm();
  const [servicesForm] = Form.useForm();
  const { id: labId = '' } = useParams<{ id: string; }>();
  const labStaffById = useLabStaffId();
  const updateUserStatus = useUpdateUserStatus();
  const resetPassword = usePasswordForgot();
  const labStaffResendLink = useLabStaffResendLink();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  useEffect(() => {
    setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
  }, [labStaff]);

  const fetchLabStaffId = () => {
    labStaffById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchLabStaffId();
    }
  }, [id]);

  useEffect(() => {
    if (isOpen) return;

    form.resetFields();
    servicesForm.resetFields();
  }, [isOpen]);

  const labStaffData = labStaff || labStaffById.data;
  const initialValues = prepareLabStaffInitialData(labStaffData);

  const [servicesInitialValues, setServicesInitialValues] = useState<JsonResult | undefined>();

  useEffect(() => {
    if (labStaffData?.labServices) {
      setServicesInitialValues(prepareLabStaffServicesInitialData(labStaffData?.labServices));
    }
  }, [labStaffData?.labServices]);

  useEffect(() => {
    if (form && labStaffById.data && !labStaffById.error && form) {
      form.setFieldsValue(initialValues);
    }
  }, [form, labStaffById.data]);

  useEffect(() => {
    if (labStaffById.error && isOpen) {
      message.error(getMessageInError(labStaffById.error), 5);
      labStaffById.clearError();
    }
  }, [labStaffById.error]);

  useEffect(() => {
    if (labStaffError?.message && isOpen) {
      message.error(labStaffError.message, 5);
    }
  }, [labStaffError]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = prepareLabStaffBody(values, values.role, labStaffById.data?.user?.id || undefined);

      return updateLabStaff({ ...body, id: `${id}` });
    });
  };

  const handleServicesSubmit = () => {
    servicesForm.validateFields().then((values) => {
      const body = prepareLabStaffBody(
        undefined,
        undefined,
        labStaffById.data?.user?.id || undefined,
        values,
      );

      return updateLabStaff({ ...body, id: `${id}` });
    });
  };

  const handleServicesCancel = () => {
    servicesForm.resetFields();
    servicesForm.setFieldsValue(servicesInitialValues);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsFormChanged(false);
    setIsFormValid(true);
  };

  const handleClose = () => {
    close();
    handleCancel();
    clearLabStaffData();
    setServicesInitialValues([]);
  };

  useEffect(() => {
    if (!labStaffLoading && !labStaffError && isOpen) {
      handleClose();
      onReload?.();
      message.success('Staff has been updated!');
    }
  }, [labStaffLoading]);

  useEffect(() => {
    if (updateUserStatus.data && !updateUserStatus.error && id && isOpen) {
      fetchLabStaffId();
      onReload?.();
    }
  }, [updateUserStatus.data]);

  const [lastActionName, setLastActionName] = useState<string>('');

  const items: (MenuItemType | SubMenuType | MenuItemGroupType)[] = [
    {
      label: 'Resend Link',
      key: '1',
      icon: <Icon component={Send} />,
      onClick: () => {
        onActionClick({
          title: 'Resend Link',
          content: 'Are you sure you want to resend link?',
          actionName: 'resend link',
          hook: () => labStaffResendLink.fetch(undefined, `${id}/resend-link`),
        });
        setLastActionName('link was send');
      },
      className: 'resendLinkBtn',
    },
    {
      label: 'Reset Password',
      key: '2',
      icon: <LockFilled />,
      onClick: () => {
        onActionClick({
          title: 'Reset Password',
          content: 'Are you sure you want to reset password for this user?',
          actionName: 'reset password',
          hook: () => resetPassword.fetch({ email: labStaffById.data?.user?.email || '' }),
        });
        setLastActionName('Password reset link was send');
      },
      className: 'resetPasswordBtn',
    },
    labStaffById.data?.user?.status === 'deactivated' ? {
      label: 'Activate',
      key: '5',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updateUserStatus.fetch({ status: 'active' }, labStaffById.data?.user?.id),
        });
        setLastActionName('activated');
      },
      className: 'activateBtn',
    } : {
      label: 'Deactivate',
      key: '3',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          title: 'Deactivate',
          actionName: 'deactivate',
          hook: () => updateUserStatus.fetch({ status: 'deactivated' }, labStaffById.data?.user?.id),
        });
        setLastActionName('deactivated');
      },
      className: 'deactivateBtn',
    },
    labStaffById.data?.user?.status === 'archived' ? {
      label: 'Unarchive',
      key: '6',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updateUserStatus.fetch({ status: 'active' }, labStaffById.data?.user?.id),
        });
        setLastActionName('activated');
      },
      className: 'unarchiveBtn',
    } : {
      label: 'Archive',
      key: '4',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Archive',
          actionName: 'archive',
          hook: () => updateUserStatus.fetch({ status: 'archived' }, labStaffById.data?.user?.id),
        });
        setLastActionName('archived');
      },
      className: 'archiveBtn',
    },
  ];

  const menuItems = items
    .filter((item) => labStaffById.data?.user?.status === 'pending'
      ? item.label === 'Resend Link' : item.label !== 'Resend Link')
    .filter((item) => (labStaffById.data?.user?.status === 'deactivated'
      || labStaffById.data?.user?.status === 'archived')
      ? item : (item.label !== 'Archive' && item.label !== 'Unarchive'));

  useEffect(() => {
    if ((updateUserStatus.data && !updateUserStatus.error) || (labStaffResendLink.data && !labStaffResendLink.error)) {
      message.success(`${capitalizeFirstLetter(lastActionName)}!`);
    }
  }, [updateUserStatus.data, labStaffResendLink.data]);

  useEffect(() => {
    if (updateUserStatus.error) {
      message.error(getMessageInError(updateUserStatus.error));
      updateUserStatus.clearError();
    }
  }, [updateUserStatus.error]);

  useEffect(() => {
    if (labStaffResendLink.error) {
      message.error(getMessageInError(labStaffResendLink.error));
      labStaffResendLink.clearError();
    }
  }, [labStaffResendLink.error]);

  useEffect(() => {
    if (resetPassword.error) {
      message.error(getMessageInError(resetPassword.error));
      resetPassword.clearError();
    }
  }, [resetPassword.error]);

  return (
    <DrawerModal
      title={initialValues.role === 'lab manager' ? 'Lab Manager Profile' : 'Lab Staff Profile'}
      status={labStaffById.data?.user?.status || ''}
      open={isOpen}
      onClose={handleClose}
      extra={(
        <Space>
          <Dropdown
            trigger={['click']}
            key="button"
            menu={{ items: menuItems }}
          >
            <Button type="ghost" onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
              <Space>
                <SettingFilled />
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => {
        form.resetFields();
        setIsFormChanged(false);
      }}
      isFormChanged={isFormChanged}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="lab_staff_info"
              layout="vertical"
              autoComplete="off"
              initialValues={initialValues}
              onFieldsChange={() => {
                setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
                setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
              }}
            >
              <LabStaffInfoForm
                cardLoading={labStaffById.loading}
                editMode={{
                  onSubmit: handleSubmit,
                  isFormValid,
                  onCancel: handleCancel,
                  loading: labStaffLoading,
                }}
                disabled={getStaffDisabledFields(labStaffById?.data)}
                labId={labStaffById?.data?.lab?.id}
              />
            </Form>
            {labStaffById?.data?.lab?.id && isRoleEnough('lab staff') && (
              <LabStaffServices
                form={servicesForm}
                labId={labStaffById?.data?.lab?.id}
                editMode={{ onSubmit: handleServicesSubmit, onCancel: handleServicesCancel, loading: labStaffLoading }}
                initialValues={servicesInitialValues}
              />
            )}
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
});

LabStaffInfoDrawer.displayName = 'LabStaffInfoDrawer';

LabStaffInfoDrawer.defaultProps = {
  onReload: undefined,
};

export default connect((state: RootState) => ({
  labStaffLoading: state[moduleName].labStaffLoading,
  labStaff: state[moduleName].labStaff,
  labStaffError: state[moduleName].labStaffError,
}), {
  updateLabStaff: actionUpdateLabStaff,
  clearLabStaffData: actionClearLabStaffData,
})(LabStaffInfoDrawer);
