import React, { useEffect } from 'react';
import { Card, Form, message, Space } from 'antd';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import {
  moduleName as eventModuleName,
  ICreateEvent,
  createEvent as actionCreateEvent,
} from '../../../../store/ducks/events';
import { Action } from '../../../../store';
import { Error as ErrorRedux } from '../../../../store/ducks/common';
import FormItems from '../FormItems';

interface CreateEvent {
  createEvent: (payload: ICreateEvent) => Action;
  eventCreateLoading: boolean;
  eventCreateError: ErrorRedux | null;
}

const CreateEvent: React.FC<CreateEvent> = ({ createEvent, eventCreateLoading, eventCreateError }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values: ICreateEvent) => {
    createEvent({ ...values });
  };

  useEffect(() => {
    /** Reset form after create request finish without errors */
    if (!eventCreateLoading && !eventCreateError) {
      form.resetFields();
      // form.setFieldsValue({ name: '', eventDate: undefined, remember: true });
    }
  }, [eventCreateLoading]);

  useEffect(() => {
    if (eventCreateError?.message) {
      message.error(eventCreateError.message, 5);
    }
  }, [eventCreateError]);

  return (
    <div>
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        <Card size="default" title="Create a new event" style={{ borderRadius: '10px' }}>
          <Form
            form={form}
            name="calendar_row_create"
            layout="vertical"
            initialValues={{ repeat: true }}
            autoComplete="off"
            onFinish={handleSubmit}
            disabled={eventCreateLoading}
          >
            <FormItems loading={eventCreateLoading} />
          </Form>
        </Card>
      </Space>
    </div>
  );
};

export default connect((state: RootState) => ({
  eventCreateLoading: state[eventModuleName].eventCreateLoading,
  eventCreateError: state[eventModuleName].eventCreateError,
}), {
  createEvent: actionCreateEvent,
})(CreateEvent);
