import React from 'react';
import clsx from 'clsx';

import styles from './index.module.less';

interface Status {
  status?: string;
}

const Status = ({ status }: Status): JSX.Element | null => (
  <div className={clsx(styles.status, status && styles[status.toLowerCase().replaceAll(' ', '-')])}>
    {status}
  </div>
);

Status.defaultProps = {
  status: '',
};

export default Status;
