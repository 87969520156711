import React, { useState } from 'react';
import { Button, Col, Tooltip } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';

/** styles for teeth block, they are here to override the default antd styles */
const iconBtnStyle: React.CSSProperties = {
  // padding: 0,
  padding: '1.5px',
  border: 'none',
  /** removing border 1.5px and adding padding 1.5 cause with border styles for tooth type 'bridge' works incorrectly */
  height: '47px',
  display: 'flex',
  alignItems: 'flex-end',
};
const colBtnStyle: React.CSSProperties = {
  height: '86px',
  /** These styles was used to display tooth content with before & after content outside of tooltip children */
  // display: 'flex',
  // flexDirection: 'column',
  // justifyContent: 'space-between',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
};
const toothItemStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export interface ButtonOptionTooltip extends BaseButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
  forService?: 'surgical' | 'restorative';
}

/** New Item */
interface ToothColItem {
  itemKey: string;
  tooltipOpenKey: string;
  setTooltipOpenKey: React.Dispatch<React.SetStateAction<string>>;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onContextMenu?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  tooltipSelectOptions: () => ButtonOptionTooltip[];
  isShiftTooltipOpen?: boolean;
  onShiftOpenChange?: () => void;
  shiftTooltipSelectOptions?: () => ButtonOptionTooltip[];
  // Content:
  beforeContent?: React.ReactNode;
  afterContent?: React.ReactNode;
  children: React.ReactNode;
  iconBtnAlignItems?: 'flex-start' | 'flex-end';
  style?: React.CSSProperties;
}

const ToothColItem: React.FC<ToothColItem> = ({
  itemKey,
  tooltipOpenKey,
  setTooltipOpenKey,
  onClick,
  onContextMenu,
  onMouseEnter,
  onMouseLeave,
  tooltipSelectOptions,
  isShiftTooltipOpen,
  onShiftOpenChange,
  shiftTooltipSelectOptions,
  // Content:
  beforeContent,
  afterContent,
  children,
  iconBtnAlignItems,
  style,
}) => {
  const [selectOptions, setSelectOptions] = useState<ButtonOptionTooltip[]>([]);

  return (
    <Col
      style={{ ...colBtnStyle, ...style }}
    >
      <Tooltip
        open={isShiftTooltipOpen || tooltipOpenKey === itemKey}
        trigger={['click', 'contextMenu']}
        // placement="right"
        placement="topLeft"
        onOpenChange={(visible) => {
          if (visible) {
            const getOptions = tooltipSelectOptions()
              ?.filter((item) => !item.disabled)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ?.map(({ forService, ...rest }) => rest);

            setSelectOptions(getOptions);
          } else {
            onShiftOpenChange?.();
            setTooltipOpenKey('');
          }
        }}
        overlayClassName="clean-tooltip-overlay"
        showArrow={false}
        title={(
          <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {(isShiftTooltipOpen ? shiftTooltipSelectOptions?.() || [] : selectOptions)
              .map(({ children, icon, onClick, ...rest }) => (
                <Button
                  type="default"
                  className="button-option-tooltip"
                  key={children?.toString()}
                  onClick={onClick}
                  icon={icon}
                  {...rest}
                >
                  {children}
                </Button>
              ))}
          </div>
        )}
      >
        <div style={{ ...toothItemStyle }}>
          {beforeContent}
          <Button
            type="text"
            style={{ ...iconBtnStyle, alignItems: iconBtnAlignItems }}
            onClick={(e) => onClick(e)}
            onContextMenu={onContextMenu ? (e) => {
              e.preventDefault();
              onContextMenu?.();
            } : undefined}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
          </Button>
          {afterContent}
        </div>
      </Tooltip>
    </Col>
  );
};

ToothColItem.defaultProps = {
  iconBtnAlignItems: 'flex-end',
  beforeContent: undefined,
  afterContent: undefined,
  onContextMenu: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  style: undefined,
  isShiftTooltipOpen: false,
  onShiftOpenChange: undefined,
  shiftTooltipSelectOptions: undefined,
};

export default ToothColItem;
