import React, { useEffect, useState } from 'react';
import { Card, Form, message, Space } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { RootState } from '../../../../store/reducers';
import {
  moduleName as eventModuleName,
  updateEvent as actionUpdateEvent, IUpdateEvent,
} from '../../../../store/ducks/events';
import { Action } from '../../../../store';
import { Error as ErrorRedux } from '../../../../store/ducks/common';
import FormItems from '../FormItems';
import { useEventId } from '../../../../hooks/events';
import { Send } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';
import { getMessageInError } from '../../../../hooks/fetch';

interface UpdateEvent {
  id: number;
  updateEvent: (payload: IUpdateEvent) => Action;
  eventUpdateLoading: boolean;
  eventUpdateError: ErrorRedux | null;
}

const UpdateEvent: React.FC<UpdateEvent> = ({ id, updateEvent, eventUpdateLoading, eventUpdateError }) => {
  const [form] = Form.useForm();
  const eventById = useEventId();

  const handleSubmit = (values: IUpdateEvent) => {
    updateEvent({ ...values, id });
  };

  const [initialValues, setInitialValues] = useState<IUpdateEvent>({ repeat: true });

  useEffect(() => {
    if (id) {
      eventById.fetch(undefined, id).then((res) => {
        if (res) {
          setInitialValues(res);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({ ...initialValues, eventDate: moment(initialValues?.eventDate) });
    } else {
      form.setFieldsValue({});
    }
  }, [initialValues]);

  useEffect(() => {
    if (eventUpdateError?.message) {
      message.error(eventUpdateError.message);
    }
  }, [eventUpdateError]);

  useEffect(() => {
    if (eventById.error) {
      message.error(getMessageInError(eventById.error));
      eventById.clearError();
    }
  }, [eventById.error]);

  return (
    <div>
      <Space direction="vertical" size="small" style={{ display: 'flex', position: 'relative' }}>
        <Card size="default" title="Update event" style={{ borderRadius: '10px' }}>
          <Loading absolute delay={50} visible={eventById.loading} />
          <Form
            form={form}
            name="calendar_row_update"
            layout="vertical"
            initialValues={initialValues}
            autoComplete="off"
            onFinish={handleSubmit}
            disabled={eventUpdateLoading}
          >
            <FormItems
              loading={eventUpdateLoading}
              buttonLabel="Save"
              buttonIcon={<Icon component={Send} />}
            />
          </Form>
        </Card>
      </Space>
    </div>
  );
};

export default connect((state: RootState) => ({
  eventUpdateLoading: state[eventModuleName].eventUpdateLoading,
  eventUpdateError: state[eventModuleName].eventUpdateError,
}), {
  updateEvent: actionUpdateEvent,
})(UpdateEvent);
