import { Button } from 'antd';
import React, { useState } from 'react';
import { JsonResult } from '../../../../types';
import PracticeCreate from '../Create';
import { initialCountryData } from '../../../../utils/prepareData';

interface PracticeCreateButton {
  title: string;
  id: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  type: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text';
  style?: JsonResult;
  isAdmin?: boolean;
  requiredFieldsType?: 'create' | 'completeRegistration';
  withEmailForm?: boolean;
}

const PracticeCreateButton = (props: PracticeCreateButton) => {
  const { title, id, type, children, icon, disabled, style, isAdmin, requiredFieldsType, withEmailForm } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        id={id}
        type={type}
        icon={icon}
        disabled={disabled}
        onClick={() => setIsDrawerOpen(true)}
        style={style}
      >
        {children}
      </Button>
      <PracticeCreate
        title={title}
        drawerOpen={isDrawerOpen}
        requiredFieldsType={requiredFieldsType}
        setDrawerOpen={setIsDrawerOpen}
        initialData={{
          practice: {
            country: initialCountryData,
          },
        }}
        isAdmin={isAdmin}
        withEmailForm={withEmailForm}
      />
    </>
  );
};

PracticeCreateButton.defaultProps = {
  icon: null,
  disabled: false,
  style: {},
  isAdmin: false,
  requiredFieldsType: undefined,
  withEmailForm: false,
};

export default PracticeCreateButton;
