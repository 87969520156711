import React, { useState } from 'react';
import Sider from 'antd/es/layout/Sider';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import MenuCommon from './Menu';

import styles from './index.module.less';

const SiderCommon = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={50}
    >
      <div className={styles.sider}>
        <MenuCommon />
        <div className={styles.trigger} onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </div>
    </Sider>
  );
};

export default SiderCommon;
