import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Switch } from 'antd';
import moment, { Moment } from 'moment';
import { PlusOutlined } from '@ant-design/icons/lib';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { required, requiredDatepicker } from '../../../../utils/inputRules';

interface FormItems {
  loading: boolean;
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
}

const FormItems: React.FC<FormItems> = ({ loading, buttonLabel, buttonIcon }) => {
  const form = useFormInstance();
  const repeatValue: boolean | undefined = Form.useWatch('repeat', form);
  const isDisabledDate = (current: Moment) => !repeatValue ? moment().add(-1, 'days') >= current : false;

  return (
    <Row gutter={15}>
      <Col>
        <Form.Item
          name="eventDate"
          label="Date"
          rules={[...requiredDatepicker,
            { validator: (_, value) => {
              if (!isDisabledDate(value)) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Non-repeatable date must be in the future!'));
            } }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format={repeatValue ? 'MMMM DD' : 'MMM DD, YYYY'}
            disabledDate={isDisabledDate}
          />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item name="name" label="Name" rules={required}>
          <Input placeholder="Type here" />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item name="repeat" label="Repeatable" valuePropName="checked" style={{ textAlign: 'center' }}>
          <Switch />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item label=" ">
          <Button
            size="middle"
            type="primary"
            icon={buttonIcon}
            htmlType="submit"
            loading={loading}
          >
            {buttonLabel}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

FormItems.defaultProps = {
  buttonLabel: 'Create',
  buttonIcon: <PlusOutlined />,
};

export default FormItems;
