import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
  ContactsFilled, ControlFilled,
  ReconciliationFilled,
  RobotFilled,
  CalendarFilled,
} from '@ant-design/icons/lib';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import Icon from '@ant-design/icons/es';
import { connect } from 'react-redux';
import PracticeRequest from '../../../../../pages/practices/request';
import PriceLevels from '../../../../../pages/priceLevels';
import Reports from '../../../../../pages/reports';
import Services from '../../../../../pages/services';
import Users from '../../../../../pages/users';
import Practices from '../../../../../pages/practices';
import PracticeProfile from '../../../../../pages/practices/profile';
import Labs from '../../../../../pages/labs';
import LabProfile from '../../../../../pages/labs/profile';
import Patients from '../../../../../pages/patients';
import Cases from '../../../../../pages/cases';
import CaseId from '../../../../../pages/cases/caseId';
import CalendarPage from '../../../../../pages/calendar';
import { Route } from '../../../../../routes';
import { BuildingHouse, PriceChange, Profile, UsersIcon } from '../../../../Common/Icon';
import { isRoleEnough } from '../../../../../utils/auth';
import { RootState } from '../../../../../store/reducers';
import { moduleName, User } from '../../../../../store/ducks/auth';
import LabServices from '../../../../Pages/Services/LabServices';

export const getItem = (route: Route): ItemType => ({
  key: route.bind.path || '',
  icon: route.icon,
  label: route.name,
  className: `class-${route.name}`,
});

export const routes: Route[] = [
  {
    bind: {
      element: <Practices />,
      path: '/practices',
    },
    name: 'Practices',
    icon: <BuildingHouse />,
    roleNeeded: 'admin',
    children: [
      {
        bind: {
          element: <PracticeRequest />,
          path: '/request/:id',
        },
        name: 'Practice Request',
        hidden: true,
        roleNeeded: 'admin',
      },
      {
        bind: {
          element: <PracticeProfile />,
          path: '/:id',
        },
        name: 'Practice info',
        hidden: true,
        roleNeeded: 'doctor',
      },
    ],
  },
  {
    bind: {
      element: <PracticeProfile />,
      path: '/doctors',
    },
    name: 'Doctors',
    icon: <BuildingHouse />,
    roleNeeded: ['practice manager'],
    // hidden: !isRoleEnough(['practice manager']),
  },
  {
    bind: {
      element: <Labs />,
      path: '/labs',
    },
    name: 'Labs',
    icon: <RobotFilled />,
    roleNeeded: 'admin',
    children: [
      {
        bind: {
          element: <LabServices />,
          path: '/:id/services',
        },
        name: 'Services',
        hidden: true,
      },
      {
        bind: {
          element: <LabProfile />,
          path: '/:id',
        },
        name: 'Lab info',
        hidden: true,
        roleNeeded: ['lab staff', 'lab manager', 'admin', 'root'],
      },
    ],
  },
  {
    bind: {
      element: <LabProfile />,
      path: '/staff',
    },
    name: 'Staff',
    icon: <RobotFilled />,
    roleNeeded: ['lab manager'],
    children: [
      {
        bind: {
          element: <LabServices />,
          path: '/services',
        },
        name: 'Services',
        hidden: true,
      },
    ],
  },
  {
    bind: {
      element: <Users />,
      path: '/users',
    },
    name: 'Users',
    icon: <Profile />,
    roleNeeded: 'admin',
  },
  {
    bind: {
      element: <Cases />,
      path: '/cases',
    },
    name: 'Cases',
    icon: <ContactsFilled />,
    roleNeeded: 'lab staff',
  },
  {
    bind: {
      element: <CaseId />,
      path: '/cases/:id',
    },
    name: 'Cases - Info',
    icon: <ContactsFilled />,
    roleNeeded: 'lab staff',
    hidden: true,
  },
  {
    bind: {
      element: <Patients />,
      path: '/patients',
    },
    name: 'Patients',
    icon: <Icon component={UsersIcon} />,
    roleNeeded: ['doctor', 'practice manager'],
  },
  {
    bind: {
      element: <Services />,
      path: '/services',
    },
    name: 'Services',
    icon: <ControlFilled />,
    roleNeeded: 'admin',
  },
  {
    bind: {
      element: <PriceLevels />,
      path: '/price-levels',
    },
    name: 'Price Level',
    icon: <PriceChange />,
    roleNeeded: 'admin',
  },
  {
    bind: {
      element: <Reports />,
      path: '/reporting',
    },
    name: 'Reporting',
    icon: <ReconciliationFilled />,
    roleNeeded: ['admin', 'root'],
  },
  {
    bind: {
      element: <CalendarPage />,
      path: '/calendar',
    },
    name: 'Calendar',
    icon: <CalendarFilled />,
    roleNeeded: 'admin',
  },
];

interface MenuCommon {
  user: User | null;
  roleList: string[] | null;
}

const MenuCommon: React.FC<MenuCommon> = ({ user, roleList }): JSX.Element | null => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [key, setKey] = useState('');

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  if (!key) {
    return null;
  }

  const shownRoutes = routes.filter((item) => (
    isRoleEnough(item.roleNeeded || 'patient', item.strictRoleComparison, user?.role, roleList)))
    .filter((route) => !route.hidden);

  const items = shownRoutes.map((route) => getItem(route));

  const handleMenuItemClick = (item: { key: string; }) => {
    navigate(item.key);
  };

  return (
    <Menu
      onClick={handleMenuItemClick}
      mode="inline"
      items={items}
      selectedKeys={[pathname, `/${key}`]}
      defaultOpenKeys={[`/${key}`]}
      defaultSelectedKeys={[pathname, `/${key}`]}
    />
  );
};

export default connect((state: RootState) => ({
  user: state[moduleName].user,
  roleList: state[moduleName].roleList,
}))(MenuCommon);
