import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import { useDoctorId, useDoctorsGet, DoctorsGetParams } from '../../../../hooks/doctors';
import NotFoundContent from '../../NotFoundContent';
import { UserStatus } from '../../../../enums/user';

interface SelectDoctor extends SelectProps<string | string[], Option> {
  onlyStatus?: UserStatus;
  practice?: string;
  requestOnlyWithPractice?: boolean;
  clearOnPracticeChange?: boolean;
  allowAll?: boolean;
}

const SelectDoctor: React.FC<SelectDoctor> = ({
  onlyStatus,
  practice,
  onClear,
  requestOnlyWithPractice,
  clearOnPracticeChange,
  allowAll,
  ...props
}): JSX.Element => {
  const doctorsGet = useDoctorsGet();
  const doctorById = useDoctorId();

  const form = useFormInstance();

  const [options, setOptions] = useState<Option[]>([]);
  const [doctorsName, setDoctorsName] = useState<string>('');

  const fetch = () => {
    const params: DoctorsGetParams = { page: 1, take: 100, orderBy: 'ASC', orderByColumn: 'fullName' };

    if (onlyStatus) {
      params.status = onlyStatus;
    }

    if (doctorsName) {
      params.fullName = doctorsName;
    }

    if (practice) {
      params.practice = practice;
    }

    doctorsGet.fetch(params);
  };

  useEffect(() => {
    if (requestOnlyWithPractice && !practice) return undefined;

    if (doctorsName && doctorsName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }
    if (doctorsName === '') {
      fetch();
    }

    return undefined;
  }, [doctorsName, practice]);

  useEffect(() => {
    if (clearOnPracticeChange) {
      form.setFieldValue('doctor', '');
    }
  }, [practice]);

  useEffect(() => {
    if (doctorsGet.data?.data.length) {
      setOptions(doctorsGet.data.data.map(({ user, id }): Option => (
        { label: `${user?.firstName} ${user?.lastName}`, value: id })));
    }
  }, [doctorsGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        doctorById.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: `${res?.user?.firstName} ${res?.user?.lastName}` || '', value: props.value as string },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (doctorsGet.error) {
      message.error(getMessageInError(doctorsGet.error));
      doctorsGet.clearError();
    }
  }, [doctorsGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    setDoctorsName('');

    if (!onClear) return;

    onClear();
  };

  const optionsList = allowAll
    ? [{ label: 'All', value: '' }, ...options]
    : options;

  return (
    <Select
      loading={doctorsGet.loading || doctorById.loading}
      options={optionsList}
      onSearch={(name) => setDoctorsName(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Doctor not found." />}
      {...props}
      value={doctorsGet.loading || doctorById.loading ? 'Loading...' : props.value}
    />
  );
};

SelectDoctor.defaultProps = {
  onlyStatus: undefined,
  practice: undefined,
  requestOnlyWithPractice: false,
  clearOnPracticeChange: false,
  allowAll: false,
};

export default SelectDoctor;
