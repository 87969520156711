import { useDispatch } from 'react-redux';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { alertDelaySuccess, name as appName } from '../../config';
import { LabService } from '../../hooks/services';
import { createLocationSearch } from '../../utils';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, Error } from './common';
import { Action, store } from '../index';
import { fetchSaga, SET_REFRESH_TOKEN, setRefreshToken } from './auth';
import { Lab } from '../../hooks/labs';
import { CreateAddress } from '../../hooks/addresses';
import { LabStatus } from '../../enums/lab';
import { CreateUserPayload } from '../../hooks/users';

/**
 * Constants
 * */
export const moduleName = 'lab';
const prefix = `${appName}/${moduleName}`;

export const CREATE_LAB = `${prefix}/CREATE_LAB`;
export const CREATE_LAB_START = `${prefix}/CREATE_LAB_START`;
export const CREATE_LAB_SUCCESS = `${prefix}/CREATE_LAB_SUCCESS`;
export const CREATE_LAB_ERROR = `${prefix}/CREATE_LAB_ERROR`;
export const CREATE_LAB_ERROR_RESET = `${prefix}/CREATE_LAB_ERROR_RESET`;

export const UPDATE_LAB = `${prefix}/UPDATE_LAB`;
export const UPDATE_LAB_START = `${prefix}/UPDATE_LAB_START`;
export const UPDATE_LAB_SUCCESS = `${prefix}/UPDATE_LAB_SUCCESS`;
export const UPDATE_LAB_ERROR = `${prefix}/UPDATE_LAB_ERROR`;
export const UPDATE_LAB_ERROR_RESET = `${prefix}/UPDATE_LAB_ERROR_RESET`;

export const DELETE_LAB = `${prefix}/DELETE_LAB`;
export const DELETE_LAB_START = `${prefix}/DELETE_LAB_START`;
export const DELETE_LAB_SUCCESS = `${prefix}/DELETE_LAB_SUCCESS`;
export const DELETE_LAB_ERROR = `${prefix}/DELETE_LAB_ERROR`;
export const DELETE_LAB_ERROR_RESET = `${prefix}/DELETE_LAB_ERROR_RESET`;

/**
 * Reducer
 * */
export interface State {
  lab: Lab | null;
  loading: boolean;

  error: Error | null;
}

const defaultState: State = {
  lab: null,
  loading: false,

  error: null,
};

export interface CreateLab {
  name: string;
  status?: LabStatus;
  email: string;
  phone: string;
  note: string;
  accountNumber: string;
  // services?: [
  //   null
  // ];
  labServices?: LabService[];
  labStaff?: {
    id?: string;
    position: string;
    user: CreateUserPayload;
  }[];
  addresses: CreateAddress[];
}

export interface UpdateLab {
  id?: string;
  secretKey?: string;
  name?: string;
  status?: LabStatus;
  email?: string;
  phone?: string;
  note?: string;
  accountNumber?: string;
  labServices?: LabService[];
  addresses?: CreateAddress[];
}

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LAB_START:
      return { ...state, loading: true };
    case CREATE_LAB_SUCCESS:
      return { ...state, loading: false };
    case CREATE_LAB_ERROR:
      return { ...state, loading: false, error: payload };
    case CREATE_LAB_ERROR_RESET:
      return { ...state, error: null };

    case UPDATE_LAB_START:
      return { ...state, loading: true };
    case UPDATE_LAB_SUCCESS:
      return { ...state, loading: false, lab: payload };
    case UPDATE_LAB_ERROR:
      return { ...state, loading: false, error: payload };
    case UPDATE_LAB_ERROR_RESET:
      return { ...state, error: null };

    default:
      return { ...state };
  }
}

/**
 * Action Creators
 * */

export const createLab = (payload: CreateLab): Action => ({
  type: CREATE_LAB,
  payload,
});

export const updateLab = (payload: UpdateLab): Action => ({
  type: UPDATE_LAB,
  payload,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */
function* createLabSaga({ payload }: { payload: CreateLab; }): Generator {
  yield put({
    type: CREATE_LAB_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/labs`,
    {
      method: 'POST',
      body: { ...payload },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CREATE_LAB_SUCCESS,
    CREATE_LAB_ERROR,
    false,
  );
}

function* updateLabSaga({ payload }: { payload: UpdateLab; }): Generator {
  yield put({
    type: UPDATE_LAB_START,
  });

  const { id, secretKey, ...data } = payload;

  const response = secretKey
    ? (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/labs/update-secret/${id}${createLocationSearch({ secretKey })}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
      {},
    )) as FetchResponse
    : (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/labs/${id}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
      {},
    )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_LAB_SUCCESS,
    UPDATE_LAB_ERROR,
    false,
    (d) => {
      if (d?.message) {
        store.dispatch(setRefreshToken(d?.message));
      }
    },
  );
}

export function* saga(): Generator {
  yield takeLatest(
    CREATE_LAB,
    cancelableLocationSaga.bind(
      null,
      createLabSaga,
      CREATE_LAB_ERROR,
      false,
    ),
  );
  yield takeLatest(CREATE_LAB_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CREATE_LAB_ERROR_RESET,
    });
  });

  yield takeLatest(
    UPDATE_LAB,
    cancelableLocationSaga.bind(
      null,
      updateLabSaga,
      UPDATE_LAB_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_LAB_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_LAB_ERROR_RESET,
    });
  });
}
