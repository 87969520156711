import { PlusOutlined } from '@ant-design/icons/lib';
import { Button, Form, Space, Typography } from 'antd';
import React from 'react';
import { AdditionalOptionsTypes } from '../../../../../enums/services';
import OptionRow from '../OptionRow';
import Prices from '../Prices';

interface AdditionalOptions {
  name: string;
  isEditing?: boolean;
  withPrices?: boolean;
  hideToggle?: boolean;
  withoutAdding?: boolean;
  withoutTitle?: boolean;
  nameDisabled?: boolean;
  hidePrice?: boolean;
}

const AdditionalOptions = (props: AdditionalOptions) => {
  const { name, isEditing, withPrices, hideToggle, withoutAdding, withoutTitle, nameDisabled, hidePrice } = props;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {!withoutTitle && (
        <div>
          <Typography.Title level={5}>Additional Options</Typography.Title>
        </div>
      )}
      <Form.List
        name={name}
      >
        {(fields, { add }) => (
          <>
            {!!fields.length && fields.map(({ key, name: fieldName, ...restField }) => (
              <div key={key}>
                <OptionRow
                  name={fieldName}
                  restField={restField}
                  isEditing={isEditing}
                  nameDisabled={nameDisabled}
                  hidePrice={hidePrice}
                  hideToggle={hideToggle}
                />
                {withPrices && (
                  <Form.Item shouldUpdate style={{ width: '100%' }} className="no-space-form-item">
                    {(form) => (
                      <Form.Item className="no-space-form-item">
                        <Prices
                          name={fieldName}
                          restField={restField}
                          isEditing={isEditing}
                          prices={form.getFieldValue(name)?.[fieldName]?.prices}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                )}
              </div>
            ))}
            {!fields.length && !isEditing && (
              <Space style={{ width: '100%' }}>
                <Typography style={{ color: 'var(--color-text-disabled)' }}>No Active Options</Typography>
              </Space>
            )}
            {!withoutAdding && isEditing && (
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="ghost"
                  onClick={() => add(hidePrice ? undefined : { type: AdditionalOptionsTypes.perTooth })}
                  icon={<PlusOutlined />}
                >
                  Add Option
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Space>
  );
};

AdditionalOptions.defaultProps = {
  isEditing: false,
  withPrices: false,
  withoutAdding: false,
  withoutTitle: false,
  nameDisabled: false,
  hidePrice: false,
  hideToggle: false,
};

export default AdditionalOptions;
