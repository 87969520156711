import { CreateCaseService, TeethWithServices } from '../store/ducks/cases';
import { FetchSuccess, JsonResult, PagingDataResponse } from '../types';
import { AdditionalOption } from './additionalOptions';
import { Doctor } from './doctors';
import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId, FetchUpdate,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from './fetch';
import { LabStaffMember } from './labStaff';
import { Material } from './materials';
import { Practice } from './practices';
import { Patient } from './patients';
import { Service } from './services';
import { User } from './users';
import { Address } from './addresses';
import { CaseServiceStatus, CaseStatus } from '../enums/case';

export interface Case {
  id: string;
  patientId: string;
  status: CaseStatus;
  dueDate: string;
  totalPrice: number;
  labTotalPrice: number;
  leadTimePrice: number;
  createdAt: string;
  updatedAt: string;
  address: Address;
  note?: string;
  manufacturer?: string;
  family?: string;
  shade?: string;
  printingMaterialId?: string;
  teeth: {
    services: {
      id: string;
      additionalOptions: {
        id: string;
      }[];
    }[];
    teeth: Tooth[];
  };
  /** Items with many attachments  */
  practice: Practice;
  patient: Patient;
  doctor: Doctor;
  chats?: { id: string; type: string;}[];
  user: User;
  caseServices?: CaseService[];
  practicePrice: {
    fullPrice: number;
    services: {
      name: string;
      totalPrice: number;
      materials: {
        name: string;
        price: number;
      }[];
      additionalOptions: {
        name: string;
        price: number;
      }[];
    }[];
  };
  files: ServiceFile[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ServiceFile {
  file: {
    createdAt: string;
    id: string;
    mimeType: string;
    originalName: string;
  };
  id: string;
  removed: boolean;
  url: string;
}

export interface CaseService {
  id: string;
  status: CaseServiceStatus;
  previewFiles?: ServiceFile[];
  resultFiles?: ServiceFile[];
  rejectionReason?: string;
  declineReason?: string;
  labService?: {
    id: string;
    lab: {
      id: string;
      name: string;
      accountNumber?: number | string; // 1
      status?: string;
      email?: string;
      phone?: string;
      note?: string | null;
    };
    service: Service;
  };
  dueDate?: string | null;
  caseMaterials?: {
    id: string;
    price: number;
    labPrice: number;
    labMaterial: Material;
  }[];
  caseAdditionalOptions?: {
    id: string;
    price: number;
    labPrice: number;
    labAdditionalOption: AdditionalOption;
  }[];
  labStaff: LabStaffMember | null;
}

export interface Tooth {
  index: number;
  isMissing: boolean;
  toBeExtracted: boolean;
  materials?: string[];
  isBridge: boolean;
}

export interface CasesGetParams {
  id?: string;
  patientId?: string;
  doctor?: string;
  status?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services?: any;
  dueDate?: string;
  page?: number;
  take?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'name' | 'accountNumber' | 'status' | 'email' | 'phone';
  // used not for request, but for open default tab
  openTab?: string;
  fromPage?: 'patients' | string;
}

export interface TableCasesRow extends Case {
  key: string;
}

interface CaseTableData {
  cases: TableCasesRow[];
  total: number;
}

export const useTableCaseRow = (): FetchGet<
  PagingDataResponse<Case>,
  CasesGetParams,
  DefaultFetchError,
  CaseTableData
> => (
  useCasesGet((data: PagingDataResponse<Case>): CaseTableData => ({
    cases: data.data.map((item: Case): TableCasesRow => ({
      key: item.id,
      ...item,
    })),
    total: data.meta.itemCount,
  }))
);

export function useCasesGet<D = PagingDataResponse<Case>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, CasesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CasesGetParams, DD>(
    'cases',
    { decorateData, autoStart: false },
  );
}

export const useCaseId = (): FetchGetId<Case> => useFetchGetId('cases', '', { autoStart: false });

export const useUpdateCaseServiceStatus = (): FetchCreate<
  JsonResult,
  DefaultFetchError,
  JsonResult | undefined
> => useFetchCreate(
  'case-services',
  { startStateLoading: false },
);

export interface CaseCalcPrice {
  teeth: {
    services: {
      id: string;
      additionalOptions: { id: string; }[];
    };
    teeth: Tooth[];
  };
  dateSize?: number;
  caseServices?: CaseService[];
}

export interface UpdateCaseService {
  labStaff: string | null;
}

export interface UpdateCase {
  id?: string;
  dueDate?: string;
  practice?: string;
  patient?: string;
  doctor?: string;
  address?: string;
  note?: string;
  previewStatus?: string;
  resultStatus?: string;
  rejectionReason?: string;
  printingMaterialId?: string;
  teeth?: TeethWithServices;
  caseServices?: CreateCaseService[];
  status?: string;
}

export interface ResponseCalcPrice {
  dateSize: number;
  labTotalPrice: number;
  leadTimePrice: number;
  totalPrice: number;
}

export const useCaseCalcPrice = (): FetchCreate<ResponseCalcPrice, DefaultFetchError, CaseCalcPrice> => useFetchCreate(
  'cases/calc-price',
  { startStateLoading: false },
);

export const useUpdateCaseService = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  UpdateCaseService
> => useFetchUpdate(
  'case-services',
);

export const useUpdateCase = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  UpdateCase
> => useFetchUpdate(
  'cases',
);

export const useUploadCaseFile = (
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
  abortController?: AbortController,
): FetchCreate<
  FetchSuccess,
  DefaultFetchError,
  FormData
> => useFetchCreate(
  'cases',
  {
    config: {
      headers: {
        'content-type': 'multipart/form-data',
      },
      signal: abortController?.signal,
    },
  },
  onUploadProgress,
);

export const useUploadCaseServiceFile = (
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
  abortController?: AbortController,
): FetchCreate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchCreate(
    'case-services',
    { startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal },
    },
    onUploadProgress,
  )
);

export const useDeleteCaseFile = (): FetchDelete<
  FetchSuccess,
  DefaultFetchError,
  string
> => useFetchDelete(
  'cases',
);

export const useDeleteCaseServiceFile = (): FetchDelete<
  FetchSuccess,
  DefaultFetchError,
  string
> => useFetchDelete(
  'case-services',
);

export const useDownloadFile = (
  onDownloadProgress: (progressEvent: ProgressEvent) => void,
  abortController: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    'files',
    '',
    { autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController.signal,
      },
    },
    'arraybuffer',
    onDownloadProgress,
  )
);

/** Comments */

export interface Comment {
  id: string;
  message: string;
  file?: {
    createdAt: string;
    id: string;
    mimeType: string;
    originalName: string;
  } | null;
  user: User;
  createdAt: string;
}

export interface CommentsGetParams {
  id?: string;
  page?: number;
  take?: number;
  orderBy?: 'ASC' | 'DESC';
  // orderByColumn?: 'id' | 'name' | 'accountNumber' | 'status' | 'email' | 'phone';
}

interface CommentListData {
  comments: Comment[];
  total: number;
}

export const useListCommentRow = (): FetchGetId<
  PagingDataResponse<Comment>, DefaultFetchError, CommentsGetParams, CommentListData
> => (
  useCommentsGet((data: PagingDataResponse<Comment>): CommentListData => ({
    comments: data.data.map((item: Comment): Comment => ({
      ...item,
    })),
    total: data.meta.itemCount,
  }))
);

export function useCommentsGet<D = PagingDataResponse<Comment>,
  DD = D>(decorateData?: (data: D) => DD): FetchGetId<D, DefaultFetchError, CommentsGetParams, DD> {
  return useFetchGetId<D, DefaultFetchError, CommentsGetParams, DD>(
    'cases',
    '',
    { decorateData, autoStart: false, startStateLoading: false },
  );
}

export interface CommentCreateParams {
  message: string;
  file?: File;
}

export const useCommentCreate = (
  caseId: string | number,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
  abortController?: AbortController,
): FetchCreate<FetchSuccess, DefaultFetchError, FormData> => (
  useFetchCreate(
    `cases/${caseId}/comment`,
    { startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController?.signal },
    },
    onUploadProgress,
  )
);

/** Notifications receivers - Doctor */

export interface PracticeNotificationsReceiver {
  doctor: Doctor;
  id: string;
}

export interface ReceiversGetParams {
  id?: string;
  name?: string;
  page?: number;
  take?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'createdAt' | 'updatedAt' | 'eventDate';
}

export const useNotificationsReceiversGet = ()
  : FetchGetId<PracticeNotificationsReceiver[], DefaultFetchError, ReceiversGetParams> => (
  useFetchGetId('cases', '', { autoStart: false, startStateLoading: false })
);

// GET options for select
export const useAvailableReceiversGet = ()
  : FetchGetId<Doctor[], DefaultFetchError, ReceiversGetParams> => (
  useFetchGetId('cases', '', { autoStart: false, startStateLoading: false })
);

export interface PracticeReceiversCreateParams {
  doctor: string;
}

export const useReceiverCreate = (
  caseId: string | number,
): FetchCreate<FetchSuccess, DefaultFetchError, PracticeReceiversCreateParams> => (
  useFetchCreate(
    `cases/${caseId}/recipient`,
    { startStateLoading: false },
  )
);

export const useReceiverDelete = (): FetchDelete<FetchSuccess> => (
  useFetchDelete(
    'cases/recipient',
    '',
  )
);

/** Notifications receivers - Lab side */

export interface LabNotificationsReceiver {
  labStaff: LabStaffMember;
  id: string;
}

export const useLabNotificationsReceiversGet = ()
  : FetchGetId<LabNotificationsReceiver[], DefaultFetchError, ReceiversGetParams> => (
  useFetchGetId('case-services', '', { autoStart: false, startStateLoading: false })
);

// GET options for select
export const useLabAvailableReceiversGet = ()
  : FetchGetId<LabStaffMember[], DefaultFetchError, ReceiversGetParams> => (
  useFetchGetId('case-services', '', { autoStart: false, startStateLoading: false })
);

export interface LabReceiversCreateParams {
  labStaff: string;
}

export const useLabReceiverCreate = (
  caseId: string | number,
): FetchCreate<FetchSuccess, DefaultFetchError, LabReceiversCreateParams> => (
  useFetchCreate(
    `case-services/${caseId}/recipient`,
    { startStateLoading: false },
  )
);

export const useLabReceiverDelete = (): FetchDelete<FetchSuccess> => (
  useFetchDelete(
    'case-services/recipient',
    '',
  )
);
