import React from 'react';
import clsx from 'clsx';
import styles from './index.module.less';
import { IconNotFoundContent } from '../Icon';

interface NotFoundContent {
  message?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

const NotFoundContent: React.FC<NotFoundContent> = ({ message, fullWidth, style }) => (
  <div className={clsx(styles.wrapper, { [styles.fullWidth]: fullWidth })} style={style}>
    <div>
      <IconNotFoundContent />
    </div>
    <div className={styles.message}>
      {message}
    </div>
  </div>
);

NotFoundContent.defaultProps = {
  message: 'No Data.',
  fullWidth: false,
  style: undefined,
};

export default NotFoundContent;
