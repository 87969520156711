import { BellFilled } from '@ant-design/icons';
import { PageHeader, Space, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import { isRoleEnough } from '../../../utils/auth';
import NotificationsList from './NotificationsList';
import styles from './index.module.less';

const Notifications: React.FC = () => {
  const tabs = isRoleEnough('admin') ? [
    {
      label: 'Cases',
      entity: ['cases'],
    },
    {
      label: 'Services',
      entity: ['case_services', 'lab_additional_options', 'lab_materials'],
    },
  ] : [];
  const [activeKey, setActiveKey] = useState<string | undefined>(isRoleEnough('admin') ? 'cases' : undefined);

  const handleTabsChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title={(
          <Space size={10}>
            <BellFilled />
            Notifications
          </Space>
        )}
      />
      <Content className={styles.contentWrapper}>
        {!!tabs?.length && (
          <Tabs
            key="labServicesTabs"
            activeKey={activeKey}
            onChange={handleTabsChange}
            items={tabs?.map((tab) => ({
              label: tab.label,
              key: tab.entity.join(','),
            }))}
          />
        )}
        <NotificationsList entity={activeKey} />
      </Content>
    </div>
  );
};

export default Notifications;
