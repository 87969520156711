import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';

import TableUsers from './Table';
import { ModalState, ModalTypes } from '../../../types';
import DoctorCreateDrawer from '../Practices/Profile/Doctor/Create';
import { UserRoles } from '../../../enums/user';
import LabStaffCreateDrawer from '../Labs/Profile/Staff/Create';
import DoctorInfoDrawer from '../Practices/Profile/Doctor/DoctorInfo';
import LabStaffInfoDrawer from '../Labs/Profile/Staff/StaffInfo';
import AdminCreateDrawer from './Create';
import AdminInfoDrawer from './AdminInfo';

function Users(): JSX.Element {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <AdminCreateDrawer
        isOpen={modal?.type === ModalTypes.create && modal.role === UserRoles.admin}
        close={closeModal}
        onReload={modal?.onReload}
      />
      <AdminInfoDrawer
        isOpen={(modal?.type === ModalTypes.info && modal.role === UserRoles.admin)
          || (modal?.type === ModalTypes.info && modal.role === UserRoles.root)}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <DoctorCreateDrawer
        isOpen={(modal?.type === ModalTypes.create && modal.role === UserRoles.doctor)
          || (modal?.type === ModalTypes.create && modal.role === UserRoles.practiceManager)}
        close={closeModal}
        isManager={modal?.role === UserRoles.practiceManager}
        onReload={modal?.onReload}
        withEmailForm
      />
      <DoctorInfoDrawer
        isOpen={((modal?.type === ModalTypes.info && modal.role === UserRoles.doctor) && !!modal?.id)
          || ((modal?.type === ModalTypes.info && modal.role === UserRoles.practiceManager) && !!modal?.id)}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <LabStaffCreateDrawer
        isOpen={(modal?.type === ModalTypes.create && modal.role === UserRoles.labStaff)
          || (modal?.type === ModalTypes.create && modal.role === UserRoles.labManager)}
        close={closeModal}
        isManager={modal?.role === UserRoles.labManager}
        onReload={modal?.onReload}
      />
      <LabStaffInfoDrawer
        isOpen={((modal?.type === ModalTypes.info && modal.role === UserRoles.labStaff) && !!modal?.id)
          || ((modal?.type === ModalTypes.info && modal.role === UserRoles.labManager) && !!modal?.id)}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <TableUsers openModal={openModal} />
    </Content>
  );
}

export default Users;
