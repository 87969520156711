import moment from 'moment';
import { AddressStatus } from '../enums/address';
import { AdditionalOptionsTypes } from '../enums/services';
import { UserRoles } from '../enums/user';
import { Practice } from '../hooks/practices';
import { CreatePriceLevelService, PriceLevelService } from '../hooks/priceLevels';
import { LabService, Service } from '../hooks/services';
import { User as UserHooks } from '../hooks/users';
import { User } from '../store/ducks/auth';
import { ServiceUpdatePayload } from '../store/ducks/service';
import { JsonResult } from '../types';
import { CreateLab } from '../store/ducks/lab';
import { LabStatus } from '../enums/lab';
import { Lab, TableLabRow } from '../hooks/labs';
import { CreatePatient } from '../store/ducks/patient';
import { Patient } from '../hooks/patients';
import { Doctor } from '../hooks/doctors';
import { LabStaffMember, TableLabStaffRow } from '../hooks/labStaff';
import { Case, CaseCalcPrice, CaseService, Tooth } from '../hooks/cases';
import { CreateCase, TeethWithServices, UpdateCaseService } from '../store/ducks/cases';
import { IImplantInfo, IPrintingInfo, ISelectTreatmentPlan } from '../context/caseFlow';
import { defaultPriceLabel } from '../config';

export const initialCountryData = JSON.stringify({
  id: 233,
  name: 'United States',
  iso3: 'USA',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupArrayBy = (array: JsonResult[], field: string, fieldMask?: (data: any) => string): JsonResult => {
  const groupedResult: JsonResult = {};

  array.forEach((item) => {
    const objectKey = fieldMask ? fieldMask(item[field]) : field;

    groupedResult[objectKey] = [...(groupedResult[objectKey] || []), item];
  });

  return groupedResult;
};

/**
 * Practices
 */
export const preparePracticeBody = (
  practiceInfo: JsonResult,
  addresses: JsonResult[],
  sameAsBilling: boolean | undefined,
  managerInfo?: JsonResult,
) => {
  const billingAddress = !practiceInfo.street
  // || !practiceInfo.apartment
  || !practiceInfo.code
  || !practiceInfo.state
  // || !practiceInfo.city
  || !practiceInfo.cityString
    ? null
    : {
      id: practiceInfo?.billingAddressId || undefined,
      // name: 'billing address',
      street: practiceInfo.street,
      apartment: practiceInfo.apartment || undefined,
      code: practiceInfo.code,
      country: JSON.parse(practiceInfo.country).id,
      state: JSON.parse(practiceInfo.state).id,
      // city: JSON.parse(practiceInfo.city).id,
      cityString: practiceInfo.cityString,
      status: AddressStatus.ACTIVE,
      asShipping: false,
      sameAsBilling,
    };

  const addressesList = addresses.map((address) => ({
    ...address,
    country: address.country ? JSON.parse(address.country).id : '',
    state: address.state ? JSON.parse(address.state).id : '',
    // city: address.city ? JSON.parse(address.city).id : '',
    cityString: address.cityString || '',
  }));

  return (
    {
      name: practiceInfo.name,
      accountNumber: practiceInfo.accountNumber,
      note: practiceInfo.note,
      email: practiceInfo.email || undefined,
      phone: practiceInfo.phone,
      priceLevel: practiceInfo.priceLevel,
      addresses: billingAddress ? [billingAddress, ...addressesList] : addressesList,
      ...(managerInfo
        ? {
          doctors: [
            {
              id: managerInfo.id,
              nickname: managerInfo.nickname,
              specialty: managerInfo.specialty,
              position: managerInfo.position,
              user: {
                id: managerInfo.userId,
                email: managerInfo.email || undefined,
                password: managerInfo.password,
                firstName: managerInfo.firstName,
                lastName: managerInfo.lastName,
                phone: managerInfo.phone,
                role: UserRoles.practiceManager,
              },
            },
          ],
        }
        : {}
      ),
    }
  );
};

export interface PreparedPracticeInitialData {
  id?: string;
  practice?: JsonResult;
  smileUser?: JsonResult;
  manager?: JsonResult;
  addresses?: JsonResult[];
  sameAsBilling?: boolean;
}

export const prepareSmileUserInitialData = (user: User | UserHooks | null): JsonResult => ({
  id: user?.doctor?.id,
  userId: user?.id,
  email: user?.email,
  firstName: user?.firstName,
  lastName: user?.lastName,
  phone: user?.phone,
});

export const preparePracticeInitialData = (practiceData?: Practice | null): PreparedPracticeInitialData => {
  const billingAddress = practiceData?.addresses?.find((address) => !address.asShipping);
  const addresses = practiceData?.addresses ? [...practiceData.addresses] : [];
  const billingAddressIndex = billingAddress ? addresses.indexOf(billingAddress) : -1;

  if (billingAddressIndex >= 0) {
    addresses.splice(billingAddressIndex, 1);
  }

  return {
    id: practiceData?.id,
    practice: {
      id: practiceData?.id,
      name: practiceData?.name,
      accountNumber: practiceData?.accountNumber,
      note: practiceData?.note,
      email: practiceData?.email,
      phone: practiceData?.phone,
      status: practiceData?.status,
      priceLevel: practiceData?.priceLevel?.id,
      billingAddressId: billingAddress?.id || '',
      street: billingAddress?.street || '',
      apartment: billingAddress?.apartment || '',
      code: billingAddress?.code || '',
      country: billingAddress ? JSON.stringify(billingAddress.country) : initialCountryData,
      state: billingAddress ? JSON.stringify(billingAddress.state) : undefined,
      city: billingAddress ? JSON.stringify(billingAddress.city) : undefined,
      cityString: billingAddress ? billingAddress.cityString : undefined,
    },
    manager: practiceData?.doctors?.length ? {
      id: practiceData?.doctors[0]?.id,
      userId: practiceData?.doctors[0]?.user?.id,
      email: practiceData?.doctors[0]?.user?.email,
      firstName: practiceData?.doctors[0]?.user?.firstName,
      lastName: practiceData?.doctors[0]?.user?.lastName,
      nickname: practiceData?.doctors[0]?.nickname,
      phone: practiceData?.doctors[0]?.user?.phone,
      position: practiceData?.doctors[0]?.position,
      role: practiceData?.doctors[0]?.user?.role,
      specialty: practiceData?.doctors[0]?.specialty,
    } : {},
    addresses: addresses.length
      ? addresses.map((address) => ({
        ...address,
        country: address.country ? JSON.stringify(address.country) : initialCountryData,
        state: address.state ? JSON.stringify(address.state) : undefined,
        city: address.city ? JSON.stringify(address.city) : undefined,
        cityString: address.cityString || undefined,
      }))
      : [],
    sameAsBilling: billingAddress ? !!billingAddress?.sameAsBilling : false,
  };
};

/**
 * User
 */
export const prepareUserAdminBody = (
  doctorData: JsonResult,
  userRole: UserRoles = UserRoles.admin,
) => ({
  id: doctorData?.id,
  email: doctorData?.email,
  password: doctorData.password || undefined,
  firstName: doctorData?.firstName,
  lastName: doctorData?.lastName,
  phone: doctorData?.phone,
  role: userRole,
});

export const prepareUserInitialData = (user?: UserHooks | null) => {
  const defaultInitialData = {
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    status: user?.status,
    role: user?.role,
  };

  switch (user?.role) {
    case UserRoles.practiceManager:
      return {
        ...defaultInitialData,
        id: user?.doctor?.id,
        practice: user?.doctor?.practice?.id,
        nickname: user?.doctor?.nickname,
        position: user?.doctor?.position,
        specialty: user?.doctor?.specialty,
        userId: user?.id,
      };

    case UserRoles.doctor:
      return {
        ...defaultInitialData,
        id: user?.doctor?.id,
        practice: user?.doctor?.practice?.id,
        nickname: user?.doctor?.nickname,
        position: user?.doctor?.position,
        specialty: user?.doctor?.specialty,
        userId: user?.id,
      };

    case UserRoles.labManager:
      return {
        ...defaultInitialData,
        id: user?.labStaff?.id,
        lab: user?.labStaff?.lab?.id,
        position: user?.labStaff?.position,
        userId: user?.id,
      };

    case UserRoles.labStaff:
      return {
        ...defaultInitialData,
        id: user?.labStaff?.id,
        lab: user?.labStaff?.lab?.id,
        position: user?.labStaff?.position,
        userId: user?.id,
      };

    default:
      return defaultInitialData;
  }
};

/**
 * Doctors / Lab Managers
 */
export const prepareDoctorBody = (
  doctorData: JsonResult,
  role: UserRoles,
  userId?: string,
) => ({
  id: doctorData?.id,
  user: {
    email: doctorData?.email,
    password: doctorData.password || undefined,
    firstName: doctorData?.firstName,
    lastName: doctorData?.lastName,
    phone: doctorData?.phone,
    role,
    id: userId || undefined,
  },
  nickname: doctorData?.nickname,
  position: doctorData?.position,
  note: doctorData?.note || '',
  specialty: doctorData?.specialty,
  cases: undefined,
  practice: doctorData?.practice,
});

export const prepareDoctorInitialData = (doctorData?: Doctor | null) => ({
  id: doctorData?.id,
  userId: doctorData?.user?.id,
  practice: doctorData?.practice?.id,
  email: doctorData?.user?.email,
  firstName: doctorData?.user?.firstName,
  lastName: doctorData?.user?.lastName,
  nickname: doctorData?.nickname,
  phone: doctorData?.user?.phone,
  position: doctorData?.position,
  role: doctorData?.user?.role,
  specialty: doctorData?.specialty,
});

/**
 * Labs
 */
export const prepareLabBody = (
  labInfo: JsonResult,
  managerInfo?: JsonResult,
  status?: LabStatus,
  labServices?: LabService[],
): CreateLab => {
  const billingAddress = !labInfo.street
  // || !labInfo.apartment
  || !labInfo.code
  || !labInfo.state
  // || !labInfo.city
  || !labInfo.cityString
    ? null
    : {
      id: labInfo.billingAddressId,
      name: 'billing address',
      street: labInfo.street,
      apartment: labInfo.apartment,
      code: labInfo.code,
      country: JSON.parse(labInfo.country).id,
      state: JSON.parse(labInfo.state).id,
      // city: JSON.parse(labInfo.city).id,
      cityString: labInfo.cityString,
      status: AddressStatus.ACTIVE,
    };

  return (
    {
      name: labInfo.name,
      accountNumber: labInfo.accountNumber,
      status,
      note: labInfo.note,
      email: labInfo.email || undefined,
      phone: labInfo.phone,
      addresses: billingAddress ? [billingAddress] : [],
      ...(managerInfo
        ? {
          labStaff: [
            {
              id: managerInfo.staffId,
              position: managerInfo.position,
              user: {
                id: managerInfo.userId,
                email: managerInfo.email || undefined,
                password: managerInfo.password || undefined,
                firstName: managerInfo.firstName,
                lastName: managerInfo.lastName,
                phone: managerInfo.phone,
                role: UserRoles.labManager,
              },
            },
          ],
        }
        : undefined
      ),
      labServices: labServices?.map((service) => ({
        ...service,
        labAdditionalOptions: service.labAdditionalOptions?.map((additionalOption) => ({
          ...additionalOption,
          name: undefined,
        })),
        labMaterials: service.labMaterials?.map((material) => ({
          ...material,
          name: undefined,
        })),
      })),
    }
  );
};

export const prepareLabServicesBody = (services: LabService[]): LabService[] => (
  services?.map((service) => ({
    ...service,
    service: typeof service.service === 'string' ? service.service : service.service?.id,
    name: undefined,
    status: service.labMaterials?.filter((material) => material.isActive).length
      || service.labAdditionalOptions?.filter((additionalOption) => additionalOption.isActive).length
      ? 'active'
      : 'deactivated',
    labAdditionalOptions: service.labAdditionalOptions?.map((additionalOption) => ({
      ...additionalOption,
      name: undefined,
    })),
    labMaterials: service.labMaterials?.map((material) => ({
      ...material,
      prices: [{
        dateSize: 1,
        expeditedDate: 0,
        id: undefined,
        increasePercent: 0,
        label: defaultPriceLabel,
        minRange: 1,
        maxRange: 1,
        price: material.price || 0,
        type: 'per tooth',
      }, ...(material.prices || []).filter((price) => price.label !== defaultPriceLabel)],
      name: undefined,
    })),
  }))
);

export interface PreparedLabInitialData {
  id?: string;
  lab?: JsonResult;
  manager?: JsonResult;
  services?: JsonResult[];
}

export const prepareLabInitialData = (labData?: Lab | null): PreparedLabInitialData => ({
  id: labData?.id,
  lab: {
    name: labData?.name,
    accountNumber: labData?.accountNumber,
    note: labData?.note,
    email: labData?.email,
    phone: labData?.phone,
    status: labData?.status,
    billingAddressId: labData?.addresses?.[0]?.id,
    street: labData?.addresses?.[0]?.street,
    apartment: labData?.addresses?.[0]?.apartment,
    code: labData?.addresses?.[0]?.code,
    country: labData?.addresses?.[0]?.country ? JSON.stringify(labData?.addresses?.[0]?.country) : initialCountryData,
    state: labData?.addresses?.[0]?.state ? JSON.stringify(labData?.addresses?.[0]?.state) : undefined,
    city: labData?.addresses?.[0]?.city ? JSON.stringify(labData?.addresses?.[0]?.city) : undefined,
    cityString: labData?.addresses?.[0]?.cityString || undefined,
  },
  manager: {
    userId: labData?.labStaff?.[0]?.user?.id,
    staffId: labData?.labStaff?.[0]?.id,
    email: labData?.labStaff?.[0]?.user?.email,
    firstName: labData?.labStaff?.[0]?.user?.firstName,
    lastName: labData?.labStaff?.[0]?.user?.lastName,
    phone: labData?.labStaff?.[0]?.user?.phone,
    position: labData?.labStaff?.[0]?.position,
    role: labData?.labStaff?.[0]?.user?.role,
  },
  services: [],
});

export const prepareLabServicesInitialDataFromServices = (
  services: Service[],
  labServices?: LabService[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activeOnly?: boolean,
): LabService[] => (
  services
    .sort((a, b) => `${a?.name}` < `${b?.name}` ? 1 : -1)
    .map((service) => {
      const foundLabService = labServices?.find((labService) => labService.service?.id === service.id);

      return {
        id: foundLabService?.id,
        service: service.id,
        name: service?.name?.split(' ')?.[0],
        labAdditionalOptions: service.additionalOptions
          ?.filter((option) => option.isActive)
          ?.map((additionalOption) => {
            const foundAdditionalOption = foundLabService?.labAdditionalOptions
              ?.find((option) => option.additionalOption.id === additionalOption.id);

            return {
              id: foundAdditionalOption?.id,
              additionalOption: additionalOption.id,
              isActive: foundAdditionalOption?.isActive || false,
              price: foundAdditionalOption?.price || null, // foundAdditionalOption?.price || additionalOption.price,
              prices: foundAdditionalOption?.prices || additionalOption.prices,
              type: foundAdditionalOption?.type || additionalOption.type,
              name: foundAdditionalOption?.name
                || foundAdditionalOption?.additionalOption?.name
                || additionalOption.name,
            };
          }),
        labMaterials: service.materials
          ?.filter((option) => option.isActive)
          ?.map((material) => {
            const foundMaterial = foundLabService?.labMaterials
              ?.find((option) => option.material.id === material.id);

            return {
              id: foundMaterial?.id,
              material: material.id,
              isActive: foundMaterial?.isActive || false,
              price: foundMaterial?.price || null, // foundMaterial?.price || material.price,
              prices: (foundMaterial?.prices || material.prices)
                ?.filter((price) => price.label !== defaultPriceLabel),
              type: foundMaterial?.type || material.type,
              name: foundMaterial?.name
                || foundMaterial?.material?.name
                || material.name,
            };
          }),
      };
    })
);

export const prepareLabServicesInitialData = (
  labServices: LabService[],
): LabService[] => (
  labServices
    .filter((service) => service.status === 'active')
    .sort((a, b) => `${a?.service?.name}` < `${b?.service?.name}` ? 1 : -1)
    .map((labService) => ({
      id: labService?.id,
      service: labService?.service?.id,
      name: labService?.service?.name?.split(' ')?.[0],
      labAdditionalOptions: labService.labAdditionalOptions
        ?.filter((option) => option.isActive)
        ?.map((labAdditionalOption) => ({
          id: labAdditionalOption?.id,
          additionalOption: labAdditionalOption?.additionalOption?.id,
          isActive: labAdditionalOption?.isActive || false,
          price: labAdditionalOption?.price,
          prices: labAdditionalOption?.prices,
          type: labAdditionalOption?.type,
          name: labAdditionalOption?.name
                || labAdditionalOption?.additionalOption?.name,
        })),
      labMaterials: labService.labMaterials
        ?.filter((option) => option.isActive)
        ?.map((labMaterial) => ({
          id: labMaterial?.id,
          material: labMaterial?.material?.id,
          isActive: labMaterial?.isActive || false,
          price: labMaterial?.price,
          prices: labMaterial?.prices
            ?.filter((price) => price.label !== defaultPriceLabel),
          type: labMaterial?.type,
          name: labMaterial?.name
                || labMaterial?.material?.name,
        })),
    }))
);

export const preparePriceLevelServiceInitialDataFromServices = (services: Service[]): CreatePriceLevelService[] => (
  services
    .sort((a, b) => `${a?.name}` < `${b?.name}` ? 1 : -1)
    .map((service) => ({
      service: service.id,
      name: service.name,
      status: service.status,
      priceLevelAdditionalOptions: service.additionalOptions
        ?.filter((additionalOption) => additionalOption.isActive)
        ?.map((additionalOption) => ({
          ...additionalOption,
          id: undefined,
          additionalOption: additionalOption.id,
        })),
      priceLevelMaterials: service.materials?.filter((material) => material.isActive)?.map((material) => ({
        ...material,
        id: undefined,
        material: material.id,
      })),
    }))
);

export const preparePriceLevelServiceInitialData = (
  services: Service[],
  priceLevelServices?: PriceLevelService[],
  isCreateForm?: boolean,
): CreatePriceLevelService[] => (
  services
    .sort((a, b) => `${a?.name}` < `${b?.name}` ? 1 : -1)
    .map((service) => {
      const foundPriceLevelService = priceLevelServices
        ?.find((priceLevelService) => priceLevelService.service?.id === service.id);

      return {
        id: foundPriceLevelService?.id,
        service: service?.id,
        name: service?.name?.split(' ')?.[0],
        status: service?.status,
        priceLevelAdditionalOptions: service.additionalOptions
          ?.filter((option) => option.isActive)
          ?.map((additionalOption) => {
            const foundAdditionalOption = foundPriceLevelService?.priceLevelAdditionalOptions
              ?.find((option) => option?.additionalOption?.id === additionalOption?.id);

            return {
              id: foundAdditionalOption?.id,
              additionalOption: additionalOption?.id,
              // isActive: isCreateForm || foundAdditionalOption?.isActive || false,
              // By default all priceLevel additional options have to be true or status of parent option
              isActive: isCreateForm || foundAdditionalOption?.additionalOption?.isActive || true,
              price: foundAdditionalOption?.price || null, // foundAdditionalOption?.price || additionalOption.price,
              prices: foundAdditionalOption?.prices || additionalOption.prices,
              type: foundAdditionalOption?.type || additionalOption.type,
              name: foundAdditionalOption?.name
                || foundAdditionalOption?.additionalOption?.name
                || additionalOption.name,
            };
          }),
        priceLevelMaterials: service?.materials
          ?.filter((option) => option?.isActive)
          ?.map((material) => {
            const foundMaterial = foundPriceLevelService?.priceLevelMaterials
              ?.find((option) => option?.material?.id === material?.id);

            return {
              id: foundMaterial?.id,
              material: material?.id,
              isActive: isCreateForm || foundMaterial?.isActive || false,
              price: foundMaterial?.price || null, // foundMaterial?.price || material.price,
              prices: foundMaterial?.prices || material.prices,
              type: foundMaterial?.type || material.type,
              name: foundMaterial?.name
                || foundMaterial?.material?.name
                || material?.name,
            };
          }),
      };
    })
);

export const preparePriceLevelServicesBody = (services: CreatePriceLevelService[]) => (
  services
    .sort((a, b) => `${a?.name}` < `${b?.name}` ? 1 : -1)
    .map((service) => ({
      ...service,
      priceLevelAdditionalOptions: service?.priceLevelAdditionalOptions?.map((option) => ({
        ...option,
        price: option.price as number || null,
        name: undefined,
      })),
      priceLevelMaterials: service?.priceLevelMaterials?.map((option) => ({
        ...option,
        price: option.price as number || null,
        name: undefined,
      })),
    }))
);

/**
 * Lab Staff / Lab Manager
 */

export const prepareLabStaffBody = (
  labStaffData?: JsonResult,
  role?: UserRoles,
  userId?: string,
  servicesValues?: JsonResult,
) => {
  const labServices: string[] = [];
  const labAdditionalOptions: string[] = [];
  const labMaterials: string[] = [];

  if (servicesValues) {
    Object.entries(servicesValues).forEach(([id, options]) => {
      const isMaterialSelected = options?.some((opt: string) => (JSON.parse(opt)?.type === 'material'));

      if (isMaterialSelected) {
        labServices.push(id);
      }

      options
        ?.forEach((option: string) => {
          if (!option) return;

          const optionObj = JSON.parse(option);

          if (optionObj.type === 'material') {
            labMaterials.push(optionObj.id);
          }
          if (optionObj.type === 'additionalOption' && isMaterialSelected) {
            labAdditionalOptions.push(optionObj.id);
          }
        });
    });
  }

  return {
    id: labStaffData?.id,
    user: labStaffData ? {
      email: labStaffData?.email,
      password: labStaffData.password || undefined,
      firstName: labStaffData?.firstName,
      lastName: labStaffData?.lastName,
      phone: labStaffData?.phone,
      role,
      id: userId || undefined,
    } : undefined,
    position: labStaffData?.position,
    note: labStaffData?.note || undefined,
    lab: labStaffData?.lab,
    labServices: labServices?.length ? labServices : [],
    labAdditionalOptions: labAdditionalOptions.length ? labAdditionalOptions : [],
    labMaterials: labMaterials.length ? labMaterials : [],
  };
};

export const prepareLabStaffInitialData = (labStaffData?: LabStaffMember | null) => ({
  id: labStaffData?.id,
  userId: labStaffData?.user?.id,
  lab: labStaffData?.lab?.id,
  email: labStaffData?.user?.email,
  firstName: labStaffData?.user?.firstName,
  lastName: labStaffData?.user?.lastName,
  phone: labStaffData?.user?.phone,
  position: labStaffData?.position,
  role: labStaffData?.user?.role,
});

export const prepareLabStaffServicesInitialData = (services?: LabService[] | null) => {
  const preparedServices: JsonResult = {};

  services?.forEach((service) => {
    if (!service?.id) return;
    const materials = service.labMaterials?.map((option) => (
      JSON.stringify({ id: option.id, type: 'material' }))) || [];
    const additionalOptions = service.labAdditionalOptions?.map((option) => (
      JSON.stringify({ id: option.id, type: 'additionalOption' })
    )) || [];

    preparedServices[service.id] = [...materials, ...additionalOptions];
  });

  return preparedServices;
};

/**
 * Service
 */

export const prepareServiceInitialData = (service: Service, activeOnly?: boolean) => ({
  id: service.id,
  materials: activeOnly ? service.materials?.filter((material) => material.isActive) : service.materials,
  additionalOptions: activeOnly
    ? service.additionalOptions?.filter((option) => option.isActive)
    : service.additionalOptions,
});

export const prepareServiceBody = (data: ServiceUpdatePayload): ServiceUpdatePayload => ({
  id: data.id,
  materials: data.materials?.map((material) => ({
    ...material,
    isActive: !!material.isActive,
    price: material.price || null,
    type: material.type || AdditionalOptionsTypes.perTooth,
  })),
  additionalOptions: data.additionalOptions?.map((option) => ({
    ...option,
    isActive: !!option.isActive,
    price: option.price || null,
    type: option.type || AdditionalOptionsTypes.perTooth,
  })),
});

/**
 * Patients
 */
export const preparePatientBody = (
  patientInfo: JsonResult,
): CreatePatient => {
  const billingAddress = !patientInfo.street
  // || !labInfo.apartment
  || !patientInfo.code
  || !patientInfo.state
  // || !patientInfo.city
  || !patientInfo.cityString
    ? null
    : {
      name: 'billing address',
      street: patientInfo.street,
      apartment: patientInfo.apartment,
      code: patientInfo.code,
      country: JSON.parse(patientInfo.country).id,
      state: JSON.parse(patientInfo.state).id,
      // city: JSON.parse(patientInfo.city).id,
      cityString: patientInfo.cityString,
      status: AddressStatus.ACTIVE,
    };

  return (
    {
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      phone: patientInfo.phone,
      email: patientInfo.email || undefined,
      addresses: billingAddress ? [billingAddress] : [],
      doctor: patientInfo.doctor,
      cases: undefined,
      note: patientInfo.note,
    }
  );
};

export const preparePatientInitialData = (patientData?: Patient | null) => ({
  firstName: patientData?.firstName,
  lastName: patientData?.lastName,
  phone: patientData?.phone,
  email: patientData?.email,
  doctor: patientData?.doctor.id,
  cases: undefined,
  note: patientData?.note,
  // address
  addressId: patientData?.addresses?.[0]?.id,
  street: patientData?.addresses?.[0]?.street,
  apartment: patientData?.addresses?.[0]?.apartment,
  code: patientData?.addresses?.[0]?.code,
  country: patientData?.addresses?.[0]?.country
    ? JSON.stringify(patientData?.addresses?.[0]?.country) : initialCountryData,
  state: patientData?.addresses?.[0]?.state ? JSON.stringify(patientData?.addresses?.[0]?.state) : undefined,
  city: patientData?.addresses?.[0]?.city ? JSON.stringify(patientData?.addresses?.[0]?.city) : undefined,
  cityString: patientData?.addresses?.[0]?.cityString || undefined,
});

/**
 * Cases
 */

export const prepareCaseBody = (
  patientInfo: JsonResult,
  selectTreatmentPlanInfo: JsonResult,
  treatmentPlanInfo: Tooth[],
  implantInfo?: IImplantInfo,
  printingInfo?: { address?: string; material?: string; },
  summaryInfo?: JsonResult,
  dateSize?: number,
): CreateCase => {
  const getToothMaterials = (tooth: Tooth): string[] | undefined => {
    const materialsWithoutPrinting = tooth.materials?.filter((material) => material !== printingInfo?.material);

    if (materialsWithoutPrinting?.length) {
      if (printingInfo?.material) {
        return [...materialsWithoutPrinting, printingInfo.material];
      }

      return tooth.materials;
    }

    return undefined;
  };

  const teethValues: TeethWithServices = {
    services: selectTreatmentPlanInfo.services?.map((serviceId: string) => ({
      id: serviceId,
      additionalOptions: selectTreatmentPlanInfo[serviceId]?.map((optionId: string) => ({
        id: optionId,
      })),
    })),
    teeth: treatmentPlanInfo?.map((tooth) => ({
      ...tooth,
      materials: getToothMaterials(tooth),
    })),
  };

  return (
    {
      patient: patientInfo.patient,
      // dueDate: moment(patientInfo.dueDate).format(),
      dueDate: moment(selectTreatmentPlanInfo.dueDate).format('YYYY-MM-DD 01:00 Z'),
      dateSize,
      doctor: patientInfo?.doctor,
      practice: patientInfo?.practice,
      teeth: teethValues,
      manufacturer: implantInfo?.manufacturer,
      family: implantInfo?.family,
      shade: implantInfo?.shade,
      printingMaterialId: printingInfo?.material || '',
      address: printingInfo?.address,
      note: summaryInfo?.note,
    }
  );
};

export const prepareCaseCalcPriceBody = (
  patientInfo: JsonResult,
  selectTreatmentPlanInfo: JsonResult,
  treatmentPlanInfo: Tooth[],
  printingInfo?: JsonResult,
  dateSize?: number,
): CaseCalcPrice => {
  const getToothMaterials = (tooth: Tooth): string[] | undefined => {
    const materialsWithoutPrinting = tooth.materials?.filter((material) => material !== printingInfo?.material);

    if (materialsWithoutPrinting?.length) {
      if (printingInfo?.material) {
        return [...materialsWithoutPrinting, printingInfo.material];
      }

      return tooth.materials;
    }

    return undefined;
  };

  return (
    {
      teeth: {
        services: selectTreatmentPlanInfo.services?.map((serviceId: string) => ({
          id: serviceId,
          additionalOptions: selectTreatmentPlanInfo[serviceId]?.map((optionId: string) => ({
            id: optionId,
          })) || [],
        })),
        teeth: treatmentPlanInfo.filter((tooth) => tooth.materials?.length)?.map((tooth) => ({
          ...tooth,
          materials: getToothMaterials(tooth),
        })),
      },
      dateSize,
    }
  );
};

export const prepareCaseAssignLabBody = (
  services?: PriceLevelService[],
  lab?: TableLabRow,
  formValues?: JsonResult,
  dueDates?: number[],
  caseDueDate?: string,
  oldCaseServices?: CaseService[],
): CreateCase => {
  const caseServicesModified = services?.map((priceLevelService, index) => {
    const currentLabService = lab?.labServices
      ?.find((labService) => labService?.service?.id === priceLevelService?.service?.id);
    const foundCaseService = oldCaseServices?.find((caseService) => (
      caseService?.labService?.service?.id === priceLevelService?.service?.id));

    return ({
      id: foundCaseService?.id,
      dueDate: formValues?.[`dueDate-${priceLevelService.id}`]
        ? moment(formValues?.[`dueDate-${priceLevelService.id}`] || '').format('YYYY-MM-DD 01:00 Z')
        : moment(caseDueDate).format('YYYY-MM-DD 01:00 Z'),
      dateSize: dueDates?.[index] || 0,
      labService: currentLabService?.id,
      caseMaterials: priceLevelService.priceLevelMaterials?.filter((item) => item?.isActive)
        ?.map((priceMaterial) => ({
          labMaterial: currentLabService?.labMaterials?.find((labMaterial) => (
            labMaterial?.material?.id === priceMaterial?.material?.id
          ))?.id?.toString() || '',
        })),
      caseAdditionalOptions: priceLevelService.priceLevelAdditionalOptions?.filter((item) => item?.isActive)
        ?.map((priceAdditionalOption) => ({
          labAdditionalOption: currentLabService?.labAdditionalOptions?.find((labAdditionalOption) => (
            labAdditionalOption?.additionalOption?.id === priceAdditionalOption?.additionalOption?.id
          ))?.id?.toString() || '',
        })),
    });
  });

  return (
    {
      caseServices: [
        ...(oldCaseServices
          ?.filter((oldCaseService) => !(services || []).some((priceLevelService) => (
            priceLevelService?.service?.id === oldCaseService?.labService?.service?.id)))
          ?.map((oldCaseService) => ({ id: oldCaseService?.id })) || []),
        ...(caseServicesModified || []),
      ],
    }
  );
};

export const prepareCaseAssignStaffBody = (
  services?: PriceLevelService[],
  staff?: TableLabStaffRow,
  caseServices?: CaseService[],
) => (
  [...(caseServices
    ?.filter((caseService) => !(services || []).some((priceLevelService) => (
      priceLevelService.service?.id === caseService.labService?.service?.id)))
    ?.map((caseService) => ({ id: caseService?.id })) || []),
  ...(services?.map((priceLevelService) => {
    const caseService = caseServices
      ?.find((service) => service?.labService?.service?.id === priceLevelService?.service?.id);

    return (
      {
        id: caseService?.id,
        labStaff: staff?.id || null,
      }
    );
  }) || []),
  ]
) as UpdateCaseService[];

export const prepareCaseUnassignStaffBody = (
  caseServices?: CaseService[],
  staff?: TableLabStaffRow,
) => caseServices?.map((caseService) => (caseService.labStaff?.id === staff?.id
  && caseService.status !== 'completed' && caseService.status !== 'pending'
  && caseService.status !== 'ready for approval'
  ? {
    id: caseService?.id,
    labStaff: null,
  }
  : {
    id: caseService?.id,
  }
)) as UpdateCaseService[];

export const prepareCaseUnassignStaffFromServiceBody = (
  caseServices?: CaseService[],
  staff?: TableLabStaffRow,
  unassignFromServiceId?: string,
) => caseServices?.map((caseService) => (
  caseService.labStaff?.id === staff?.id && caseService.id === unassignFromServiceId
  && caseService.status !== 'completed' && caseService.status !== 'pending'
  && caseService.status !== 'ready for approval'
    ? {
      id: caseService?.id,
      labStaff: null,
    }
    : {
      id: caseService?.id,
    }
)) as UpdateCaseService[];

export interface PreparedCaseInitialData {
  id?: string;
  patientInfo?: JsonResult;
  selectTreatmentPlan?: ISelectTreatmentPlan;
  teethValues?: Tooth[];
  implantInfo?: IImplantInfo;
  printingInfo?: IPrintingInfo;
  summaryInfo?: JsonResult;
}

export const prepareCaseInitialData = (caseData?: Case | null): PreparedCaseInitialData => {
  /** Set Services fields in const */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const servicesForm: { services: string[]; [key: string]: any; } = { services: [] };

  caseData?.teeth?.services?.forEach((service) => {
    servicesForm?.services?.push(service?.id);
    servicesForm[service.id] = service?.additionalOptions?.map((option) => option?.id);
  });

  return {
    id: caseData?.id,
    patientInfo: {
      patient: caseData?.patient?.id,
      // dueDate: moment(caseData?.dueDate),
      doctor: caseData?.doctor?.id,
      practice: caseData?.practice?.id,
    },
    selectTreatmentPlan: {
      ...servicesForm,
      dueDate: moment(caseData?.dueDate),
    },
    teethValues: caseData?.teeth?.teeth?.map((tooth) => ({
      ...tooth,
      materials: tooth.materials?.filter((materialId) => materialId !== caseData?.printingMaterialId),
    })),
    implantInfo: {
      manufacturer: caseData?.manufacturer,
      family: caseData?.family,
      shade: caseData?.shade,
    },
    printingInfo: {
      address: caseData?.address?.id,
      material: caseData?.printingMaterialId,
    },
    summaryInfo: {
      note: caseData?.note,
    },
  };
};

export interface PreparedCaseAssignLabInitialData {
  services?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const prepareCaseAssignLabInitialData = (
  caseData?: Case | null,
  priceLevelServicesData?: PriceLevelService[] | null,
): PreparedCaseAssignLabInitialData => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const caseServicesForm: { services?: string[]; [key: string]: any; } = { services: [] };

  caseData?.caseServices?.forEach((caseService) => {
    const priceLevelServiceId = priceLevelServicesData?.find((priceLevelService) => (
      priceLevelService?.service?.id === caseService?.labService?.service?.id))?.id || '';

    if (!caseService?.labService?.lab?.id) {
      caseServicesForm?.services?.push(priceLevelServiceId);
    }

    caseServicesForm[`dueDate-${priceLevelServiceId}`] = moment(caseService?.dueDate);
    // TODO check is need to add here .format('YYYY-MM-DD 01:00 Z')
  });

  return caseData?.caseServices?.length ? {
    // id: caseServicesData?.id,
    ...caseServicesForm,
  } : {
    services: caseData?.teeth?.services?.map((service) => service.id),
  };
};

export const prepareCaseAssignStaffInitialData = (
  caseData?: Case | null,
  priceLevelServicesData?: PriceLevelService[] | null,
): PreparedCaseAssignLabInitialData => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const caseServicesForm: { services?: string[]; [key: string]: any; } = { services: [] };

  caseData?.caseServices?.forEach((caseService) => {
    const priceLevelServiceId = priceLevelServicesData?.find((priceLevelService) => (
      priceLevelService?.service?.id === caseService?.labService?.service?.id))?.id || '';

    // if (caseService?.labStaff?.id) {
    //   caseServicesForm?.services?.push(priceLevelServiceId);
    // }

    caseServicesForm[`dueDate-${priceLevelServiceId}`] = moment(caseService?.dueDate);
    // TODO check is need to add here .format('YYYY-MM-DD 01:00 Z')
  });

  return caseServicesForm;
};
