import { FormInstance } from 'antd/es/form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Divider, Form, notification, Row, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import { InfoCircleOutlined, RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { teethIconsRow1, teethIconsRow2 } from '../../../../../Common/Icon/Teeth';
import { BridgeIcon, CrownIcon, ImplantIcon } from '../../../../../Common/Icon';
import { HeadingContentRow } from '../index';
import ToothColItem, { ButtonOptionTooltip } from '../ToothColItem';
import {
  findToothByKey, getArrayWithBridgesArrays, getBridgeFromKey,
  isNextToothBridge,
  isPrevToothBridge,
  isToothBridge,
  isToothMaterialCrown,
  isToothMaterialImplant,
  isToothMissing,
  isToothSelected,
  isToothToBeExtracted,
  numberFromToothIconKey,
} from '../../../../../../utils/teeth';
import { Tooth } from '../../../../../../hooks/cases';
import { useContextCaseFlow } from '../../../../../../context/caseFlow';
import { formStylesSummaryView } from '../DentateStatusStepForm';
import ToothIconWrapper from '../ToothIconWrapper';

import styles from '../index.module.less';

/** Teeth Treatment Plan Form Component */

interface RestorativePlanStepForm {
  form: FormInstance;
  servicesArrValues?: string[];
  isServiceSelected?: (serviceId: string | undefined) => boolean;
  onSubmit?: () => void;
  teethValues: Tooth[];
  setTeethTreatmentPlan?: React.Dispatch<React.SetStateAction<Tooth[]>>;
  // Add functions:
  uncheckTooth?: (key: string) => void;
  removeMaterials?: (key: string, removeMaterialId?: string, isShift?: boolean) => void;
  addCrownTooth?: (key: string) => void;
  addImplantTooth?: (key: string) => void;
  addBridgeTooth?: (key: string) => void;
  addBridgeBetweenTooth?: (fromKey: string, toKey: string) => void;
  removeBridge?: (key: string) => void;
  handleUndo?: () => void;
  handleRedo?: () => void;
  summaryView?: boolean;
}

const RestorativePlanStepForm: React.FC<RestorativePlanStepForm> = (props) => {
  const {
    form,
    servicesArrValues,
    isServiceSelected,
    onSubmit,
    teethValues,
    // Add functions:
    uncheckTooth,
    removeMaterials,
    addCrownTooth,
    addImplantTooth,
    addBridgeTooth,
    addBridgeBetweenTooth,
    removeBridge,
    handleUndo,
    handleRedo,
    summaryView,
  } = props;
  const { materialCrownId, materialImplantId, foundSurgicalService, foundRestorativeService } = useContextCaseFlow();

  const teethIconsArrayRow1 = Object.entries(teethIconsRow1);
  const teethIconsArrayRow2 = Object.entries(teethIconsRow2);

  const [tooltipOpenKey, setTooltipOpenKey] = useState<string>('');

  const [lastClickedTooth, setLastClickedTooth] = useState<{ key: string; clickedOn: 'number' | 'tooth' | ''; }>({
    key: '', clickedOn: '',
  });
  const [hoverToothKey, setHoverToothKey] = useState<string>('');
  const [shiftSelectedArr, setShiftSelectedArr] = useState<string[]>([]);

  const [bridgeStartKey, setBridgeStartKey] = useState('');
  const [bridgeHighlightedKeys, setBridgeHighlightedKeys] = useState<string[]>([]);

  const [isBulkActive, setIsBulkActive] = useState<'implant' | 'crown' | 'bridge' | ''>('');
  const handleIsBulkActive = (legendKey: 'implant' | 'crown' | 'bridge') => (
    legendKey === isBulkActive ? setIsBulkActive('') : setIsBulkActive(legendKey)
  );

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
  };

  useEffect(() => {
    if (servicesArrValues?.length) {
      setIsBulkActive('');
    }
  }, [servicesArrValues]);

  const tooltipSelectOptions = (key: string): ButtonOptionTooltip[] => (
    [
      {
        children: isToothMaterialImplant(key, teethValues, materialImplantId) ? 'Remove Implant' : 'Add Implant',
        icon: <ImplantIcon style={{ width: 21, height: 16 }} />,
        onClick: isToothMaterialImplant(key, teethValues, materialImplantId)
          ? () => {
            setTooltipOpenKey('');
            const foundTooth = findToothByKey(key, teethValues);

            if (foundTooth?.materials?.length === 1 && !foundTooth?.isBridge) {
              uncheckTooth?.(key);
              /** Move lastClicked from number to tooth when remove implant */
              setLastClickedTooth({ key, clickedOn: 'tooth' });
            } else {
              removeMaterials?.(key, materialImplantId);
              setLastClickedTooth({ key, clickedOn: 'tooth' });
            }
          }
          : () => {
            setTooltipOpenKey('');
            addImplantTooth?.(key);
          },
        disabled: !servicesArrValues?.includes(foundSurgicalService?.id || ''),
        forService: 'surgical',
      },
      {
        children: isToothMaterialCrown(key, teethValues, materialCrownId) ? 'Remove Crown' : 'Add Crown',
        icon: <CrownIcon style={{ width: 21, height: 16 }} />,
        onClick: isToothMaterialCrown(key, teethValues, materialCrownId)
          ? () => {
            setTooltipOpenKey('');
            const foundTooth = findToothByKey(key, teethValues);

            if (foundTooth?.materials?.length === 1 && !foundTooth?.isBridge) {
              uncheckTooth?.(key);
            } else {
              removeMaterials?.(key, materialCrownId);
            }
          }
          : () => {
            setTooltipOpenKey('');
            addCrownTooth?.(key);
          },
        disabled: !servicesArrValues?.includes(foundRestorativeService?.id || ''),
        forService: 'restorative',
      },
      {
        children: isToothBridge(key, teethValues) ? 'Remove Bridge' : 'Create Bridge',
        icon: <BridgeIcon style={{ width: 21, height: 16 }} />,
        onClick: isToothBridge(key, teethValues)
          ? () => {
            setTooltipOpenKey('');
            removeBridge?.(key);
            setBridgeStartKey('');
          }
          : () => {
            setTooltipOpenKey('');
            setBridgeStartKey(key);
          },
        disabled: !servicesArrValues?.includes(foundRestorativeService?.id || '')
          || !isToothMaterialCrown(key, teethValues, materialCrownId),
        forService: 'restorative',
      },
    ]);

  const shiftTooltipOptions = (): ButtonOptionTooltip[] => (
    [
      {
        /** If this tooltip is opened - then all teeth have a crown selected and it's safe to use [0] */
        children: isToothMaterialCrown(shiftSelectedArr?.[0], teethValues, materialCrownId)
          ? 'Remove Crown' : 'Add Crown',
        icon: <CrownIcon style={{ width: 21, height: 16 }} />,
        onClick: isToothMaterialCrown(shiftSelectedArr?.[0], teethValues, materialCrownId)
          ? () => {
            shiftSelectedArr.forEach((toothKey) => {
              const foundTooth = findToothByKey(toothKey, teethValues);

              if (foundTooth?.materials?.length === 1 && !foundTooth?.isBridge) {
                uncheckTooth?.(toothKey);
              } else {
                removeMaterials?.(toothKey, materialCrownId, true);
              }
            });
            setTooltipOpenKey('');
            setShiftSelectedArr([]);
            setLastClickedTooth({ key: '', clickedOn: '' });
          }
          : () => {
            shiftSelectedArr.forEach((toothKey) => {
              addCrownTooth?.(toothKey);
            });
            setTooltipOpenKey('');
            setShiftSelectedArr([]);
            setLastClickedTooth({ key: '', clickedOn: '' });
          },
        disabled: !servicesArrValues?.includes(foundRestorativeService?.id || ''),
      },
      {
        children: isToothBridge(shiftSelectedArr[0], teethValues) ? 'Remove Bridge' : 'Create Bridge',
        icon: <BridgeIcon style={{ width: 21, height: 16 }} />,
        onClick: isToothBridge(shiftSelectedArr?.[0], teethValues)
          ? () => {
            setTooltipOpenKey('');
            removeBridge?.(shiftSelectedArr?.[0]);
            setLastClickedTooth({ key: '', clickedOn: '' });
            setShiftSelectedArr([]);
            setBridgeStartKey('');
          }
          : () => {
            setTooltipOpenKey('');
            addBridgeBetweenTooth?.(shiftSelectedArr?.[0], shiftSelectedArr?.[(shiftSelectedArr?.length || 0) - 1]);
            setLastClickedTooth({ key: '', clickedOn: '' });
            setShiftSelectedArr([]);
            setBridgeStartKey('');
          },
        disabled: !servicesArrValues?.includes(foundRestorativeService?.id || ''),
      },
    ]);

  const toothOnClick = (key: string, event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (bridgeStartKey) {
      if (bridgeStartKey !== key) addBridgeBetweenTooth?.(bridgeStartKey, key);
      setBridgeStartKey('');

      return;
    }

    /** Set last clicked tooth on each click on any tooth  */
    setLastClickedTooth({ key, clickedOn: 'tooth' });

    /** If with shift key \/ */
    if (servicesArrValues?.length) {
      // if (event && !event.shiftKey) {
      //   setShiftStartKey('');
      // }
      if (event && event.shiftKey) {
        /** Shift logic works only if restorative selected */
        if (isServiceSelected?.(foundRestorativeService?.id)) {
          const numberShift = numberFromToothIconKey(lastClickedTooth.key);
          const numberKey = numberFromToothIconKey(key);

          const biggerKey = numberShift > numberKey ? numberShift : numberKey;
          const lowerKey = numberShift > numberKey ? numberKey : numberShift;

          const indexesArr = Array.from({ length: biggerKey + 1 - lowerKey }, (_, i) => (
            i + lowerKey));

          const isTeethSelectedSameLength = teethValues
            .filter((toothObj) => indexesArr.includes(toothObj.index))?.length === indexesArr?.length;
          const isEveryInRangeCrown = teethValues.filter((toothObj) => indexesArr.includes(toothObj.index))
            .every((tooth) => (tooth.materials?.includes(materialCrownId || '')));

          /** If tooth crown, and not every tooth in range crown - just change last clicked tooth */
          if (isToothMaterialCrown(key, teethValues, materialCrownId)
            && !(isEveryInRangeCrown && isTeethSelectedSameLength)) {
            setLastClickedTooth({ key, clickedOn: 'tooth' });
            // setTooltipOpenKey(tooltipOpenKey === key ? '' : key);

            return;
          }

          if (lastClickedTooth.key && lastClickedTooth.key !== key && lastClickedTooth.clickedOn === 'tooth') {
            /** If every tooth in range crown - then setSelectedArr and show shift tooltip, in other case add crowns */
            if (isEveryInRangeCrown && isTeethSelectedSameLength) {
              setShiftSelectedArr(indexesArr?.map((item) => `Tooth${item}`));
            } else {
              indexesArr.forEach((toothIndex) => (
                addCrownTooth?.(`Tooth${toothIndex}`)
              ));
            }
          }

          return;
        }
      }
    }

    const tooth = findToothByKey(key, teethValues);

    if (isBulkActive) {
      if (isBulkActive === 'implant' && !tooth?.materials?.includes(materialImplantId)) {
        addImplantTooth?.(key);
      }
      if (isBulkActive === 'crown' && !tooth?.materials?.includes(materialCrownId)) {
        addCrownTooth?.(key);
      }
      if (isBulkActive === 'bridge') addBridgeTooth?.(key);
    }

    // if (isServiceSelected?.(foundRestorativeService?.id || '') && (!tooth?.materials?.length && !tooth?.isBridge)) {
    if (isServiceSelected?.(foundRestorativeService?.id || '') && !tooth?.isBridge
      && !tooth?.materials?.includes(materialCrownId)) {
      /** If restorative selected, tooth not bridge and selected tooth not crown, then add crown */
      addCrownTooth?.(key);

      return;
    }

    if (isServiceSelected?.(foundSurgicalService?.id || '') && (!tooth?.materials?.length && !tooth?.isBridge)) {
      addImplantTooth?.(key);

      return;
    }

    if (tooth) {
      if (tooth && !tooth.materials?.length && !tooth.isBridge) {
        /** show tooltip only if no materials and bulk is not active. */
        if (!isBulkActive) {
          setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
        } /** Else works just action from 'if (isBulkActive)' */
      } else if (tooth?.isMissing || tooth?.toBeExtracted || tooth?.isBridge || tooth?.materials?.length) {
        /** If tooth selected AND bulk active - then by clicking on tooth we remove material which in bulk active */
        if (isBulkActive) {
          if (isBulkActive === 'implant' && tooth.materials?.includes(materialImplantId)) {
            if (tooth.materials?.length === 1 && !tooth.isBridge) {
              uncheckTooth?.(key);
            } else {
              removeMaterials?.(key, materialImplantId);
            }
          }
          if (isBulkActive === 'crown' && tooth.materials?.includes(materialCrownId)) {
            if (tooth.materials?.length === 1 && !tooth.isBridge) {
              uncheckTooth?.(key);
            } else {
              removeMaterials?.(key, materialCrownId);
            }
          }
        } else if (isServiceSelected?.(foundRestorativeService?.id)) {
          /** if at least restorative selected - then show tooltip onClick, cause tooltip have 2+ options active */
          setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
        } else {
          uncheckTooth?.(key);
        }
      } else {
        uncheckTooth?.(key);
      }
    } else if (!isBulkActive) {
      setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
    }
  };

  const toothNumberOnClick = (key: string) => {
    const foundTooth = findToothByKey(key, teethValues);

    setLastClickedTooth({ key, clickedOn: 'number' });

    if (isServiceSelected?.(foundSurgicalService?.id)) {
      if (foundTooth) {
        if (!isToothMaterialImplant(key, teethValues, materialImplantId)) {
          addImplantTooth?.(key);

          return;
        }

        if (foundTooth.materials?.length === 1 && !foundTooth.isBridge
          && isToothMaterialImplant(key, teethValues, materialImplantId)) {
          if (isBulkActive === 'implant') {
            uncheckTooth?.(key);

            return;
          }

          /** If surgical selected and restorative not selected, then uncheck tooth */
          if (!isServiceSelected?.(foundRestorativeService?.id)) {
            uncheckTooth?.(key);
          } else {
            setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
          }
          // uncheckTooth?.(key);

          return;
        }

        if (isBulkActive === 'implant') {
          removeMaterials?.(key, materialImplantId);

          return;
        }
        setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
        // removeMaterials?.(key, materialImplantId);
      } else {
        addImplantTooth?.(key);
      }
    }
  };

  const onBridgeClick = (key: string) => {
    const bridgeArrays = getArrayWithBridgesArrays(teethValues);

    const bridgeWithSelectedKey = bridgeArrays.find((arr) => (
      arr.find((tooth) => tooth.index === numberFromToothIconKey(key))
    ));

    setLastClickedTooth({ key, clickedOn: 'tooth' });
    setShiftSelectedArr((bridgeWithSelectedKey || []).map((tooth) => `Tooth${tooth.index}`));
  };

  const onBridgeHover = (key: string) => {
    const bridgeKeys = getBridgeFromKey(key, teethValues);

    if (bridgeKeys.length) {
      setBridgeHighlightedKeys(bridgeKeys);
    }
  };

  useEffect(() => {
    if (bridgeStartKey) {
      notification.open({
        key: 'bridgeSelect',
        message: 'Bridge',
        description: 'Please select the second tooth for the bridge',
        placement: 'bottomRight',
        duration: 0,
      });
    }

    if (!bridgeStartKey) {
      notification.close('bridgeSelect');
    }
  }, [bridgeStartKey]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={summaryView ? formStylesSummaryView : {}}
    >
      <Row gutter={15} className="content-wrapper-row" style={summaryView ? { transform: 'scale(0.6)' } : {}}>
        <Col span={24}>
          {!summaryView && (
            <Space size={6}>
              <Button type="ghost" onClick={handleUndo} title="Undo. CTRL/Command + Z">
                <Space size={8}>
                  <UndoOutlined />
                  Undo
                </Space>
              </Button>
              <Button type="ghost" onClick={handleRedo} title="Redo. CTRL/Command + Shift + Z">
                <Space size={8}>
                  Redo
                  <RedoOutlined />
                </Space>
              </Button>
              <Tooltip
                placement="bottom"
                title={(
                  <>
                    You can use hotkeys to
                    <p style={{ marginBottom: 0 }}>Undo - CTRL/Command + Z</p>
                    <p style={{ marginBottom: 0 }}>Redo - CTRL/Command + Shift + Z</p>
                  </>
              )}
              >
                <InfoCircleOutlined style={{ fontSize: '16px' }} />
              </Tooltip>
            </Space>
          )}
          <HeadingContentRow
            items={[
              { icon: <ImplantIcon style={{ width: 22, height: 22 }} className={styles.implantShadow} />,
                label: 'Implant',
                onClick: () => handleIsBulkActive('implant'),
                isActive: isBulkActive === 'implant',
                disabled: !servicesArrValues?.includes(foundSurgicalService?.id || '') && !summaryView,
                disabledText: 'Surgical plan need to be selected to chose this option' },
              { icon: <CrownIcon />,
                label: 'Crown',
                onClick: () => handleIsBulkActive('crown'),
                isActive: isBulkActive === 'crown',
                disabled: !servicesArrValues?.includes(foundRestorativeService?.id || '') && !summaryView,
                disabledText: 'Restorative plan need to be selected to chose this option' },
              { icon: <BridgeIcon />,
                label: 'Bridge',
                disabled: !servicesArrValues?.includes(foundRestorativeService?.id || '') && !summaryView },
            ]}
            infoTooltipContent={(
              <>
                <p>
                  Left-click to set the option based on the selected services.
                  (If only Surgical - implant. If only Restorative - crown).
                </p>
                <p>Right-click to open the context menu with all options.</p>
                <span>Shift + click to select multiple tooth.</span>
              </>
              )}
          />
          <Divider />
          <Row
            gutter={[1, 1]}
            justify="center"
            style={{ alignItems: 'flex-end', position: 'relative' }}
            className={!!bridgeStartKey && numberFromToothIconKey(bridgeStartKey) >= 17
              ? styles.disabledToothRow : undefined}
            wrap={!summaryView}
          >
            {teethIconsArrayRow1.map(([key, Icon]: [
              key: string, Icon: FunctionComponent<{ style?: React.CSSProperties; className?: string; }>]) => (
                <ToothColItem
                  key={key}
                  itemKey={key}
                  tooltipOpenKey={tooltipOpenKey}
                  setTooltipOpenKey={setTooltipOpenKey}
                  tooltipSelectOptions={() => tooltipSelectOptions(key)}
                  isShiftTooltipOpen={!!shiftSelectedArr.length
                        && !!lastClickedTooth.key && key === lastClickedTooth.key}
                  onShiftOpenChange={() => {
                    setTooltipOpenKey('');
                    setShiftSelectedArr([]);
                  }}
                  shiftTooltipSelectOptions={() => shiftTooltipOptions()}
                  beforeContent={(
                    <div
                      className={styles.numberLabelWrapper}
                      onClick={() => toothNumberOnClick(key)}
                      onMouseEnter={() => setHoverToothKey(key)}
                      onMouseLeave={() => setHoverToothKey('')}
                    >
                      <div style={{ position: 'absolute', zIndex: -1, top: '-50%' }}>
                        {hoverToothKey === key && !isToothMaterialImplant(key, teethValues, materialImplantId)
                          && isServiceSelected?.(foundSurgicalService?.id) && (
                          <ImplantIcon className={clsx(styles.implantHoverIcon)} />
                        )}
                        {isToothMaterialImplant(key, teethValues, materialImplantId) && (
                        <ImplantIcon className={clsx(styles.implantShadow, {
                          [styles.lastClickedFilled]: (lastClickedTooth.key === key
                                  && lastClickedTooth.clickedOn === 'number'),
                        })}
                        />
                        )}
                      </div>
                      <span
                        className={clsx(
                          styles.numberLabel,
                          {
                            [styles.selectedLabel]: isToothSelected(key, teethValues),
                            [styles.hoveredLabel]: !isToothSelected(key, teethValues) && hoverToothKey === key,
                            /* Logic to highlight tooth while shift logic active: styles */
                            /* [styles.shiftStartKey]: shiftStartKey && (key === shiftStartKey
                                  || (numberFromToothIconKey(key) <= numberFromToothIconKey(hoverToothKey)
                                    && numberFromToothIconKey(key) >= numberFromToothIconKey(shiftStartKey))
                                  || (numberFromToothIconKey(key) >= numberFromToothIconKey(hoverToothKey)
                                    && numberFromToothIconKey(key) <= numberFromToothIconKey(shiftStartKey))), */
                          },
                        )}
                      >
                        {numberFromToothIconKey(key)}
                      </span>
                    </div>
                  )}
                  onClick={(event) => toothOnClick(key, event)}
                  onContextMenu={() => {
                    if (bridgeStartKey) {
                      if (bridgeStartKey !== key) addBridgeBetweenTooth?.(bridgeStartKey, key);
                      setBridgeStartKey('');

                      return;
                    }
                    setLastClickedTooth({ key, clickedOn: 'tooth' });
                    setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
                  }}
                      /* Logic to highlight tooth while shift logic active: onMouseEnter */
                  onMouseEnter={() => setHoverToothKey(key)}
                  onMouseLeave={() => setHoverToothKey('')}
                >
                  <ToothIconWrapper
                    showBridge={bridgeStartKey === key || !!isToothBridge(key, teethValues)}
                    className={clsx({
                      [styles.borderIfBeforeDivBridge]: isPrevToothBridge(key, teethValues),
                      [styles.borderIfAfterDivBridge]: isNextToothBridge(key, teethValues),
                      [styles.beforeNotDivBridge]: !isPrevToothBridge(key, teethValues),
                      [styles.afterNotDivBridge]: !isNextToothBridge(key, teethValues),
                      [styles.singleInAreaDivBridge]: bridgeStartKey === key
                          || (!isNextToothBridge(key, teethValues) && !isPrevToothBridge(key, teethValues)),
                      [styles.active]: shiftSelectedArr?.includes(key),
                      [styles.bridgeHover]: bridgeHighlightedKeys?.includes(key),
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      onBridgeClick(key);
                    }}
                    onMouseEnter={() => onBridgeHover(key)}
                    onMouseLeave={() => setBridgeHighlightedKeys([])}
                  >
                    <Icon
                      className={clsx(styles.tooth, {
                        [styles.lastClickedStroke]: (lastClickedTooth.key === key
                              && lastClickedTooth.clickedOn === 'tooth') || shiftSelectedArr?.includes(key),
                        [styles.lastClickedFilled]: ((lastClickedTooth.key === key
                              && lastClickedTooth.clickedOn === 'tooth') || shiftSelectedArr?.includes(key))
                            && isToothMaterialCrown(key, teethValues, materialCrownId),

                        [styles.activeToothOptions]: tooltipOpenKey === key,
                        [styles.activeToothNotCrown]: tooltipOpenKey === key
                              && !isToothMaterialCrown(key, teethValues, materialCrownId),
                        [styles.toBeExtracted]: isToothToBeExtracted(key, teethValues),
                        [styles.isMissing]: isToothMissing(key, teethValues),
                        [styles.isBridge]: isToothBridge(key, teethValues),
                        [styles.isCrown]: isToothMaterialCrown(key, teethValues, materialCrownId),
                      })}
                    />
                  </ToothIconWrapper>
                </ToothColItem>
            ))}
          </Row>
          <Row
            gutter={[1, 1]}
            justify="center"
            style={{ alignItems: 'flex-start', position: 'relative' }}
            className={!!bridgeStartKey && numberFromToothIconKey(bridgeStartKey) <= 16
              ? styles.disabledToothRow : undefined}
            wrap={!summaryView}
          >
            {teethIconsArrayRow2.map(([key, Icon]: [
              key: string, Icon: FunctionComponent<{ style?: React.CSSProperties; className?: string; }>]) => (
                <ToothColItem
                  key={key}
                  itemKey={key}
                  tooltipOpenKey={tooltipOpenKey}
                  setTooltipOpenKey={setTooltipOpenKey}
                  iconBtnAlignItems="flex-start"
                  tooltipSelectOptions={() => tooltipSelectOptions(key)}
                  isShiftTooltipOpen={!!shiftSelectedArr.length
                    && !!lastClickedTooth.key && key === lastClickedTooth.key}
                  onShiftOpenChange={() => {
                    setTooltipOpenKey('');
                    setShiftSelectedArr([]);
                  }}
                  shiftTooltipSelectOptions={() => shiftTooltipOptions()}
                  afterContent={(
                    <div
                      className={styles.numberLabelWrapper}
                      onClick={() => toothNumberOnClick(key)}
                      onMouseEnter={() => setHoverToothKey(key)}
                      onMouseLeave={() => setHoverToothKey('')}
                    >
                      <div style={{ position: 'absolute', zIndex: -1, top: '-50%' }}>
                        {hoverToothKey === key && !isToothMaterialImplant(key, teethValues, materialImplantId)
                          && isServiceSelected?.(foundSurgicalService?.id) && (
                          <ImplantIcon className={styles.implantHoverIcon} />
                        )}
                        {isToothMaterialImplant(key, teethValues, materialImplantId) && (
                          <ImplantIcon className={clsx(styles.implantShadow, {
                            [styles.lastClickedFilled]: (lastClickedTooth.key === key
                              && lastClickedTooth.clickedOn === 'number'),
                          })}
                          />
                        )}
                      </div>
                      <span
                        className={clsx(
                          styles.numberLabel,
                          {
                            [styles.selectedLabel]: isToothSelected(key, teethValues),
                            [styles.hoveredLabel]: !isToothSelected(key, teethValues) && hoverToothKey === key,
                            /* Logic to highlight tooth while shift logic active: styles */
                            /* [styles.shiftStartKey]: shiftStartKey && (key === shiftStartKey
                              || (numberFromToothIconKey(key) <= numberFromToothIconKey(hoverToothKey)
                                && numberFromToothIconKey(key) >= numberFromToothIconKey(shiftStartKey))
                              || (numberFromToothIconKey(key) >= numberFromToothIconKey(hoverToothKey)
                                && numberFromToothIconKey(key) <= numberFromToothIconKey(shiftStartKey))), */
                          },
                        )}
                      >
                        {numberFromToothIconKey(key)}
                      </span>
                    </div>
                  )}
                  onClick={(event) => toothOnClick(key, event)}
                  onContextMenu={() => {
                    if (bridgeStartKey) {
                      if (bridgeStartKey !== key) addBridgeBetweenTooth?.(bridgeStartKey, key);
                      setBridgeStartKey('');

                      return;
                    }
                    setLastClickedTooth({ key, clickedOn: 'tooth' });
                    setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
                  }}
                  /* Logic to highlight tooth while shift logic active: onMouseEnter */
                  onMouseEnter={() => setHoverToothKey(key)}
                  onMouseLeave={() => setHoverToothKey('')}
                >
                  <ToothIconWrapper
                    showBridge={bridgeStartKey === key || !!isToothBridge(key, teethValues)}
                    className={clsx({
                      [styles.bridgeDivOnRowTwo]: true,
                      /** On second row function isNext/Prev used in reverse. next=prev, and prev=next.
                       * That's because on first row count goes from 1 to 16, in second from 32 to 17. */
                      [styles.borderIfBeforeDivBridge]: isNextToothBridge(key, teethValues),
                      [styles.borderIfAfterDivBridge]: isPrevToothBridge(key, teethValues),
                      [styles.beforeNotDivBridge]: !isNextToothBridge(key, teethValues),
                      [styles.afterNotDivBridge]: !isPrevToothBridge(key, teethValues),
                      [styles.singleInAreaDivBridge]: bridgeStartKey === key
                        || (!isNextToothBridge(key, teethValues) && !isPrevToothBridge(key, teethValues)),
                      [styles.active]: shiftSelectedArr?.includes(key),
                      [styles.bridgeHover]: bridgeHighlightedKeys?.includes(key),
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      onBridgeClick(key);
                    }}
                    onMouseEnter={() => onBridgeHover(key)}
                    onMouseLeave={() => setBridgeHighlightedKeys([])}
                  >
                    <Icon
                      className={clsx(styles.tooth, {
                        [styles.lastClickedStroke]: (lastClickedTooth.key === key
                            && lastClickedTooth.clickedOn === 'tooth') || shiftSelectedArr?.includes(key),
                        [styles.lastClickedFilled]: ((lastClickedTooth.key === key
                            && lastClickedTooth.clickedOn === 'tooth') || shiftSelectedArr?.includes(key))
                          && isToothMaterialCrown(key, teethValues, materialCrownId),

                        [styles.activeToothOptions]: tooltipOpenKey === key,
                        [styles.activeToothNotCrown]: tooltipOpenKey === key
                          && !isToothMaterialCrown(key, teethValues, materialCrownId),
                        [styles.shadowKey24n23]: tooltipOpenKey === key
                          && (numberFromToothIconKey(key) === 24 || numberFromToothIconKey(key) === 23),
                        [styles.shadowKey26n25]: tooltipOpenKey === key
                          && (numberFromToothIconKey(key) === 25 || numberFromToothIconKey(key) === 26),
                        [styles.toBeExtracted]: isToothToBeExtracted(key, teethValues),
                        [styles.isMissing]: isToothMissing(key, teethValues),
                        [styles.isBridge]: isToothBridge(key, teethValues),
                        [styles.isCrown]: isToothMaterialCrown(key, teethValues, materialCrownId),
                      })}
                    />
                  </ToothIconWrapper>
                </ToothColItem>
            ))}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

RestorativePlanStepForm.defaultProps = {
  servicesArrValues: [],
  isServiceSelected: () => false,
  onSubmit: () => undefined,
  // Add functions:
  setTeethTreatmentPlan: () => undefined,
  uncheckTooth: () => undefined,
  removeMaterials: () => undefined,
  addCrownTooth: () => undefined,
  addImplantTooth: () => undefined,
  addBridgeTooth: () => undefined,
  addBridgeBetweenTooth: () => undefined,
  removeBridge: () => undefined,
  handleUndo: () => undefined,
  handleRedo: () => undefined,
  summaryView: false,
};

export default RestorativePlanStepForm;
