import React from 'react';
import { NavLink } from 'react-router-dom';

import { Logo as LogoCommon } from '../../../../Common/Icon';

const Logo: React.FC<{ link?: string; }> = ({ link }) => (
  <NavLink to={link || '/users'}>
    <LogoCommon />
  </NavLink>
);

Logo.defaultProps = {
  link: '/users',
};

export default Logo;
