import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PracticeStatuses } from '../../../../enums/practice';
import { useConfirmPractice } from '../../../../hooks/practices';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import {
  moduleName,
  PracticeCreatePayload,
  practiceUpdate as actionPracticeUpdate,
  PracticeUpdatePayload,
} from '../../../../store/ducks/practice';
import { RootState } from '../../../../store/reducers';
import { PreparedLabInitialData, preparePracticeInitialData } from '../../../../utils/prepareData';
import PracticeCreate from '../Create';
import { getMessageInError } from '../../../../hooks/fetch';
import { JsonResult } from '../../../../types';

interface CompletePracticeRegistration {
  practiceLoading: boolean;
  error: Error | null;
  practiceUpdate: (payload: PracticeUpdatePayload) => Action;
}

const CompletePracticeRegistration: React.FC<CompletePracticeRegistration> = (props) => {
  const { practiceLoading, error, practiceUpdate } = props;
  const navigate = useNavigate();

  const { token = '' } = useParams<{ token: string; }>();

  const confirmPractice = useConfirmPractice();

  const handleDrawerClose = () => {
    if (error) return;

    navigate({ pathname: '/sign-in' }, { replace: true });
  };

  const fetchConfirmPractice = () => {
    confirmPractice.fetch({ secretKey: token });
  };

  useEffect(() => {
    if (token) {
      fetchConfirmPractice();
    }
  }, [token]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleSubmit = async (body: PracticeCreatePayload) => {
    if (!initialData?.id || !token) return;

    await practiceUpdate({ id: initialData.id, secretKey: token, ...body });
  };

  const [initialData, setInitialData] = useState<PreparedLabInitialData | undefined>();

  useEffect(() => {
    if (confirmPractice.data) {
      setInitialData(preparePracticeInitialData(confirmPractice.data));
    }
  }, [confirmPractice.data]);

  useEffect(() => {
    if (confirmPractice.error) {
      message.error(getMessageInError(confirmPractice.error));
      confirmPractice.clearError();
    }
  }, [confirmPractice.error]);

  return (
    <PracticeCreate
      initialData={initialData}
      handleSave={handleSubmit}
      requiredFieldsType="completeRegistration"
      title="Complete Registration Form"
      drawerOpen
      setDrawerOpen={handleDrawerClose}
      loading={confirmPractice?.loading || practiceLoading}
      hasPassword={!confirmPractice?.data?.isPasswordSet}
      disabled={{
        practice: confirmPractice.data?.status === PracticeStatuses.request,
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  practiceLoading: state[moduleName].loading,
  error: state[moduleName].error,
});

const mapDispatchToProps = {
  practiceUpdate: actionPracticeUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletePracticeRegistration);
