import { Tooth } from '../hooks/cases';

export const numberFromToothIconKey = (key: string) => Number(key?.replace('Tooth', ''));

/** helper functions for teeth */
export const findToothByKey = (key: string, teethArr: Tooth[]) => (
  teethArr?.find((tooth) => tooth.index === numberFromToothIconKey(key)));
export const isToothSelected = (key: string, teethArr: Tooth[]): boolean => !!findToothByKey(key, teethArr);
export const isToothMissing = (key: string, teethArr: Tooth[]) => findToothByKey(key, teethArr)?.isMissing;
export const isToothBridge = (key: string, teethArr: Tooth[]) => findToothByKey(key, teethArr)?.isBridge;
export const isToothToBeExtracted = (key: string, teethArr: Tooth[]) => findToothByKey(key, teethArr)?.toBeExtracted;
export const isToothMaterialCrown = (key: string, teethArr: Tooth[], crownId: string): boolean => !!(
  findToothByKey(key, teethArr)?.materials?.find((item) => item === crownId));
export const isToothMaterialImplant = (key: string, teethArr: Tooth[], implantId: string): boolean => !!(
  findToothByKey(key, teethArr)?.materials?.find((item) => item === implantId));

export const isPrevToothBridge = (key: string, teethArr: Tooth[]) => (
  teethArr?.find((tooth) => (tooth.index === numberFromToothIconKey(key) - 1) && tooth.index !== 16)?.isBridge
);
export const isNextToothBridge = (key: string, teethArr: Tooth[]) => (
  teethArr?.find((tooth) => (tooth.index === numberFromToothIconKey(key) + 1) && tooth.index !== 17)?.isBridge
);

export const getArrayWithBridgesArrays = (teethValues: Tooth[]): Tooth[][] => {
  const teethArraysArray: Tooth[][] = [];

  let timelyArr: Tooth[] = [];

  const sortedTeethValues = teethValues.sort((a, b) => a.index > b.index ? 1 : -1);

  let lastUsedIndex: number = sortedTeethValues[0].index;

  sortedTeethValues.forEach((tooth, index) => {
    if (lastUsedIndex <= 16 && tooth.index > 16) {
      if (timelyArr.length) {
        teethArraysArray.push(timelyArr);
      }
      timelyArr = [];
    }
    if (tooth.isBridge && (tooth.index - lastUsedIndex === 1)) {
      timelyArr.push(tooth);
    }
    if (tooth.index - lastUsedIndex !== 1 || !tooth.isBridge || index === sortedTeethValues.length - 1) {
      if (timelyArr.length) {
        teethArraysArray.push(timelyArr);
      }
      timelyArr = [];
    }
    if (tooth.isBridge && (tooth.index - lastUsedIndex !== 1)) {
      timelyArr.push(tooth);
    }
    if (tooth.isBridge) {
      lastUsedIndex = tooth.index;
    }
  });

  return teethArraysArray;
};

export const getBridgeFromKey = (key: string, teethValues: Tooth[]): string[] => {
  const bridgeArrays = getArrayWithBridgesArrays(teethValues);
  const arrayWithKey = bridgeArrays.find((arr) => (
    !!arr.find((tooth) => tooth.index === numberFromToothIconKey(key))
  ));

  return (arrayWithKey || []).map((tooth) => `Tooth${tooth.index}`);
};
