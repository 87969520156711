import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { ContactsFilled, FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, FormInstance, message, Space, Typography } from 'antd';
import { connect } from 'react-redux';
import { CaseStatus, CaseStatuses } from '../../../../enums/case';
import { CaseService, TableCasesRow, useTableCaseRow } from '../../../../hooks/cases';
import { Doctor } from '../../../../hooks/doctors';
import { isRoleEnough } from '../../../../utils/auth';
import Status from '../../../Common/Status';

import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { CaseModalState, JsonResult, ModalTypes, Option } from '../../../../types';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import CaseCreateButton from '../CreateButton';
import { Error as ErrorCommon, Error } from '../../../../store/ducks/common';
import { RootState } from '../../../../store/reducers';
import { moduleName } from '../../../../store/ducks/cases';
import SelectDoctor from '../../../Common/Selects/SelectDoctor';
import SelectPatient from '../../../Common/Selects/SelectPatient';
import SelectServiceMulti from '../../../Common/Selects/SelectServiceMulti';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import DateColorStatus from '../../../Common/DateColorStatus';
import { getCaseServiceStatusLabel } from '../Info';

export const getCaseStatusLabel = (status: string | undefined, caseServices: CaseService[] | undefined): string => (
  status === 'assigning' && caseServices?.every((serv) => serv.status === 'assigned') ? 'assigned'
    : CaseStatuses[status?.toLowerCase() as CaseStatus]
);

interface CasesTable {
  params?: Record<string, string>;
  openModal?: ((modal: CaseModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  // redux props \/
  loading: boolean;
  error: Error | null;
  caseCancelLoading: boolean;
  caseCancelError: ErrorCommon | null;
}

function CasesTable(props: CasesTable): JSX.Element {
  const { params, selectedRows, onRowSelection, loading, error, openModal, caseCancelError, caseCancelLoading } = props;
  const { servicesGetInContext } = useContextCaseFlow();

  const formRef = useRef<FormInstance>();
  const casesGet = useTableCaseRow();
  const actionRef = useRef<ActionType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<string>(searchParams.get('status') || 'all');

  const [servicesFilterOptions, setServicesFilterOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (servicesGetInContext?.data) {
      setServicesFilterOptions(servicesGetInContext.data?.data?.map(({ name, id }): Option => (
        { label: name, value: id }
      )));
    }
  }, [servicesGetInContext?.data]);

  const onStatusSearch = (value: string) => {
    setSearchParams(queryFilterParams({
      current: searchParams.get('current') || '',
      pageSize: searchParams.get('pageSize') || '',
      orderByColumn: searchParams.get('orderByColumn') || '',
      orderBy: searchParams.get('orderBy') || '',
      status: value,

      id: searchParams.get('id') || '',
      patientId: searchParams.get('patientId') || '',
      doctor: searchParams.get('doctor') || '',
      services: searchParams.get('services') || '',
      dueDate: searchParams.get('firstName') || '',
    }));
    formRef.current?.submit();
  };

  useEffect(() => {
    setActiveStatus(searchParams.get('status') || '');
  }, [searchParams.get('status')]);

  useEffect(() => {
    if (casesGet.error) {
      message.error(getMessageInError(casesGet.error));
      casesGet.clearError();
    }
  }, [casesGet.error]);

  useEffect(() => {
    /** reload table data, after create/update/delete request finished without errors */
    if ((!loading && !error) || (!caseCancelLoading && !caseCancelError)) {
      actionRef.current?.reload();
    }
  }, [loading, caseCancelLoading]);

  const onReload = () => actionRef.current?.reload();

  const toolBarRender = useCallback(() => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    isRoleEnough(['root', 'admin', 'practice manager', 'doctor']) && (
      <CaseCreateButton
        key="addButton"
        id="addButton"
        title="Case Creation"
        icon={<PlusOutlined />}
        type="primary"
        isAdmin
      >
        Add New Case
      </CaseCreateButton>
    ),
  ], [activeStatus, onStatusSearch]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableCasesRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return casesGet.fetch({
      ...newParams,
      ...params,
      services: newParams.services?.split(','),
      status: newParams.status === 'all' ? undefined : newParams.status,
      openTab: undefined,
      fromPage: undefined,
    }).then((data) => {
      if (data) {
        const { cases, total } = data;

        return ({ data: cases || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      status: searchParams.get('status') || '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  }, [searchParams.get('status')]);

  const columns: ProColumns<TableCasesRow>[] = [
    {
      title: 'Case ID',
      dataIndex: 'id',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => `${row.patient?.firstName ? `${row.patient?.firstName} `
        : ''}${row.patient?.lastName || ''}` || '',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderFormItem: (_, { defaultRender, ...config }) => <SelectPatient {...config} />,
      hideInForm: !isRoleEnough('doctor'),
      hideInSearch: !isRoleEnough('doctor'),
    },
    {
      title: 'Doctor',
      dataIndex: 'doctor',
      sorter: true,
      renderText: (doctor: Doctor) => (doctor ? `${doctor?.user?.firstName} ${doctor?.user?.lastName}` : '-'),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderFormItem: (_, { defaultRender, ...config }) => <SelectDoctor {...config} />,
      hideInForm: !isRoleEnough('practice manager'),
      hideInSearch: !isRoleEnough('practice manager'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status: CaseStatus, row) => (
        <Status status={getCaseStatusLabel(status, row.caseServices)} />
      ),
      hideInSearch: true,
      valueEnum: CaseStatuses,
    },
    {
      title: 'Services',
      dataIndex: 'services',
      width: 250,
      render: (_, row) => (
        <Space size={15}>
          {row.practicePrice?.services?.map((service) => {
            const foundService = row.caseServices?.find((caseService) => (
              caseService.labService?.service?.name?.includes(service.name)));

            return (
              <Space key={`${row.id}-${service.name}`} direction="vertical">
                <Typography>{service.name || '-'}</Typography>
                {foundService?.status ? (
                  <Status status={getCaseServiceStatusLabel(foundService?.status, false)} />
                ) : '-'}
                {isRoleEnough(['root', 'admin', 'lab manager', 'lab staff']) && (
                  <>
                    <Typography style={{ color: 'var(--color-text-primary)' }}>
                      {foundService?.labService?.lab?.name || '-'}
                    </Typography>
                    <Typography>
                      {`${foundService?.labStaff?.user?.firstName ? `${foundService?.labStaff?.user?.firstName} `
                        : ''}${foundService?.labStaff?.user?.lastName || ''}`}
                    </Typography>
                  </>
                )}
              </Space>
            );
          })}
        </Space>
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderFormItem: (_, { defaultRender, ...config }) => (
        <SelectServiceMulti
          {...config}
          selectOptions={servicesFilterOptions}
          placeholder="Please select"
          value={Array.isArray(config.value) ? config.value : config?.value?.split(',')}
          mode="multiple"
        />
      ),
      sorter: false,
      ellipsis: false,
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: true,
      ellipsis: true,
      valueType: 'date',
      render: (_, row) => (
        <DateColorStatus date={row?.dueDate} format="DD MMM, YYYY" />
      ),
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  const [filteredCaseStatuses, setFilteredCaseStatuses] = useState<JsonResult>(CaseStatuses);

  useEffect(() => {
    setFilteredCaseStatuses(Object.fromEntries(
      Object.entries(CaseStatuses).filter(([key]) => (
        key !== 'printing' && key !== 'delivery'
        /** Hide filter with status 'New' from 'lab staff' and 'lab manager' */
        && (isRoleEnough(['lab staff', 'lab manager']) ? key !== 'draft' : true)
      )),
    ));
  }, [servicesGetInContext?.data]);

  return (
    <Table<TableCasesRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      headerTitle={(
        <>
          <ContactsFilled />
          Cases list
        </>
      )}
      rowSelection={!!onRowSelection && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-cases', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      statusesFilter={{
        valuesEnum: servicesGetInContext?.data?.data
          // ?.find((service) => service?.name?.toLowerCase()?.includes('printing'))
          ?.find((service) => service?.serviceType === 'printing')
          ? CaseStatuses
          : filteredCaseStatuses,
        onChange: (key: string) => {
          setActiveStatus(key);
          onStatusSearch(key);
        },
        activeKey: activeStatus,
      }}
      onRow={(record) => ({
        onClick: () => {
          openModal?.({
            type: ModalTypes.info,
            id: record.id,
            onReload,
            caseStatus: record.status,
          });
        },
      })}
    />
  );
}

CasesTable.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
};

export default connect((state: RootState) => ({
  loading: state[moduleName].caseLoading,
  error: state[moduleName].caseError,
  caseCancelLoading: state[moduleName].caseCancelLoading,
  caseCancelError: state[moduleName].caseCancelError,
}))(CasesTable);
