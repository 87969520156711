import React, { useEffect, useRef } from 'react';
import { ControlFilled, SettingFilled } from '@ant-design/icons/lib';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, FormInstance, message } from 'antd';
import { connect } from 'react-redux';
import { ServiceTableRow, useTableServiceRow } from '../../../../hooks/services';
import { Error } from '../../../../store/ducks/common';
import { moduleName } from '../../../../store/ducks/service';
import { RootState } from '../../../../store/reducers';
import { ModalState, ModalTypes } from '../../../../types';
import Status from '../../../Common/Status';

import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';

interface ServicesTable {
  openModal?: ((modal: ModalState) => void) | undefined;
  loading: boolean;
  error: Error | null;
}

function ServicesTable(props: ServicesTable): JSX.Element {
  const { openModal, loading, error } = props;
  const formRef = useRef<FormInstance>();
  const servicesGet = useTableServiceRow();
  const actionRef = useRef<ActionType>();

  useEffect(() => {
    if (servicesGet.error) {
      message.error(getMessageInError(servicesGet.error));
      servicesGet.clearError();
    }
  }, [servicesGet.error]);

  useEffect(() => {
    if (!loading && !error) {
      actionRef.current?.reload();
    }
  }, [loading]);

  const handleServiceEdit = (id: string) => {
    openModal?.({ type: ModalTypes.update, key: 'service', id });
  };

  const tableRequest = (): Promise<Partial<RequestData<ServiceTableRow>>> => servicesGet.fetch(
    { orderByColumn: 'createdAt', orderBy: 'ASC' },
  ).then((data) => {
    if (data) {
      const { services, total } = data;

      return ({ data: services || [], success: true, total });
    }

    return ({ data: [], success: false, total: 0 });
  });

  const columns: ProColumns<ServiceTableRow>[] = [
    {
      title: 'Service Name',
      dataIndex: 'name',
      sorter: false,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
      renderText: (status) => <Status status={status} />,
    },
    {
      title: '',
      align: 'center',
      hideInSetting: true,
      width: 60,
      renderText: (_, row) => (
        <Button type="text" onClick={() => handleServiceEdit(row.id)}>
          <SettingFilled />
        </Button>
      ),
    },
  ];

  return (
    <Table<ServiceTableRow>
      formRef={formRef}
      columns={columns}
      actionRef={actionRef}
      request={tableRequest}
      pagination={false}
      headerTitle={(
        <>
          <ControlFilled />
          Services list
        </>
      )}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-services', persistenceType: 'localStorage' }}
      onRow={(record) => ({
        onClick: () => {
          handleServiceEdit(record.id);
        },
      })}
    />
  );
}

ServicesTable.defaultProps = {
  openModal: undefined,
};

const mapStateToProps = (state: RootState) => ({
  loading: state[moduleName].loading,
  error: state[moduleName].error,
});

export default connect(mapStateToProps)(ServicesTable);
