import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';

interface Confirm {
  onOk: () => void;
  title: string;
  content: string;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
  zIndex?: number;
  centered?: boolean;
}

const confirm = ({
  onOk, onCancel, title, content, okText = 'Confirm', cancelText = 'Cancel', zIndex = 1002, centered = false,
}: Confirm): void => {
  // eslint-disable-next-line max-len
  Modal.confirm({ title, icon: <ExclamationCircleOutlined />, content, okText, cancelText, onOk, onCancel, zIndex, centered });
};

export default confirm;
