import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Row, Tooltip, Typography } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import ContentCard from '../../../../../Common/ContentCard';
import { required, requiredDatepicker } from '../../../../../../utils/inputRules';
import SelectPatient from '../../../../../Common/Selects/SelectPatient';
import SelectPractice from '../../../../../Common/Selects/SelectPractice';
import SelectDoctor from '../../../../../Common/Selects/SelectDoctor';

interface AdminCaseFormItems {
  loading?: boolean;
  disabled?: boolean;
}

const AdminCaseFormItems: React.FC<AdminCaseFormItems> = (props) => {
  const { loading, disabled } = props;
  const form = useFormInstance();

  const [practice, setPractice] = useState<string>('');
  const [doctor, setDoctor] = useState<string>('');

  const practiceValue = Form.useWatch('practice', form);
  const doctorValue = Form.useWatch('doctor', form);

  useEffect(() => {
    setPractice(practiceValue);
  }, [practiceValue]);

  useEffect(() => {
    setDoctor(doctorValue);
  }, [doctorValue]);

  const handleClear = (field: string) => {
    if (field === 'practice') {
      form?.setFields([
        { name: 'doctor', value: null },
        { name: 'patient', value: null },
      ]);
    }
    if (field === 'doctor') {
      form?.setFields([{ name: 'patient', value: null }]);
    }
  };

  form.getFieldsValue();

  return (
    <div>
      <Typography.Title level={4}>Patient Information</Typography.Title>
      <ContentCard loading={loading}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              label="Select Practice"
              name="practice"
              rules={required}
            >
              <SelectPractice
                disabled={disabled}
                onChange={(value) => {
                  setPractice(value as string);
                  handleClear('practice');
                }}
                onClear={() => handleClear('practice')}
                onlyStatus="active"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Select Doctor"
              name="doctor"
              rules={required}
            >
              <SelectDoctor
                disabled={!(practice || form?.getFieldValue('practice')) || disabled}
                practice={practice || form?.getFieldValue('practice')}
                onChange={(value) => {
                  setDoctor(value as string);
                  handleClear('doctor');
                }}
                onClear={() => handleClear('doctor')}
                onlyStatus="active"
                requestOnlyWithPractice
              />
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item
              label="Select Patient"
              name="patient"
              rules={required}
            >
              <SelectPatient
                disabled={!(doctor || form?.getFieldValue('doctor')) || disabled}
                doctor={doctor || form?.getFieldValue('doctor')}
                onlyActive
                requestOnlyWithDoctor
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Due Date"
              name="dueDate"
              rules={requiredDatepicker}
            >
              <DatePicker
                format="MMM DD, YYYY"
                style={{ width: '100%' }}
                disabled={disabled}
                disabledDate={(current) => moment().add(-1, 'days') >= current}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label=" ">
              <Tooltip placement="bottom" title="The deadline by which the lab has to complete all requested services">
                <InfoCircleOutlined style={{ fontSize: '16px' }} />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </ContentCard>
    </div>
  );
};

AdminCaseFormItems.defaultProps = {
  loading: false,
  disabled: false,
};

export default AdminCaseFormItems;
