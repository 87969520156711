import { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { CheckOutlined } from '@ant-design/icons';
import { JsonResult } from '../../../../../types';

import { useContextCaseFlow } from '../../../../../context/caseFlow';
import NotFoundContent from '../../../../Common/NotFoundContent';
import { formatAddressToString } from '../../../../../utils';
import ServiceCard, { DescriptionItem } from '../../../../Common/ServiceCard';
import { Tooth } from '../../../../../hooks/cases';
import { PriceLevelService } from '../../../../../hooks/priceLevels';
import { requiredDatepicker } from '../../../../../utils/inputRules';
import { isThisDayEvent } from '../../../../../utils/datePickerHelpers';

interface AssignServiceForm {
  initialData?: JsonResult;
  disabled?: boolean;
  onSubmit?: () => void;
  handleChange?: (field: JsonResult[]) => void;
  handleServicesChange?: (services: PriceLevelService[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
  practiceId?: string;
  isUpdate?: boolean;
  formType?: 'lab' | 'staff';
  isDatePickerDisabled?: boolean;
  selectedLab?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTriggerReloadParams?: React.Dispatch<React.SetStateAction<any>>;
}

const AssignServiceForm: React.FC<AssignServiceForm> = (props) => {
  const {
    initialData,
    onSubmit,
    children,
    form,
    handleChange,
    handleServicesChange,
    practiceId,
    isUpdate,
    formType,
    isDatePickerDisabled,
    selectedLab,
    setTriggerReloadParams,
  } = props;

  const {
    selectTreatmentPlan, printingInfo, foundPrintingService, caseByIdInContext,
    foundSurgicalService, foundRestorativeService, teethValues, materialImplantId, materialCrownId,
    practicePriceLevelServiceInContext, implantInfo, isPriceLevelServiceAssigned,
    isPriceLevelServiceStaffAssigned, getCaseServiceByPriceParentServiceId, eventsListGetInContext,
  } = useContextCaseFlow();

  const servicesLength = selectTreatmentPlan.services?.length || caseByIdInContext?.data?.teeth?.services?.length || 0;

  const [priceLevelServicesSelected, setPriceLevelServicesModified] = useState<PriceLevelService[]>([]);
  const [disabledTimeout, setDisabledTimeout] = useState<boolean>(false);

  useEffect(() => {
    if (disabledTimeout) {
      setTimeout(() => setDisabledTimeout(false), 2000);
    }
  }, [disabledTimeout]);

  useEffect(() => {
    handleServicesChange?.(priceLevelServicesSelected);
  }, [priceLevelServicesSelected]);

  useEffect(() => {
    if (selectTreatmentPlan.services && (caseByIdInContext?.data || practicePriceLevelServiceInContext?.data)) {
      setPriceLevelServicesModified((caseByIdInContext?.data?.practice?.priceLevelServices
        || practicePriceLevelServiceInContext?.data?.priceLevel?.priceLevelServices)
        ?.filter((service) => (selectTreatmentPlan.services?.includes(service?.id || ''))) || []);
    }
  }, [selectTreatmentPlan, practicePriceLevelServiceInContext?.data]);

  useEffect(() => {
    if (practiceId && practicePriceLevelServiceInContext && !isUpdate) {
      practicePriceLevelServiceInContext.fetch(undefined, `${practiceId}/price-level-service`);
    }
  }, [practiceId]);

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
  };

  /** Copied from Summary info form functions \/ */
  const getCountBridges = (): string => {
    const countArr: Tooth[][] = [];
    let arrIndex = 0;
    let lastIndex: number | null = null;

    teethValues.sort((a, b) => a.index > b.index ? 1 : -1).forEach((tooth) => {
      if (tooth.isBridge) {
        if ((!lastIndex || tooth.index - lastIndex === 1)
          && (!lastIndex || (lastIndex <= 16 && tooth.index <= 16) || (lastIndex >= 17 && tooth.index >= 17))
        ) {
          lastIndex = tooth.index;
          countArr[arrIndex] = [...(countArr[arrIndex] || []), tooth];
        } else {
          arrIndex += 1;
          lastIndex = tooth.index;
          countArr[arrIndex] = [...(countArr[arrIndex] || []), tooth];
        }
      }
    });

    return `${countArr.length}`;
  };

  const getDescriptionServicesItems = (serviceId: string): DescriptionItem[] => {
    if (serviceId === foundSurgicalService?.id) {
      return [{
        label: 'Implant',
        content: `${teethValues.filter((tooth) => tooth.materials?.includes(materialImplantId))?.length}`,
      }, {
        label: 'Implant Manufacturer',
        content: implantInfo.manufacturer || '-',
      }, {
        label: 'Implant Family',
        content: implantInfo.family || '-',
      }];
    }

    if (serviceId === foundRestorativeService?.id) {
      return [{
        label: 'Crown',
        content: `${teethValues.filter((tooth) => tooth.materials?.includes(materialCrownId))?.length}`,
      }, {
        label: 'Bridge',
        content: getCountBridges(),
      }, {
        label: 'Tooth Shade',
        content: implantInfo.shade || '-',
      }];
    }

    if (serviceId === foundPrintingService?.id) {
      return [{
        label: 'Implant Manufacturer',
        content: foundPrintingService.priceLevelMaterials
          ?.find((material) => material.id === printingInfo?.material)?.material?.name || '',
        style: { gridTemplateColumns: '1fr' },
      }, {
        label: 'Delivery Address',
        content: formatAddressToString(
          practicePriceLevelServiceInContext?.data?.addresses
            ?.find((address) => (address.id === printingInfo?.address)),
        ) || '',
        style: { gridTemplateColumns: '1fr' },
      }];
    }

    return [{ label: '-', content: '-' }];
  };

  /** Copied from Summary info form functions /\ */

  const servicesArrValues: string[] = Form.useWatch('services', form);

  useEffect(() => {
    form.validateFields();
  }, [servicesArrValues]);

  useEffect(() => {
    const newServices = form.getFieldValue('services')?.filter((item: string) => {
      const priceLevelSelected = priceLevelServicesSelected.find((priceService) => (
        priceService.id === item
      ));

      const isSelectedFieldService = isPriceLevelServiceAssigned(priceLevelSelected?.service?.id, ['declined']);
      // Example how it works: if status rejected then return false and then don't need to clear checkbox.

      return !isSelectedFieldService;
    });

    form.setFieldValue(
      'services',
      newServices,
    );
  }, [initialData]);

  return (
    <div>
      <Row justify="space-between">
        <Typography.Title level={4} style={{ marginBottom: 0 }}>Services</Typography.Title>
      </Row>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        onFinish={onSubmit && handleSubmit}
        onFieldsChange={handleChange}
        disabled={!!caseByIdInContext?.loading}
      >
        <Row gutter={15}>

          {/** Returns array[] of selected id's with name 'services' */}
          <Col span={24}>
            <Form.Item
              name="services"
              rules={[{
                required: true,
                message: caseByIdInContext?.data?.caseServices?.length
                === priceLevelServicesSelected.length ? '' : 'You have to select at least one service!' }]}
            >
              <Checkbox.Group style={{ width: '100%' }}>
                <Row gutter={[20, 20]} style={{ minHeight: '60px' }}>
                  {priceLevelServicesSelected.length ? priceLevelServicesSelected
                    .sort((a, b) => `${a?.name}` < `${b?.name}` ? 1 : -1)
                    .map((priceService) => {
                      const isLabAssigned = isPriceLevelServiceAssigned(priceService.service?.id, ['declined']);
                      const isCardFieldSelected = formType === 'lab'
                        ? isLabAssigned
                        : isPriceLevelServiceStaffAssigned(priceService.service?.id);

                      const isStaffCanBeAssigned = isPriceLevelServiceAssigned(
                        priceService.service?.id,
                        ['declined', 'unassigned', 'request'],
                      );

                      const caseService = getCaseServiceByPriceParentServiceId(priceService?.service?.id);

                      return (
                        <Col key={priceService.id} style={{ flex: `1 1 ${100 / servicesLength}%`, minWidth: '324px' }}>
                          <ServiceCard
                            secondaryColorScheme
                            title={`${priceService.name}`}
                            status={(caseService?.status === 'declined' || caseService?.status === 'completed')
                              ? caseService?.status : undefined}
                            descriptions={{ items: isLabAssigned
                              ? [{
                                label: 'Assigned Lab',
                                content: caseService?.labService?.lab?.name || '',
                              }, ...getDescriptionServicesItems(priceService.id || '')]
                              : getDescriptionServicesItems(priceService.id || '') }}
                            additionalOptions={priceService.priceLevelAdditionalOptions?.filter((option) => (
                              selectTreatmentPlan?.[priceService?.id || '']?.includes(option.id)
                            ))?.map((item) => ({ content: item?.additionalOption?.name || '' })) || []}
                            extra={(
                              <div style={{ display: 'flex', gap: '10px' }}>
                                {priceService?.id !== foundPrintingService?.id && (
                                <Form.Item
                                  name={`dueDate-${priceService.id}`}
                                  className="no-space-form-item"
                                  rules={servicesArrValues?.includes(priceService?.id || '') ? requiredDatepicker : [{
                                    required: false,
                                    message: '',
                                  }]}
                                >
                                  <DatePicker
                                    format="MMM DD, YYYY"
                                    placeholder="Select due date"
                                    style={{ width: '100%' }}
                                    disabledDate={(current) => (moment().add(-1, 'days') >= current)
                                      || moment(caseByIdInContext?.data?.dueDate).add(1, 'days') <= current
                                      || isThisDayEvent(current, eventsListGetInContext?.data?.data)}
                                    disabled={isDatePickerDisabled || isCardFieldSelected}
                                    onChange={setTriggerReloadParams ? (e) => setTriggerReloadParams(e || Date.now())
                                      : undefined}
                                  />
                                </Form.Item>
                                )}
                                {isCardFieldSelected || caseService?.status === 'completed'
                                  ? (<div><CheckOutlined /></div>) : (
                                    <Tooltip
                                      title={
                                      (disabledTimeout && 'Waiting for table update...')
                                      || (formType === 'staff'
                                    && (((!isLabAssigned || !isStaffCanBeAssigned) && 'Lab is not assigned yet')
                                    || (!!selectedLab && caseService?.labService?.lab?.id !== selectedLab
                                      && 'You can\'t choose services assigned to different labs')))
                                  }
                                    >
                                      <Checkbox
                                        value={priceService.id}
                                        disabled={disabledTimeout || (formType === 'staff'
                                      && (!isLabAssigned
                                      || !isStaffCanBeAssigned
                                      || (!!selectedLab && caseService?.labService?.lab?.id !== selectedLab)))}
                                        onClick={() => setDisabledTimeout(true)}
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                          )}
                            dueDateExtra={priceService.id === foundPrintingService?.id
                            && !!caseByIdInContext?.data?.dueDate
                            && moment(caseByIdInContext?.data?.dueDate).format('MMM DD, YYYY')}
                          />
                        </Col>
                      );
                    }) : (
                      <NotFoundContent message="Not found any service." fullWidth />
                  )}

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>

        </Row>
      </Form>
      {children}
    </div>
  );
};

AssignServiceForm.defaultProps = {
  initialData: {},
  disabled: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  handleServicesChange: () => undefined,
  children: null,
  practiceId: undefined,
  isUpdate: false,
  formType: 'lab',
  isDatePickerDisabled: false,
  selectedLab: null,
  setTriggerReloadParams: undefined,
};

export default AssignServiceForm;
