import ProTable from '@ant-design/pro-table';
import React, { useCallback, useRef } from 'react';
import { Button, Divider, Tabs } from 'antd';
import { ParamsType } from '@ant-design/pro-provider';
import { ProTableProps } from '@ant-design/pro-table/lib/typing';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CloseOutlined } from '@ant-design/icons';
import { TabsProps } from 'rc-tabs/lib/Tabs';
import useCurrentLocation from '../../../hooks/router';
import { JsonResult } from '../../../store/ducks/common';
import { TableContext, useContextTable } from '../../../context/table';

interface StatusesFilter extends TabsProps {
  valuesEnum: JsonResult; // ProSchemaValueEnumObj | ProSchemaValueEnumMap
  onChange: (activeKey: string) => void;
  activeKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormWrapperComponent = (props: any) => {
  const { children, ...args } = props;

  const { onFilterClose } = useContextTable();

  return (
    <form {...args}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <span style={{ fontSize: '20px', fontWeight: 600 }}>Filter</span>
        <Button type="text" onClick={onFilterClose}>
          <CloseOutlined style={{ fontSize: '16px' }} />
        </Button>
      </div>
      {children}
    </form>
  );
};

export default function Table<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text'
>(props: ProTableProps<DataType, Params, ValueType>
  & { onFilterClose?: () => void; } & { statusesFilter?: StatusesFilter; }): JSX.Element {
  const key = useRef<number>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentLocation = useCurrentLocation();

  const { pagination, search, onFilterClose, statusesFilter, rowClassName } = props;

  const newProps = {
    dateFormatter: 'string',
    ...props,
    key: key.current,
  } as ProTableProps<DataType, Params, ValueType>;

  newProps.options = {
    density: false,
    ...newProps.options,
  };

  newProps.form = search ? undefined : {
    syncToUrl: true,
    extraUrlParams: { search: searchParams.get('search') },
    component: FormWrapperComponent,
    collapsed: false,
    collapseRender: false,
    ...newProps.form,
  };

  newProps.pagination = pagination ?? {
    defaultPageSize: 10,
    size: 'small',
    ...newProps.pagination,
  };

  newProps.search = search ?? {
    optionRender: ({ form }) => ([
      <div key="controls">
        <Divider />
        <div className="table-form-controls">
          <Button
            key="Clear"
            onClick={(e) => {
              e.preventDefault();
              setTimeout(() => {
                key.current = Date.now();
                navigate(currentLocation, { replace: true });
              }, 0);
            }}
          >
            Clear All
          </Button>
          <Button
            key="Query"
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              form?.submit();
            }}
          >
            Apply
          </Button>
        </div>
      </div>,
    ]),
    ...newProps.search,
  };

  const toolbarFiltersRender = useCallback(() => [
    <Tabs
      key="tabs"
      className="table-filter-tabs"
      defaultActiveKey={searchParams.get('status') || 'all'}
      activeKey={statusesFilter?.activeKey || 'all'}
      onChange={(activeKey) => { statusesFilter?.onChange(activeKey); }}
      items={statusesFilter?.valuesEnum
        ? (Object.entries(statusesFilter.valuesEnum).map(([key, value]) => ({
          key,
          label: value,
        })))
        : []}
    />,
  ], [statusesFilter?.activeKey, statusesFilter?.onChange]);

  newProps.toolbar = statusesFilter ? {
    ...newProps.toolbar,
    multipleLine: true,
    filter: toolbarFiltersRender(),
  } : {
    ...newProps.toolbar,
  };

  newProps.rowClassName = (r, index) => {
    if (rowClassName) {
      return index % 2 === 0 ? `table-second-row ${rowClassName}` : `${rowClassName}`;
    }

    return index % 2 === 0 ? 'table-second-row' : '';
  };

  return (
    <TableContext.Provider value={{ onFilterClose }}>
      <ProTable<DataType, Params, ValueType> {...newProps} />
    </TableContext.Provider>
  );
}

Table.defaultProps = {
  onFilterClose: undefined,
  statusesFilter: undefined,
};
