import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, message, Row, Space } from 'antd';
import Icon, { DownOutlined, SettingFilled } from '@ant-design/icons';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { MenuItemGroupType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import { CreateAddress } from '../../../../../hooks/addresses';
import { PracticeUpdatePayload } from '../../../../../store/ducks/practice';
import { JsonResult } from '../../../../../types';
import { PreparedPracticeInitialData, preparePracticeBody } from '../../../../../utils/prepareData';
import PracticeForm from '../../Forms/PracticeForm';
import { isRoleEnough } from '../../../../../utils/auth';
import { ArchiveFilled, Send } from '../../../../Common/Icon';
import { capitalizeFirstLetter, onActionClick } from '../../../../../utils';
import { usePracticeResendLink, useUpdatePracticeStatus } from '../../../../../hooks/practices';
import { getMessageInError } from '../../../../../hooks/fetch';

interface PracticeInfo {
  loading?: boolean;
  initialData: PreparedPracticeInitialData;
  handleSubmit: (values: PracticeUpdatePayload) => void;
  reloadData?: () => void;
}

const PracticeInfo: React.FC<PracticeInfo> = (props) => {
  const [form] = Form.useForm();
  const { loading, initialData, handleSubmit, reloadData } = props;

  const practiceResendLink = usePracticeResendLink();
  const updatePracticeStatus = useUpdatePracticeStatus();

  const addressesState = useState<CreateAddress[]>(initialData?.addresses || []);
  const sameAsBillingState = useState<boolean | undefined>(initialData?.sameAsBilling || false);

  const [addresses] = addressesState;
  const [sameAsBilling] = sameAsBillingState;

  const onSubmit = () => {
    form.validateFields().then((values: JsonResult) => {
      const body = preparePracticeBody(values, addresses, sameAsBilling);

      handleSubmit(body);
    });
  };

  useEffect(() => {
    if (addresses !== initialData.addresses || sameAsBilling !== initialData.sameAsBilling) {
      onSubmit();
    }
  }, [addresses, sameAsBilling]);

  useEffect(() => {
    if (updatePracticeStatus.data && !updatePracticeStatus.error) {
      reloadData?.();
    }
  }, [updatePracticeStatus.data]);

  const [lastActionName, setLastActionName] = useState<string>('');

  const menuItems: (MenuItemType | SubMenuType | MenuItemGroupType)[] = [
    {
      label: 'Resend Link',
      key: '1',
      icon: <Icon component={Send} />,
      onClick: () => {
        onActionClick({
          title: 'Resend Link',
          content: 'Are you sure you want to resend link?',
          actionName: 'resend link',
          hook: () => practiceResendLink.fetch(undefined, `${initialData?.id || ''}/resend`),
        });
        setLastActionName('link was send');
      },
      className: 'resendLinkBtn',
    },
    initialData.practice?.status === 'deactivated' ? {
      label: 'Activate',
      key: '5',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updatePracticeStatus.fetch({ status: 'active' }, initialData?.id),
        });
        setLastActionName('activated');
      },
      className: 'activateBtn',
    } : {
      label: 'Deactivate',
      key: '3',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          title: 'Deactivate',
          actionName: 'deactivate',
          hook: () => updatePracticeStatus.fetch({ status: 'deactivated' }, initialData?.id),
        });
        setLastActionName('deactivated');
      },
      className: 'deactivateBtn',
    },
    initialData.practice?.status === 'archived' ? {
      label: 'Unarchive',
      key: '6',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updatePracticeStatus.fetch({ status: 'active' }, initialData?.id),
        });
        setLastActionName('activated');
      },
      className: 'unarchiveBtn',
    } : {
      label: 'Archive',
      key: '4',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Archive',
          actionName: 'archive',
          hook: () => updatePracticeStatus.fetch({ status: 'archived' }, initialData?.id),
        });
        setLastActionName('archived');
      },
      className: 'archiveBtn',
    },
  ];

  useEffect(() => {
    if ((updatePracticeStatus.data && !updatePracticeStatus.error)
      || (practiceResendLink.data && !practiceResendLink.error)) {
      message.success(`${capitalizeFirstLetter(lastActionName)}!`);
    }
  }, [updatePracticeStatus.data, practiceResendLink.data]);

  useEffect(() => {
    if (updatePracticeStatus.error) {
      message.error(getMessageInError(updatePracticeStatus.error));
      updatePracticeStatus.clearError();
    }
  }, [updatePracticeStatus.error]);

  useEffect(() => {
    if (practiceResendLink.error) {
      message.error(getMessageInError(practiceResendLink.error));
      practiceResendLink.clearError();
    }
  }, [practiceResendLink.error]);

  return (
    <Row justify="center">
      <Col span={24} md={20} lg={18} xxl={14}>
        <div>
          <PracticeForm
            editMode
            requiredFieldsType="completeRegistration"
            isAdmin={!!isRoleEnough('admin')}
            onSubmit={onSubmit}
            initialData={initialData?.practice}
            form={form}
            addressesState={addressesState}
            sameAsBillingState={sameAsBillingState}
            loading={loading}
          />
          {!!isRoleEnough('admin') && (
            <Row justify="end" style={{ marginTop: '30px' }}>
              <Col>
                <Space>
                  <Dropdown
                    trigger={['click']}
                    key="button"
                    menu={{
                      items: menuItems
                        .filter((item) => initialData?.practice?.status === 'pending'
                          ? item : item.label !== 'Resend Link')
                        .filter((item) => (initialData?.practice?.status === 'deactivated'
                        || initialData?.practice?.status === 'archived')
                          ? item : (item.label !== 'Archive' && item.label !== 'Unarchive')),
                    }}
                  >
                    <Button type="ghost" onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
                      <Space>
                        <SettingFilled />
                        Actions
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

PracticeInfo.defaultProps = {
  loading: false,
  reloadData: () => undefined,
};

export default PracticeInfo;
