import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, FormInstance, message } from 'antd';
import { moduleName } from '../../../../store/ducks/practice';
import { RootState } from '../../../../store/reducers';
import Status from '../../../Common/Status';
import { Error } from '../../../../store/ducks/common';

import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState, ModalTypes } from '../../../../types';
import { TablePracticeRow, useTablePracticeRow } from '../../../../hooks/practices';
import { formatAddressToString, formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../utils';
import { BuildingHouse } from '../../../Common/Icon';
import { PracticeStatuses, PracticeStatusesOptions } from '../../../../enums/practice';
import PracticeCreateButton from '../CreateButton';
import { isRoleEnough } from '../../../../utils/auth';

interface TablePractices {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  practiceLoading: boolean;
  error: Error | null;
}

function TablePractices(props: TablePractices)
  : JSX.Element {
  const {
    params,
    openModal,
    selectedRows,
    onRowSelection,
    practiceLoading,
    error,
  } = props;
  const navigate = useNavigate();
  const formRef = useRef<FormInstance>();
  const practicesGet = useTablePracticeRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!practiceLoading && !error) {
      actionRef.current?.reload();
    }
  }, [practiceLoading]);

  useEffect(() => {
    if (practicesGet.error) {
      message.error(getMessageInError(practicesGet.error));
      practicesGet.clearError();
    }
  }, [practicesGet.error]);

  const toolBarRender = () => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    isRoleEnough('admin') && (
    <PracticeCreateButton
      title="Add New Practice"
      key="button"
      id="addButton"
      icon={<PlusOutlined />}
      type="primary"
      isAdmin
      withEmailForm
    >
      Add New Practice
    </PracticeCreateButton>
    ),
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TablePracticeRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return practicesGet.fetch({
      ...newParams,
      ...params,
    }).then((data) => {
      if (data) {
        const { practices, total } = data;

        return ({ data: practices || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TablePracticeRow>[] = [
    {
      title: 'Practice Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Account #',
      dataIndex: 'accountNumber',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status) => <Status status={status} />,
      valueEnum: PracticeStatusesOptions,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
      formItemProps: {
        rules: [{ min: 2 }],
      },
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (phone) => formatPhoneNumber(phone, 'international'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      sorter: false,
      ellipsis: true,
      renderText: (_, row) => (row?.addresses?.map((address) => formatAddressToString(address))
        .join(';')),
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TablePracticeRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      form={{ ignoreRules: false }}
      headerTitle={(
        <>
          <BuildingHouse />
          Practices list
        </>
      )}
      rowSelection={!!onRowSelection && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-practices', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => {
          if (record.status === PracticeStatuses.request) {
            openModal?.({ type: ModalTypes.info, key: 'practice', id: record.id });
          } else {
            navigate({ pathname: `/practices/${record.id}` }, { replace: true });
          }
        },
      })}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  practiceLoading: state[moduleName].loading,
  error: state[moduleName].error,
});

TablePractices.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
};

export default connect(mapStateToProps)(TablePractices);
