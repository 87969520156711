import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CountriesGetParams, useCountriesGet, useCountryId } from '../../../../hooks/addresses';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';

const SelectCountry = ({ value: selectValue, onClear, ...props }: SelectProps<string, Option>): JSX.Element => {
  const countriesGet = useCountriesGet();
  const countryById = useCountryId();

  const [options, setOptions] = useState<Option[]>([]);
  const [country, setCountry] = useState<string>('');

  const [page, setPage] = useState(1);

  const fetch = (pageProp?: number) => {
    const params: CountriesGetParams = { page: pageProp || page, take: 100, orderBy: 'ASC', orderByColumn: 'name' };

    if (country) {
      params.search = country;
    }

    countriesGet.fetch(params);
  };

  useEffect(() => {
    if (country && country.length > 1) {
      setPage(1);

      const id = setTimeout(() => fetch(1), 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [country]);

  useEffect(() => {
    if (countriesGet.data?.data.length) {
      /** If the current page is not 1, then add options to the end of the array. */
      if (page !== 1) {
        const isSelectedItemInResponse = !!countriesGet.data.data.find((option) => (
          JSON.stringify(option) === selectValue
        ));

        const filteredOptions = options.filter((option) => {
          if (isSelectedItemInResponse) return option.value !== selectValue;

          return option;
        });

        setOptions([...filteredOptions, ...countriesGet.data.data.map((item): Option => ({
          label: item.name, value: JSON.stringify(item), id: `${item.name}` }))]);
      } else { /** Else replace old options with new */
        setOptions(countriesGet.data.data.map((item): Option => ({
          label: item.name, value: JSON.stringify(item), id: `${item.name}` })));
      }
    }
  }, [countriesGet.data]);

  useEffect(() => {
    if (options.length && selectValue) {
      const selectedValueId = selectValue && JSON.parse(selectValue).id;
      const current = options.find(({ value }) => value === selectValue);

      if (!current && selectedValueId) {
        countryById.fetch(undefined, `${selectedValueId}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: JSON.stringify(res) },
          ]));
      }
    }
  }, [options, selectValue]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (countriesGet.loading || countryById.loading || !countriesGet.data?.meta.hasNextPage || !!country) {
      return;
    }
    const target = e.target as HTMLDivElement;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPage((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    if (page > 1) {
      fetch();
    }
  }, [page]);

  useEffect(() => {
    if (countriesGet.error) {
      message.error(getMessageInError(countriesGet.error));
      countriesGet.clearError();
    }
  }, [countriesGet.error]);

  const handleClear = () => {
    setCountry('');

    if (!onClear) return;

    onClear();
  };

  return (
    <Select
      loading={countriesGet.loading || countryById.loading}
      options={options}
      value={countriesGet.loading || countryById?.loading ? null : selectValue}
      onSearch={(name) => setCountry(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      notFoundContent={<NotFoundContent message="Country not found." />}
      onPopupScroll={onScroll}
      listHeight={256}
      {...props}
    />
  );
};

export default SelectCountry;
