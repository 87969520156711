import { BellFilled } from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import { useLocation } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNotificationsGet, useUpdateNotificationStatus } from '../../../hooks/notifications';
import NotificationsContent from './NotificationModalContent';
import styles from './index.module.less';

const NotificationsModal: React.FC<{ iconClassName?: string; }> = ({ iconClassName }) => {
  const location = useLocation();
  const notificationsGet = useNotificationsGet();
  const notificationsGetUnread = useNotificationsGet();
  const updateNotificationStatus = useUpdateNotificationStatus();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);

  const loadNotifications = () => {
    notificationsGet.fetch({ orderBy: 'DESC', removed: false, page: 1, take: 3 });
    notificationsGetUnread.fetch({ saw: false });
  };

  useEffect(() => {
    setInterval(() => {
      loadNotifications();
    }, 300000);
  }, []);

  useEffect(() => {
    if (!isNotificationsOpen) return;

    setIsNotificationsOpen(false);
  }, [location]);

  useEffect(() => {
    const { loading, error } = updateNotificationStatus;

    if (!loading && !error) {
      loadNotifications();
    }
  }, [updateNotificationStatus.loading]);

  const markAsRead = (id: string) => {
    updateNotificationStatus.fetch({ saw: true }, id);
  };

  useEffect(() => {
    if (isNotificationsOpen) {
      loadNotifications();

      return;
    }

    const unreadNotifications = notificationsGet?.data?.data?.filter((notification) => !notification.status.saw);

    if (!unreadNotifications || !unreadNotifications?.length) return;

    Promise.all(
      unreadNotifications.map((notification) => markAsRead(notification.id)),
    ).then(() => {
      notificationsGetUnread.fetch({ saw: false });
    });
  }, [isNotificationsOpen]);

  return (
    <Popover
      open={isNotificationsOpen}
      trigger={['click']}
      placement="bottom"
      onOpenChange={(visible) => setIsNotificationsOpen(visible)}
      overlayClassName="clean-tooltip-overlay"
      showArrow={false}
      content={<NotificationsContent notifications={notificationsGet.data?.data} />}
    >
      <Badge count={notificationsGetUnread.data?.meta?.itemCount}>
        <BellFilled className={clsx(styles.notificationsButton, iconClassName)} />
      </Badge>
    </Popover>
  );
};

NotificationsModal.defaultProps = {
  iconClassName: undefined,
};

export default NotificationsModal;
