import Simple from '../Simple';
import { LogoDark } from '../../Common/Icon';

import styles from './index.module.less';

interface Simple {
  children: React.ReactNode;
}

export default function Auth({ children }: Simple): JSX.Element {
  return (
    <Simple>
      <div className={styles.wrp}>
        <LogoDark className={styles.logo} width="86px" height="48px" />
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </Simple>
  );
}
