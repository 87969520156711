import { Divider, Form, FormInstance, Input } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Callbacks } from 'rc-field-form/lib/interface';
import { LabService } from '../../../../../hooks/services';
import { JsonResult } from '../../../../../types';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import AdditionalOptions from '../../../Services/Forms/AdditionalOptions';
import Materials from '../../../Services/Forms/Materials';

interface LabServiceView {
  form: FormInstance;
  service?: LabService;
  serviceName: string;
  materialsName?: string;
  additionalOptionsName?: string;
  isEditingState: [boolean, Dispatch<SetStateAction<boolean>>];
  withPrices?: boolean;
  hideToggle?: boolean;
  editMode?: {
    handleSubmit: () => void;
    hideControls?: boolean;
  };
  materialsRowExtra?: React.ReactNode;
  onFormFieldsChange?: Callbacks<JsonResult>['onFieldsChange'];
}

const LabServiceView: React.FC<LabServiceView> = (props) => {
  const {
    form,
    service,
    isEditingState,
    serviceName,
    editMode,
    withPrices,
    hideToggle,
    materialsName,
    additionalOptionsName,
    materialsRowExtra,
    onFormFieldsChange,
  } = props;

  const [isEditing, setIsEditing] = isEditingState;
  const [initialValues, setInitialValues] = useState<JsonResult>({});

  useEffect(() => {
    if (!service) return;

    setInitialValues(service);
  }, [service, isEditing]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        initialValues={initialValues}
        onFieldsChange={onFormFieldsChange ? (e, k) => onFormFieldsChange(e, k) : undefined}
      >
        <Form.Item name="id" className="hidden-input">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="service" className="hidden-input">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="name" className="hidden-input">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="status" className="hidden-input">
          <Input type="hidden" />
        </Form.Item>
        <Materials
          isEditing={isEditing || !editMode}
          serviceName={serviceName}
          name={materialsName || 'labMaterials'}
          withPrices={withPrices}
          hideToggle={hideToggle}
          withoutAdding
          nameDisabled
          materialsRowExtra={materialsRowExtra}
        />
        <Divider />
        <AdditionalOptions
          isEditing={isEditing || !editMode}
          name={additionalOptionsName || 'labAdditionalOptions'}
          withPrices={withPrices}
          hideToggle={hideToggle}
          withoutAdding
          nameDisabled
        />
      </Form>
      {editMode && !editMode.hideControls && (
        <ControlsEditMode
          isEditing={isEditing}
          onCancel={handleCancel}
          onSubmit={editMode.handleSubmit}
          onChangeEditing={setIsEditing}
          isFormValid
        />
      )}

    </>
  );
};

LabServiceView.defaultProps = {
  withPrices: false,
  hideToggle: false,
  service: undefined,
  editMode: undefined,
  materialsName: undefined,
  additionalOptionsName: undefined,
  materialsRowExtra: undefined,
  onFormFieldsChange: () => undefined,
};

export default LabServiceView;
