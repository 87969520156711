import { Button, Col, Divider, Row, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

export interface PropEditMode {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: () => Promise<any> | void;
  onCancel: () => void;
  isFormValid?: boolean;
  loading?: boolean;
}

interface ControlsEditMode {
  isEditing: boolean;
  onChangeEditing: (isEditing: boolean) => void;
  onCancel: () => void;
  onSubmit?: () => void;
  isFormValid: boolean;
  loading?: boolean;
}

const ControlsEditMode: React.FC<ControlsEditMode> = ({
  isEditing,
  onCancel,
  onSubmit,
  isFormValid,
  onChangeEditing,
  loading,
}) => (
  <>
    <Divider />
    <Row justify="end">
      <Col>
        <Space>
          {!isEditing ? (
            <Button
              icon={<EditOutlined />}
              type="default"
              onClick={() => onChangeEditing(true)}
              loading={loading}
              id="editBtn"
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="ghost"
                onClick={onCancel}
                id="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                type="default"
                htmlType={onSubmit ? 'button' : 'submit'}
                onClick={onSubmit}
                disabled={!isFormValid}
                id="saveChangesBtn"
              >
                Save Changes
              </Button>
            </>
          )}
        </Space>
      </Col>
    </Row>
  </>
);

ControlsEditMode.defaultProps = {
  loading: false,
  onSubmit: undefined,
};

export default ControlsEditMode;
