import React from 'react';
import { Layout } from 'antd';

import Content from '../../components/Pages/Calendar';
import { displayName } from '../../config';

const CalendarPage = (): JSX.Element => {
  document.title = `${displayName}: Calendar`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default CalendarPage;
