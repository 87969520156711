import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import styles from './index.module.less';

// ExpandIcon props
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderExpandIcon = (props: any) => {
  const { isActive } = props;

  return (
    <div className={styles.iconWrapper}>
      {isActive ? <MinusOutlined /> : <PlusOutlined />}
    </div>
  );
};

export default RenderExpandIcon;
