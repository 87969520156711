import { LabStatus } from '../enums/lab';
import { UserStatus } from '../enums/user';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
} from './fetch';
import { PagingDataResponse } from '../types';
import { User } from './users';
import { Lab } from './labs';
import { LabServiceTable } from './services';
import { Address } from './addresses';

export interface LabStaffMember {
  id: string;
  position: string;
  status: string;
  lab: Lab;
  user: User | null;
  addresses?: Address[];
  labServices?: LabServiceTable[];
}

export interface LabStaffConfirm {
  secretKey: string;
}

export interface LabStaffGetParams {
  labs?: string[];
  name?: string;
  userStatus?: UserStatus;
  status?: LabStatus;
  email?: string;
  phone?: string;
  role?: string;
  position?: string;
  services?: string[];
  serviceAdditionalOptions?: string[];
  page?: number;
  take?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'name' |'status' | 'email' | 'phone' | 'role' | 'position' | 'services'; // service?
}

export interface TableLabStaffRow extends LabStaffMember {
  key: string;
}

interface LabStaffTableData {
  labStaff: TableLabStaffRow[];
  total: number;
}

// eslint-disable-next-line max-len
export const useTableLabStaffRow = (): FetchGet<PagingDataResponse<LabStaffMember>, LabStaffGetParams, DefaultFetchError, LabStaffTableData> => (
  useLabStaffGet((data: PagingDataResponse<LabStaffMember>): LabStaffTableData => ({
    labStaff: data.data.map((lab: LabStaffMember): TableLabStaffRow => ({
      key: lab.id,
      ...lab,
    })),
    total: data.meta.itemCount,
  }))
);

export function useLabStaffGet<D = PagingDataResponse<LabStaffMember>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, LabStaffGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LabStaffGetParams, DD>(
    'lab-staff',
    { decorateData, autoStart: false },
  );
}

export const useConfirmLabStaff = (): FetchCreate<LabStaffMember, DefaultFetchError, LabStaffConfirm> => useFetchCreate(
  'lab-staff/confirm-staff',
  {
    authorization: false,
  },
);

export const useLabStaffId = (): FetchGetId<LabStaffMember> => useFetchGetId('lab-staff', '', { autoStart: false });

export const useLabStaffResendLink = (): FetchGetId<LabStaffMember> => useFetchGetId('lab-staff', '', {
  autoStart: false, startStateLoading: false });
