import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Form, Input, message } from 'antd';
import { LockFilled } from '@ant-design/icons/lib';
import { connect } from 'react-redux';
import { confirmPassword, password as passwordRule } from '../../../utils/inputRules';
import { logUserActivity } from '../../../hooks/loggers';
import { RootState } from '../../../store/reducers';
import {
  moduleName,
  NewPassword as NewPasswordInterface,
  newPassword as actionNewPassword,
} from '../../../store/ducks/user';
import { Action } from '../../../store';
import { Error } from '../../../store/ducks/common';
import { FetchSuccess } from '../../../types';

interface NewPassword {
  newPassword: (payload: NewPasswordInterface) => Action;
  loadingNewPassword: boolean;
  responseNewPassword: FetchSuccess | null;
  errorNewPassword: Error | null;
}

const NewPassword = (props: NewPassword): JSX.Element => {
  const { newPassword, responseNewPassword, errorNewPassword, loadingNewPassword } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { token = '' } = useParams<{ token: string; }>();
  const [error, setError] = useState('');

  const clearError = () => setError('');

  const onFinish = async ({ password }: NewPasswordInterface): Promise<void> => {
    await newPassword({ password, secretKey: token });
  };

  useEffect(() => {
    if (responseNewPassword?.success) {
      message.success('Success!', 2)
        .then(() => navigate({ pathname: '/sign-in' }, { replace: true }));
    }
  }, [responseNewPassword]);

  useEffect(() => {
    if (errorNewPassword) {
      setError(errorNewPassword?.message || '');
      setTimeout(clearError, 15000);
    }
  }, [errorNewPassword]);

  const handleActivityLog = (action: string, element: string) => {
    logUserActivity({ pathname, action, element });
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ remember: true }}
    >
      {error ? (
        <Form.Item>
          <Alert
            type="error"
            onClose={clearError}
            message={error}
            closable
            showIcon
          />
        </Form.Item>
      ) : null}
      <Form.Item label="New Password" name="password" rules={passwordRule} hasFeedback>
        <Input.Password
          placeholder="Enter password"
          onFocus={() => handleActivityLog('focus', 'password input')}
        />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="passwordConfirm"
        rules={[...passwordRule, ...confirmPassword]}
        dependencies={['password']}
        hasFeedback
      >
        <Input.Password
          placeholder="Enter password"
          onFocus={() => handleActivityLog('focus', 'password confirmation input')}
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldsValue, getFieldsError }) => {
          const { password: passwordValue, passwordConfirm } = getFieldsValue();
          const fieldsError = getFieldsError();

          return (
            <Button
              size="large"
              type="default"
              block
              loading={loadingNewPassword}
              htmlType="submit"
              disabled={!passwordValue || !passwordConfirm || fieldsError.some((item) => item.errors.length)}
            >
              Submit
            </Button>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default connect((state: RootState) => ({
  loadingNewPassword: state[moduleName].loadingNewPassword,
  responseNewPassword: state[moduleName].responseNewPassword,
  errorNewPassword: state[moduleName].errorNewPassword,
}), {
  newPassword: actionNewPassword,
})(NewPassword);
