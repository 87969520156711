import React from 'react';
import { Layout } from 'antd';

import CaseFlowProvider from '../../../context/caseFlow';
import Content from '../../../components/Pages/Cases/PageCaseId';
import { displayName } from '../../../config';
import FileDownloadProvider from '../../../context/fileDownload';

function CaseId(): JSX.Element {
  document.title = `${displayName}: Case Info`;

  return (
    <Layout>
      <CaseFlowProvider>
        <FileDownloadProvider>
          <Content />
        </FileDownloadProvider>
      </CaseFlowProvider>
    </Layout>
  );
}

export default CaseId;
