import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  StatesGetParams,
  useStateId,
  useStatesGet,
} from '../../../../hooks/addresses';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';

interface SelectState extends SelectProps<string, Option> {
  country?: string;
}

const SelectState = ({ country, onClear, value: selectValue, ...props }: SelectState): JSX.Element => {
  const statesGet = useStatesGet();
  const stateById = useStateId();

  const [options, setOptions] = useState<Option[]>([]);
  const [state, setState] = useState<string>('');

  const [page, setPage] = useState(1);

  const fetch = (pageProp?: number) => {
    const params: StatesGetParams = { page: pageProp || page, take: 100, orderBy: 'ASC', orderByColumn: 'name' };

    if (country) {
      params.country = JSON.parse(country).id;
    }

    if (state) {
      params.search = state;
    }

    statesGet.fetch(params);
  };

  useEffect(() => {
    if (!country) return undefined;

    if (state && state.length > 1) {
      setPage(1);
      const id = setTimeout(() => fetch(1), 700);

      return () => clearTimeout(id);
    }

    fetch();

    return undefined;
  }, [state, country]);

  useEffect(() => {
    if (statesGet.data?.data.length) {
      /** If the current page is not 1, then add options to the end of the array. */
      if (page !== 1) {
        const isSelectedItemInResponse = !!statesGet.data.data.find((option) => (
          JSON.stringify(option) === selectValue
        ));

        const filteredOptions = options.filter((option) => {
          if (isSelectedItemInResponse) return option.value !== selectValue;

          return option;
        });

        setOptions([...filteredOptions, ...statesGet.data.data.map((item): Option => ({
          label: item.name, value: JSON.stringify(item), id: `${item.name}` }))]);
      } else { /** Else replace old options with new */
        setOptions(statesGet.data.data.map((item): Option => ({
          label: item.name, value: JSON.stringify(item), id: `${item.name}` })));
      }
    }
  }, [statesGet.data]);

  useEffect(() => {
    if (options.length && selectValue) {
      const selectedValueId = selectValue && JSON.parse(selectValue).id;
      const current = options.find(({ value }) => value === selectValue);

      if (!current && selectedValueId) {
        stateById.fetch(undefined, `${selectedValueId}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: JSON.stringify(res) },
          ]));
      }
    }
  }, [options, selectValue]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (statesGet.loading || stateById.loading || !statesGet.data?.meta.hasNextPage || !!state) {
      return;
    }
    const target = e.target as HTMLDivElement;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPage((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    if (page > 1) {
      fetch();
    }
  }, [page]);

  useEffect(() => {
    if (statesGet.error) {
      message.error(getMessageInError(statesGet.error));
      statesGet.clearError();
    }
  }, [statesGet.error]);

  const handleClear = () => {
    setState('');

    if (!onClear) return;

    onClear();
  };

  return (
    <Select
      loading={statesGet.loading || stateById.loading}
      options={options}
      value={statesGet.loading || stateById.loading ? null : selectValue}
      onSearch={(name) => setState(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      notFoundContent={<NotFoundContent message="State not found." />}
      onPopupScroll={onScroll}
      listHeight={256}
      autoClearSearchValue
      {...props}
    />
  );
};

SelectState.defaultProps = {
  country: null,
};

export default SelectState;
