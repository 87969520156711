import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import { useConfirmDoctor } from '../../../../../../hooks/doctors';
import { Action } from '../../../../../../store';
import { Error } from '../../../../../../store/ducks/common';
import { JsonResult } from '../../../../../../types';
import { UserRoles } from '../../../../../../enums/user';
import {
  moduleName,
  CreateDoctor,
  updateDoctor as actionUpdateDoctor,
  UpdateDoctor,
} from '../../../../../../store/ducks/doctor';
import { RootState } from '../../../../../../store/reducers';
import { prepareDoctorInitialData } from '../../../../../../utils/prepareData';
import DoctorCreate from '../Create';
import { getMessageInError } from '../../../../../../hooks/fetch';

interface CompleteDoctoregistration {
  doctorLoading: boolean;
  error: Error | null;
  updateDoctor: (payload: UpdateDoctor) => Action;
}

const CompleteDoctoregistration: React.FC<CompleteDoctoregistration> = (props) => {
  const { doctorLoading, error, updateDoctor } = props;
  const navigate = useNavigate();

  const { token = '' } = useParams<{ token: string; }>();

  const confirmDoctor = useConfirmDoctor();

  const handleDrawerClose = () => {
    if (error) return;

    navigate({ pathname: '/sign-in' }, { replace: true });
  };

  const fetchConfirmDoctor = () => {
    confirmDoctor.fetch({ secretKey: token });
  };

  useEffect(() => {
    if (token) {
      fetchConfirmDoctor();
    }
  }, [token]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleSubmit = async (body: CreateDoctor) => {
    if (!initialData?.id || !token) {
      message.error('Something went wrong!');

      return;
    }

    await updateDoctor({ secretKey: token, id: initialData.id, ...body });
  };

  const [initialData, setInitialData] = useState<JsonResult | undefined>();

  useEffect(() => {
    if (confirmDoctor.data) {
      setInitialData(prepareDoctorInitialData(confirmDoctor.data));
    }
  }, [confirmDoctor.data]);

  useEffect(() => {
    if (confirmDoctor.error) {
      message.error(getMessageInError(confirmDoctor.error));
      confirmDoctor.clearError();
    }
  }, [confirmDoctor.error]);

  return (
    <DoctorCreate
      initialData={initialData}
      practiceSelectOption={{ label: confirmDoctor.data?.practice?.name || '-',
        value: confirmDoctor.data?.practice?.id || '' }}
      handleSave={handleSubmit}
      isManager={confirmDoctor.data?.user?.role === UserRoles.practiceManager}
      // requiredFieldsType="completeRegistration"
      title="Complete Registration Form"
      submitBtnText="Complete Registration"
      submitBtnIcon={<CheckOutlined />}
      isOpen
      close={() => handleDrawerClose()}
      loading={confirmDoctor?.loading || doctorLoading}
      hasPassword={!confirmDoctor?.data?.isPasswordSet}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  doctorLoading: state[moduleName].doctorLoading,
  error: state[moduleName].doctorError,
});

const mapDispatchToProps = {
  updateDoctor: actionUpdateDoctor,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteDoctoregistration);
