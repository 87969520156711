import React from 'react';

import { Card } from 'antd';
import clsx from 'clsx';
import Loading from '../Loading';
import styles from './index.module.less';

interface ContentCard {
  children: React.ReactNode;
  loading?: boolean;
  cardLoading?: boolean;
  paddingSize?: 'small' | 'default';
  bordered?: boolean;
  ghost?: boolean;
  toothMapView?: boolean;
}

const ContentCard: React.FC<ContentCard> = ({
  children,
  loading = false,
  cardLoading = false,
  paddingSize,
  bordered,
  ghost,
  toothMapView,
}) => (
  <Card
    className={clsx(
      styles.card,
      paddingSize === 'small' && styles.smallPadding,
      ghost && styles.ghost,
      toothMapView && styles.toothMapView,
    )}
    loading={cardLoading}
    bordered={bordered}
  >
    {children}
    <Loading absolute visible={loading} />
  </Card>
);

ContentCard.defaultProps = {
  loading: false,
  cardLoading: false,
  paddingSize: 'default',
  bordered: true,
  ghost: false,
  toothMapView: false,
};

export default ContentCard;
