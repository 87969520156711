import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, Typography } from 'antd';

import { MailFilled } from '@ant-design/icons/lib';

import { email } from '../../../utils/inputRules';
import { usePasswordForgot } from '../../../hooks/password';
import { getMessageInError } from '../../../hooks/fetch';

import styles from './index.module.less';

interface FormEmail {
  setSend: () => void;
}

const FormEmail = ({ setSend }: FormEmail) => {
  const [error, setError] = useState('');
  const passwordCreate = usePasswordForgot();
  const onFinish = async ({ email: emailValue }: { email: string; }): Promise<void> => {
    passwordCreate.fetch({ email: emailValue }).then(() => {
      setSend();
    });
  };

  useEffect(() => {
    if (passwordCreate.error) {
      setError(getMessageInError(passwordCreate.error));
      passwordCreate.clearError();
    }
  }, [passwordCreate.error]);

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ remember: true }}
    >
      {error ? (
        <Form.Item>
          <Alert
            type="error"
            onClose={() => setError('')}
            message={error}
            closable
            showIcon
          />
        </Form.Item>
      ) : null}
      <Form.Item label="Email" name="email" rules={email}>
        <Input size="large" prefix={<MailFilled />} placeholder="Email" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldsValue, getFieldsError }) => {
          const { email: emailValue } = getFieldsValue();
          const fieldsError = getFieldsError();

          return (
            <Button
              size="large"
              type="default"
              block
              loading={passwordCreate.loading}
              htmlType="submit"
              disabled={!emailValue || fieldsError.some((item) => item.errors.length)}
            >
              Send a link
            </Button>
          );
        }}
      </Form.Item>
      <Form.Item className="textAlignCenter">
        <Link to="/sign-in">
          Back to Login
        </Link>
      </Form.Item>
    </Form>
  );
};

const ForgotPassword = (): JSX.Element | null => {
  const [sendSuccess, setSendSuccess] = useState(false);
  const navigate = useNavigate();

  if (sendSuccess) {
    return (
      <div className={styles.success}>
        <Typography.Title level={4}>Check your email</Typography.Title>
        <Typography.Paragraph>
          Your reset password link has been successfully sent to your email address.
        </Typography.Paragraph>
        <Typography.Paragraph type="secondary" className="additionalInfo">
          If you don’t receive the email within a few moments, please check your spam folder or contact
          your manager for assistance.
        </Typography.Paragraph>
        <Button size="large" type="primary" onClick={() => navigate('/sign-in')}>
          Back to Login
        </Button>
      </div>
    );
  }

  return (
    <FormEmail setSend={() => setSendSuccess(true)} />
  );
};

export default ForgotPassword;
