import { SelectProps } from 'antd/lib/select';
import { message, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';
import { useLabAvailableReceiversGet } from '../../../../hooks/cases';

interface SelectLabRecipient extends SelectProps<string | string[], Option> {
  caseServiceId: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerReload?: any;
}

const SelectLabRecipient: React.FC<SelectLabRecipient> = ({
  caseServiceId,
  triggerReload,
  onClear,
  ...props
}): JSX.Element => {
  const labAvailableRecipientsGet = useLabAvailableReceiversGet();

  const [options, setOptions] = useState<Option[]>([]);

  const fetch = () => {
    labAvailableRecipientsGet.fetch(undefined, `${caseServiceId}/available-recipients`);
  };

  useEffect(() => {
    if (caseServiceId) {
      fetch();
    }

    return undefined;
  }, [caseServiceId, triggerReload]);

  useEffect(() => {
    if (labAvailableRecipientsGet.data?.length) {
      setOptions(labAvailableRecipientsGet.data.map(({ user, id }): Option => (
        { label: `${user?.firstName} ${user?.lastName}`, value: id })));
    }
  }, [labAvailableRecipientsGet.data]);

  useEffect(() => {
    if (labAvailableRecipientsGet.error) {
      message.error(getMessageInError(labAvailableRecipientsGet.error));
      labAvailableRecipientsGet.clearError();
    }
  }, [labAvailableRecipientsGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    if (!onClear) return;

    onClear();
  };

  return (
    <Select
      loading={labAvailableRecipientsGet.loading}
      options={options}
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Lab staff not found." />}
      {...props}
      value={labAvailableRecipientsGet.loading ? 'Loading...' : props.value}
    />
  );
};

SelectLabRecipient.defaultProps = {
  triggerReload: '',
};

export default SelectLabRecipient;
