import { Moment } from 'moment/moment';
import moment from 'moment';
import { Event } from '../hooks/events';

export interface DisabledDaysCount {
  surgicalDateSize?: number;
  surgicalExpeditedSize?: number;
  restorativeDateSize?: number;
  restorativeExpeditedSize?: number;
}

export const getDisabledDaysCount = ({
  surgicalDateSize = 0, surgicalExpeditedSize = 0, restorativeDateSize = 0, restorativeExpeditedSize = 0,
}: DisabledDaysCount): number => {
  // Check if all sizes are 0
  if (surgicalDateSize === 0 && surgicalExpeditedSize === 0
    && restorativeDateSize === 0 && restorativeExpeditedSize === 0) {
    return 0;
  }

  // Check if both expedited sizes exist
  if (surgicalExpeditedSize > 0 && restorativeExpeditedSize > 0) {
    // Choose the max expedited size
    return Math.max(surgicalExpeditedSize, restorativeExpeditedSize);
  }

  // Check if neither expedited size exists
  if (surgicalExpeditedSize === 0 && restorativeExpeditedSize === 0) {
    // Choose the max date size
    return Math.max(surgicalDateSize, restorativeDateSize);
  }

  // Check if surgical date size is 0 and at least one expedited size exists
  if (surgicalDateSize === 0 && surgicalExpeditedSize > 0) {
    // Choose the max size between surgical expedited size and restorative date/expedited size
    return Math.max(surgicalExpeditedSize, restorativeDateSize, restorativeExpeditedSize);
  }

  // Check if restorative date size is 0 and at least one expedited size exists
  if (restorativeDateSize === 0 && restorativeExpeditedSize > 0) {
    // Choose the max size between restorative expedited size and surgical date/expedited size
    return Math.max(surgicalDateSize, surgicalExpeditedSize, restorativeExpeditedSize);
  }

  // Check if surgical expedited size is greater than restorative date size and restorative expedited size
  if (surgicalExpeditedSize > restorativeDateSize && surgicalExpeditedSize > restorativeExpeditedSize) {
    // Choose surgical expedited size
    return surgicalExpeditedSize;
  }

  // Check if restorative expedited size is greater than surgical date size and surgical expedited size
  if (restorativeExpeditedSize > surgicalDateSize && restorativeExpeditedSize > surgicalExpeditedSize) {
    // Choose restorative expedited size
    return restorativeExpeditedSize;
  }

  // If restorative date size compares with the surgical expedited size
  if (surgicalDateSize > 0 && surgicalExpeditedSize > 0 && restorativeDateSize > 0 && !restorativeExpeditedSize) {
    return Math.max(surgicalExpeditedSize, restorativeDateSize);
  }

  // If surgical date size compares with the restorative expedited size
  if (restorativeDateSize > 0 && restorativeExpeditedSize > 0 && surgicalDateSize > 0 && !surgicalExpeditedSize) {
    return Math.max(restorativeExpeditedSize, surgicalDateSize);
  }

  // Otherwise, choose the max date size
  return Math.max(surgicalDateSize, restorativeDateSize);
};

export const isThisDayEvent = (date: Moment, eventsList?: Event[]) => {
  if (eventsList?.length) {
    const foundDay = eventsList.find((event) => {
      /** If event repeatable then check dates with same year and then validate */
      if (event.repeat) {
        return moment(moment(event.eventDate).format(`${moment().format('YYYY')}-MM-DD`))
          .isSame(moment(date.format(`${moment().format('YYYY')}-MM-DD`)));
      }

      /** If not repeatable, then just check is day same */
      return moment(event.eventDate).isSame(date, 'day');
    });

    return !!foundDay;
  }

  return false;
};

export const countDaysOff = (untilDate: Moment, eventsList?: Event[], fromDate?: Moment): number => {
  let daysOffCount = 0;
  const today = fromDate || moment();

  if (eventsList) {
    eventsList.forEach((event) => {
      const eventDate = moment(event.eventDate);
      /** Check if the event is a repeated event and if its last occurrence is before the `untilDate` */
      const isRepeated = event.repeat && eventDate.isBefore(untilDate);

      /** If the event is a repeated event, count all its occurrences until the `untilDate` */
      if (isRepeated) {
        /** Calculate the date of the first occurrence of the event in the current year */
        const firstOccurrence = eventDate.clone().year(today.year());
        /** Initialize a variable to keep track of the date of the next occurrence of the event */
        let nextOccurrence = firstOccurrence.clone();

        /** Iterate over subsequent years until the `untilDate` is reached */
        while (nextOccurrence.isBefore(untilDate)) {
          /** If the date of the next occurrence falls between the `today` and `untilDate` range, increment
           * the count of day offs */
          if (nextOccurrence.isSameOrAfter(today)) {
            daysOffCount++;
          }
          /** Calculate the date of the next occurrence of the event in the next year */
          nextOccurrence = nextOccurrence.add(1, 'year');
        }
      } else if (eventDate.isSameOrAfter(today) && eventDate.isSameOrBefore(untilDate)) {
        /** If the event is a one-time event and its date falls between the `today` and `untilDate` range, increment
         * the count of day offs */
        daysOffCount++;
      }
    });
  }

  return daysOffCount;
};
