import React, { useRef } from 'react';
import Loading from '../Loading';

import styles from './index.module.less';

interface PriceBlock {
  price: number;
  leadTimePrice: number;
  loading?: boolean;
  wrap?: boolean;
  style?: React.CSSProperties;
  textBetweenPrices?: string;
  cutIfNeeded?: boolean;
}

const PriceBlock: React.FC<PriceBlock> = ({
  price, leadTimePrice, loading, wrap, style, textBetweenPrices, cutIfNeeded,
}) => {
  const windowWidth = useRef(window.innerWidth);

  return (
    <>
      {loading && (
        <Loading visible size="small" style={{ width: '22px' }} />
      )}
      <div
        style={style}
        className={wrap ? styles.wrap : undefined}
        title={leadTimePrice ? `$${price + leadTimePrice} incl. expedited $${leadTimePrice}` : `$${price}`}
      >
        $
        {price + leadTimePrice}
        {' '}
        {!!leadTimePrice && (
          <span
            style={{ color: 'var(--color-date-expedited-price)' }}
            className={wrap ? styles.expeditedWrap : undefined}
          >
            {`${textBetweenPrices
            || (windowWidth.current <= 1580 && cutIfNeeded ? 'incl.' : 'incl. expedited')} $${leadTimePrice}`}
          </span>
        )}
      </div>
    </>
  );
};

PriceBlock.defaultProps = {
  loading: false,
  wrap: false,
  style: undefined,
  textBetweenPrices: undefined,
  cutIfNeeded: false,
};

export default PriceBlock;
