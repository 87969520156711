import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'antd/es/avatar/avatar';
import moment from 'moment';
import message from 'antd/lib/message';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Divider, Form, Input, List, Progress, Skeleton } from 'antd';
import { DeleteOutlined, PaperClipOutlined, ReloadOutlined, SendOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd/es/grid';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import Tooltip from 'antd/es/tooltip';
import { Comment, useCommentCreate, useListCommentRow } from '../../../hooks/cases';
import { getMessageInError } from '../../../hooks/fetch';
import AttachmentLoader from '../AttachmentLoader';
import { required } from '../../../utils/inputRules';
import { CaseStatus } from '../../../enums/case';
import { RootState } from '../../../store/reducers';
import { moduleName as authModuleName, User } from '../../../store/ducks/auth';
import { getInitials } from '../../../utils';

import styles from './index.module.less';

interface IComments {
  caseId: string | number;
  caseStatus?: CaseStatus;
  user: User | null;
}

const Comments: React.FC<IComments> = ({ caseId, caseStatus, user }) => {
  const [form] = useForm();
  const commentsGet = useListCommentRow();

  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);
  // eslint-disable-next-line max-len
  const commentCreate = useCommentCreate(caseId || '', (progressEvent) => { setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total)); });

  const [data, setData] = useState<Comment[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);
  const uploadRef = useRef<HTMLInputElement>(null);

  /** Load new data! */
  const loadMoreData = (resetPage?: number) => {
    if (commentsGet.loading) return;

    commentsGet.fetch({ page: resetPage || currentPage, take: 20 }, `${caseId}/comments`).then((response) => {
      if (response) {
        const { comments, total } = response;

        /** If we reset page, then we replace whole data with data from current request */
        if (resetPage) {
          setData(comments);
        } else {
          setData((prevState) => [...prevState, ...comments]);
        }

        setCurrentPage((prevPage) => (prevPage + 1));
      }
    }).finally(() => setPercentCompleted(null));
  };

  useEffect(() => {
    if (caseId && !commentsGet.loading && !commentsGet.data) {
      loadMoreData();
    }
  }, [caseId]);

  const handleFileUploadClick = () => uploadRef.current?.click();

  const handleFileUploadReset = () => {
    setFile(null);
    setPercentCompleted(null);
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
  };

  const handleResetForm = (clearMessageField = true) => {
    if (clearMessageField) {
      form.resetFields();
      handleFileUploadReset();
    }
    setCurrentPage(1);
    loadMoreData(1);
  };

  const handleSubmit = ({ message }: { message: string; }) => {
    if (message) {
      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }
      formData.append('message', message || '');

      commentCreate.fetch(formData)?.then(() => handleResetForm()).finally(() => setPercentCompleted(null));
    }
  };

  const getHeight = (): number => {
    if (data.length >= 6) return 600;
    if (data.length >= 5) return 540;
    if (data.length >= 4) return 480;
    if (data.length >= 3) return 380;
    if (data.length >= 2) return 280;

    return 200;
  };

  useEffect(() => {
    if (commentsGet.error) {
      message.error(getMessageInError(commentsGet.error));
      commentsGet.clearError();
    }
  }, [commentsGet.error]);

  useEffect(() => {
    if (commentCreate.error) {
      message.error(getMessageInError(commentCreate.error));
      commentCreate.clearError();
    }
  }, [commentCreate.error]);

  // Reload comments data every 90000 ms //
  // useEffect(() => {
  //   if (commentsGet.loading) return;
  //   setInterval(() => {
  //     handleResetForm(resetForm);
  //     // resetForm is new prop: boolean
  //   }, 90000);
  // }, []);

  return (
    <div style={{ padding: '0 16px', position: 'relative' }}>
      <div style={{ position: 'absolute', right: '15px', top: '-48px' }}>
        <Tooltip title="Reload comments">
          <Button
            type="link"
            icon={<ReloadOutlined style={{ fontSize: '18px' }} />}
            onClick={() => handleResetForm(false)}
          />
        </Tooltip>
      </div>

      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ message: '' }}
          autoComplete="off"
          onFinish={handleSubmit}
          disabled={commentCreate.loading || commentsGet.loading}
        >
          <Row>
            <Col span={24}>
              <List.Item.Meta
                avatar={(
                  <Avatar
                    size={46}
                    style={{
                      color: 'var(--color-grey-additional)',
                      backgroundColor: 'var(--color-main-orange)',
                    }}
                  >
                    {getInitials(user?.firstName, user?.lastName)}
                  </Avatar>
                )}
                title={(
                  <div className={styles.titleWrapper}>
                    <Form.Item
                      name="message"
                      rules={required}
                      style={{ width: '100%' }}
                      extra={file && (
                        <div>
                          {percentCompleted && (
                            <Progress percent={percentCompleted} strokeColor="var(--color-main-orange)" />
                          )}
                          {file.name}
                          <Button
                            type="link"
                            className={styles.delete}
                            onClick={handleFileUploadReset}
                            icon={<DeleteOutlined className={styles.icon} />}
                          />
                        </div>
                      )}
                    >
                      <Input
                        placeholder="Add a comment..."
                        addonAfter={(
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                            <Button
                              size="middle"
                              style={{ height: '28px', width: '28px' }}
                              type="link"
                              icon={<PaperClipOutlined style={{ fontSize: '18px' }} />}
                              onClick={handleFileUploadClick}
                            />
                            <Button
                              size="middle"
                              style={{ height: '28px', width: '28px' }}
                              type="link"
                              icon={<SendOutlined />}
                              onClick={() => form.validateFields().then((values) => handleSubmit(values))}
                              loading={commentCreate.loading || commentsGet.loading}
                            />
                          </div>
                        )}
                      />
                    </Form.Item>
                  </div>
                )}
              />
            </Col>
          </Row>
          <input
            ref={uploadRef}
            name="file"
            type="file"
            className={styles.inputFile}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFile(e.target.files?.[0] || null)}
          />
        </Form>
      </Col>

      <div
        id="scrollableDiv"
        style={{ maxHeight: 600 /** overflow: 'auto', */ }}
      >
        <InfiniteScroll
          height={getHeight()}
          dataLength={data.length}
          next={loadMoreData}
          hasMore={data.length < (commentsGet.data?.total || 0)}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={!commentsGet.loading && commentsGet.data?.total !== 0 && (<Divider />)}
        >
          <List
            dataSource={data}
            renderItem={(comment) => (
              <List.Item
                key={comment.id}
              >
                <List.Item.Meta
                  avatar={(
                    <Avatar
                      size={46}
                      style={comment.user.id === user?.id
                        ? { backgroundColor: 'var(--color-main-orange)', color: 'var(--color-grey-additional)' }
                        : { color: 'var(--color-grey-additional)' }}
                    >
                      {getInitials(comment.user?.firstName, comment.user?.lastName)}
                    </Avatar>
                  )}
                  title={(
                    <div className={styles.titleWrapper}>
                      <span className={styles.name}>
                        {`${comment.user?.firstName || '-'} ${comment.user?.lastName || '-'}`}
                      </span>
                      <span className={styles.date}>
                        {moment(comment?.createdAt).format('DD MMMM, YYYY [at] hh:mm A')}
                      </span>
                    </div>
                  )}
                  description={(
                    <div className={styles.descriptionWrapper}>
                      <div className={styles.message}>{comment.message}</div>
                      {comment.file && (
                        <div className={styles.file}>
                          <AttachmentLoader
                            disabled
                            hideControls
                            onUpdate={undefined}
                            defaultFiles={[{ file: comment.file, id: comment.id, url: '-', removed: false }]}
                            handleFileDelete={undefined}
                            caseId={`${caseId}`}
                            commentView
                          />
                        </div>
                      )}
                    </div>
                  )}
                />
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

Comments.defaultProps = {
  caseStatus: undefined,
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});

export default connect(mapStateToProps)(Comments);
