import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Typography } from 'antd';
import Icon from '@ant-design/icons/es';
import { Rule } from 'antd/es/form';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Tooltip from 'antd/es/tooltip';
import { AdditionalOptionsTypes } from '../../../../../enums/services';
import { JsonResult } from '../../../../../types';
import { Delete } from '../../../../Common/Icon';
import styles from '../../Edit/index.module.less';

interface PriceRow {
  name: number;
  restField: {
    fieldKey?: number;
  };
  isEditing?: boolean;
  remove: (index: number | number[]) => void;
  prices?: JsonResult[];
  materialsView?: boolean;
}

const PriceRow = (props: PriceRow) => {
  const { name, restField, isEditing, remove, prices, materialsView } = props;
  const [isRangeValid, setIsRangeValid] = useState({ minRange: true, maxRange: true });

  const priceMinRange: Rule[] = [{
    message: '',
    validator: () => {
      if (isRangeValid.minRange) return Promise.resolve();

      return Promise.reject(new Error('Range is invalid'));
    },
  }];

  const priceMaxRange: Rule[] = [{
    message: '',
    validator: () => {
      if (isRangeValid.maxRange) return Promise.resolve();

      return Promise.reject(new Error('Range is invalid'));
    },
  }];

  useEffect(() => {
    const pricesRanges = [...prices || []];
    const currentRange = pricesRanges[name];

    pricesRanges.splice(name, 1);

    setIsRangeValid({
      minRange: !pricesRanges.some((price) => {
        const [minRange, maxRange] = [price.minRange, price.maxRange].sort((a, b) => a - b);

        return currentRange.minRange >= minRange && currentRange.minRange <= maxRange;
      }),
      maxRange: !pricesRanges.some((price) => {
        const [minRange, maxRange] = [price.minRange, price.maxRange].sort((a, b) => a - b);

        return currentRange.maxRange >= minRange && currentRange.maxRange <= maxRange;
      }),
    });
  }, [prices]);

  return (
    <>
      <Form.Item name={[name, 'id']} className="hidden-input">
        <Input type="hidden" />
      </Form.Item>
      <Space className={clsx(styles.row, 'service-row')} align="center">
        <Form.Item
          className="no-space-form-item"
          name={[name, 'label']}
          {...restField}
        >
          <Input placeholder="Label" disabled={!isEditing} />
        </Form.Item>
        <Form.Item
          className="no-space-form-item"
          name={[name, 'minRange']}
          validateStatus="success"
          rules={priceMinRange}
          {...restField}
        >
          <InputNumber
            min={2}
            disabled={!isEditing}
            controls={false}
            className={styles.toothInput}
            status={isRangeValid.minRange ? '' : 'error'}
          />
        </Form.Item>
        <Typography> - </Typography>
        <Form.Item
          className="no-space-form-item"
          name={[name, 'maxRange']}
          rules={priceMaxRange}
          validateStatus="success"
          {...restField}
        >
          <InputNumber
            min={2}
            disabled={!isEditing}
            controls={false}
            className={styles.toothInput}
            status={isRangeValid.maxRange ? '' : 'error'}
          />
        </Form.Item>
        <Typography>teeth</Typography>
        <Form.Item
          className={clsx(styles.price, 'no-space-form-item')}
          name={[name, 'price']}
          rules={[{
            required: true,
            // message: '',
            validator: (_, value) => {
              if (Number(value) >= 0) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Price invalid'));
            } }]}
          {...restField}
        >
          <InputNumber prefix="$" min={0} disabled={!isEditing} placeholder="Price" controls={false} />
        </Form.Item>
        <Form.Item
          className={clsx(styles.type, 'no-space-form-item')}
          name={[name, 'type']}
          {...restField}
        >
          <Select
            options={Object.values(AdditionalOptionsTypes).map((value) => ({ label: value, value }))}
            disabled={!isEditing}
          />
        </Form.Item>

        {/* New fields. They should be only in Materials Row. hide in Additional Options row. */}
        {materialsView && (
          <Row gutter={[8, 0]}>
            <Col span={8}>
              <Form.Item
                className={clsx(styles.percent, 'no-space-form-item')}
                name={[name, 'dateSize']}
                rules={[{ required: true, message: 'Required' }]}
                {...restField}
              >
                <InputNumber
                  min={1}
                  disabled={!isEditing}
                  placeholder="Days"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Tooltip title="Expedited days">
                <Form.Item
                  className={clsx(styles.percent, 'no-space-form-item')}
                  name={[name, 'expeditedDate']}
                  tooltip="Expedited days"
                  rules={[{ required: false, message: '' }]}
                  {...restField}
                >
                  <InputNumber
                    min={1}
                    max={prices?.[name]?.dateSize === 1 ? 0 : (prices?.[name]?.dateSize || 1) - 1}
                    disabled={!isEditing || !prices?.[name]?.dateSize}
                    placeholder="Expedited days"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title="The percent of the amount added to the case service price for expediency.">
                <Form.Item
                  className={clsx(styles.percent, 'no-space-form-item')}
                  name={[name, 'increasePercent']}
                  rules={[{ required: false, message: 'Max 999%' }]}
                  {...restField}
                >
                  <InputNumber
                    min={0}
                    max={999}
                    disabled={!isEditing || !prices?.[name]?.expeditedDate}
                    placeholder="Percent"
                    prefix="%"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
        )}
        {/* end new fields */}

        {isEditing && (
          <Form.Item className="no-space-form-item">
            <Button type="text" onClick={() => remove(name)}>
              <Icon component={Delete} />
            </Button>
          </Form.Item>
        )}
      </Space>
    </>
  );
};

PriceRow.defaultProps = {
  isEditing: false,
  prices: undefined,
  materialsView: false,
};

export default PriceRow;
