import Icon, { CheckOutlined } from '@ant-design/icons';
import { Alert, Button, Col, message, Row, Space, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useContextCaseFlow } from '../../../context/caseFlow';
import {
  CaseService, useDeleteCaseServiceFile, useLabNotificationsReceiversGet,
  useUpdateCaseServiceStatus,
  useUploadCaseServiceFile,
} from '../../../hooks/cases';
import { User, moduleName as authModuleName } from '../../../store/ducks/auth';
import { RootState } from '../../../store/reducers';
import { isRoleEnough } from '../../../utils/auth';
import AttachmentLoader, { IPercentCompleted, UploadingFile } from '../AttachmentLoader';
import { Close, Send } from '../Icon';
import DeclineForm from './DeclineForm';
import { getMessageInError } from '../../../hooks/fetch';
import { onActionClick } from '../../../utils';
import RejectForm from './RejectForm';

import styles from './index.module.less';

interface ServiceFilesContent {
  caseId: string | number;
  service?: CaseService;
  user: User | null;
  isProposalView?: boolean;
}

const ServiceFilesContent: React.FC<ServiceFilesContent> = (props) => {
  const [rejectForm] = useForm();
  const [declineForm] = useForm();
  const { caseId, user, service, isProposalView } = props;
  const [isFilesLoaded, setIsFilesLoaded] = useState<boolean>(false);
  const [isRejectEditing, setIsRejectEditing] = useState<boolean>(false);
  const [isDeclineEditing, setIsDeclineEditing] = useState<boolean>(false);
  const [resultUploadingFiles, setResultUploadingFiles] = useState<UploadingFile[]>([]);
  const [previewUploadingFiles, setPreviewUploadingFiles] = useState<UploadingFile[]>([]);

  const {
    caseByIdInContext,
  } = useContextCaseFlow();

  /** Notification receivers get to update validations and let receivers upload files: */
  const notificationsReceiversGet = useLabNotificationsReceiversGet();
  const [labReceiversIds, setLabReceiversIds] = useState<string[]>([]);

  useEffect(() => {
    if (caseByIdInContext?.data?.caseServices) {
      if (notificationsReceiversGet.loading) return;

      notificationsReceiversGet
        .fetch(undefined, `${service?.id}/recipients`).then((response) => {
          if (response) {
            setLabReceiversIds(response.map((receiver) => receiver?.labStaff?.id));
          }
        });
    }
  }, [caseByIdInContext?.data]);

  /** Files upload: */
  const [percentCompleted, setPercentCompleted] = useState<IPercentCompleted[]>([]);

  const updateCaseServiceStatus = useUpdateCaseServiceStatus();
  const uploadCaseServiceFile = useUploadCaseServiceFile((progressEvent) => {
    setPercentCompleted((prevState) => (
      [
        ...prevState.filter((item) => item.fileSizeBytes !== progressEvent.total),
        {
          fileSizeBytes: progressEvent.total,
          percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
        },
      ]
    ));
  });
  const deleteFile = useDeleteCaseServiceFile();

  const fetchCaseById = () => {
    caseByIdInContext?.fetch(undefined, caseId);
  };

  useEffect(() => {
    if (deleteFile?.error) {
      message.error(deleteFile.error.message, 5);
    }
  }, [deleteFile?.error]);

  const handleFileDelete = async (fileId: string) => {
    await deleteFile.fetch(`${service?.id}/file/${fileId}`);

    if (!deleteFile?.error) {
      fetchCaseById();
    }
  };

  const isStatusEnough = (statuses: string[]) => statuses.some((status) => status === service?.status);

  const handleStartWork = () => {
    onActionClick({
      title: 'Start Work',
      content: 'Are you sure you want to Start Work on the service?',
      actionName: 'approve',
      hook: () => updateCaseServiceStatus.fetch(undefined, `${service?.id}/in-progress`).then(() => (
        fetchCaseById()
      )),
      centered: true,
    });
  };

  const uploadResultFiles = (files: UploadFile[]) => {
    if (!service?.id) return;

    const uploadingFiles = files.map((file) => ({ ...file, loading: true }));

    setIsFilesLoaded(false);
    setResultUploadingFiles(uploadingFiles);

    Promise.all([
      ...files.map((file) => {
        const formData = new FormData();

        formData.append('type', 'result');

        if (file.originFileObj) {
          formData.append('file', file.originFileObj);
        }

        return uploadCaseServiceFile.fetch(formData, `${service.id}/file`).then(() => {
          const currentFile = uploadingFiles.find((uploadingFile) => uploadingFile.uid === file.uid);
          const currentFileIndex = currentFile ? uploadingFiles.indexOf(currentFile) : -1;

          if (currentFile && currentFileIndex >= 0) {
            uploadingFiles.splice(currentFileIndex, 1, { ...currentFile, loading: false });
          }

          setResultUploadingFiles(uploadingFiles);
        });
      }),
    ]).then(() => {
      setIsFilesLoaded(true);
    }).catch(() => {
      message.error('File can\'t be uploaded', 5);
    }).finally(() => setPercentCompleted([]));
  };

  const handleComplete = () => {
    if (!service?.id) return;

    onActionClick({
      title: 'Complete',
      content: 'Are you sure you want to Complete the service?',
      actionName: 'approve',
      hook: () => updateCaseServiceStatus.fetch(undefined, `${service?.id}/ready-for-approval`)
        .then(() => {
          fetchCaseById();
        }),
      centered: true,
    });
  };

  const uploadPreviewFiles = (files: UploadFile[]) => {
    if (!service?.id) return;

    const uploadingFiles = files.map((file) => ({ ...file, loading: true }));

    setIsFilesLoaded(false);
    setPreviewUploadingFiles(uploadingFiles);

    Promise.all([
      ...files.map((file) => {
        const formData = new FormData();

        formData.append('type', 'preview');

        if (file.originFileObj) {
          formData.append('file', file.originFileObj);
        }

        return uploadCaseServiceFile.fetch(formData, `${service.id}/file`).then(() => {
          const currentFile = uploadingFiles.find((uploadingFile) => uploadingFile.uid === file.uid);
          const currentFileIndex = currentFile ? uploadingFiles.indexOf(currentFile) : -1;

          if (currentFile && currentFileIndex >= 0) {
            uploadingFiles.splice(currentFileIndex, 1, { ...currentFile, loading: false });
          }

          setPreviewUploadingFiles(uploadingFiles);
        });
      }),
    ]).then(() => {
      setIsFilesLoaded(true);
    }).catch(() => {
      message.error('File can\'t be uploaded', 5);
    });
  };

  const onFilesLoad = () => {
    fetchCaseById();

    setResultUploadingFiles([]);
    setPreviewUploadingFiles([]);
  };

  const handleSendForApproval = () => {
    if (!service?.id) return;

    onActionClick({
      title: 'Send for Approval',
      content: 'Are you sure you want to Send for Approval the service?',
      actionName: 'approve',
      hook: () => updateCaseServiceStatus.fetch(undefined, `${service?.id}/pending`)
        .then(() => {
          fetchCaseById();
        }),
      centered: true,
    });
  };

  const handleApprove = () => {
    onActionClick({
      title: 'Accept',
      content: 'Are you sure you want to Accept the service?',
      actionName: 'approve',
      hook: () => updateCaseServiceStatus.fetch(undefined, `${service?.id}/approved`).then(() => (
        fetchCaseById()
      )),
      centered: true,
    });
  };

  const handleReject = () => {
    setIsRejectEditing(true);
  };

  const handleRejectCancel = () => {
    rejectForm.resetFields();
    setIsRejectEditing(false);
  };

  const handleRejectSave = () => {
    rejectForm.validateFields().then((values) => {
      const { rejectionReason } = values;

      onActionClick({
        title: 'Reject',
        content: 'Are you sure you want to Reject the service?',
        actionName: 'reject',
        hook: () => updateCaseServiceStatus.fetch({ rejectionReason }, `${service?.id}/rejected`).then(() => {
          fetchCaseById();
          handleRejectCancel();
        }),
        centered: true,
      });
    });
  };

  /** label Accepted = status Approved */
  const [lastActionName, setLastActionName] = useState<'accepted' | 'declined' | ''>('');

  const handleApprovePropos = () => {
    onActionClick({
      title: 'Accept',
      content: 'Are you sure you want to Accept the service?',
      actionName: 'approve',
      hook: () => updateCaseServiceStatus.fetch(undefined, `${service?.id}/assigning`).then(() => (
        fetchCaseById()
      )),
      centered: true,
    });
    setLastActionName('accepted');
  };

  const handleDecline = () => {
    setIsDeclineEditing(true);
  };

  const handleDeclineCancel = () => {
    declineForm.resetFields();
    setIsDeclineEditing(false);
  };

  const handleDeclineSave = () => {
    declineForm.validateFields().then((values) => {
      const { declineReason } = values;

      onActionClick({
        title: 'Decline',
        content: 'Are you sure you want to Decline the service?',
        actionName: 'decline',
        hook: () => updateCaseServiceStatus.fetch({ declineReason }, `${service?.id}/declined`).then(() => {
          fetchCaseById();
          handleDeclineCancel();
        }),
        centered: true,
      });
      setLastActionName('declined');
    });
  };

  const getAddFileLabelByService = (service: CaseService | undefined): string => {
    if (service?.labService?.service?.serviceType === 'surgical') {
      return 'Add surgical plan (NEAF file)';
    }

    if (service?.labService?.service?.serviceType === 'restorative') {
      return 'Add restorative plan (STL file)';
    }

    return 'Add File';
  };

  useEffect(() => {
    if (updateCaseServiceStatus.data && !updateCaseServiceStatus.error && lastActionName) {
      message.success(`Request ${lastActionName}!`);
    }
  }, [updateCaseServiceStatus.data]);

  useEffect(() => {
    if (updateCaseServiceStatus.error) {
      message.error(getMessageInError(updateCaseServiceStatus.error));
      updateCaseServiceStatus.clearError();
    }
  }, [updateCaseServiceStatus.error]);

  return (
    <Row className={styles.wrapper} gutter={15}>
      {(isRoleEnough(['root', 'admin', 'lab staff', 'lab manager'])
          || (isRoleEnough(['practice manager', 'doctor'])
            && isStatusEnough(['approved', 'completed'])
            && caseByIdInContext?.data?.practice?.id === user?.doctor?.practice?.id))
        && (
          <Col span={12}>
            <Space direction="vertical" size={20} className={styles.row}>
              {!isProposalView && (
                <Typography.Title level={4} className={styles.title}>Result Files</Typography.Title>
              )}
              {service?.declineReason
                && isRoleEnough('admin')
                && isStatusEnough(['declined'])
                && (
                  <Alert
                    message={service.declineReason}
                    type="error"
                    showIcon
                  />
                )}
              {!isRoleEnough(['doctor', 'practice manager']) && (
                <Space direction="vertical" className={styles.row}>
                  <Row className={styles.row}>
                    <Col style={{ width: 124 }}>
                      <Typography.Title level={5} className={styles.title}>Assigned Lab</Typography.Title>
                    </Col>
                    <Col>
                      <Typography.Title level={5} className={styles.withoutMargin}>
                        {service?.labService?.lab?.name || '-'}
                      </Typography.Title>
                    </Col>
                  </Row>
                  <Row className={styles.row}>
                    <Col style={{ width: 124 }}>
                      <Typography.Title level={5} className={styles.title}>Assigned Staff</Typography.Title>
                    </Col>
                    <Col>
                      <Typography.Title level={5} className={styles.withoutMargin}>
                        {service?.labStaff?.user?.firstName || service?.labStaff?.user?.lastName
                          ? `${service?.labStaff?.user?.firstName} ${service?.labStaff?.user?.lastName}`.trim()
                          : '-'}
                      </Typography.Title>
                    </Col>
                  </Row>
                </Space>
              )}
              {!isProposalView && (
                <div>
                  {isStatusEnough(
                    ['in progress', 'ready for approval', 'pending', 'approved', 'rejected', 'completed'],
                  )
                    && caseByIdInContext?.data?.status !== 'cancel'
                    && (
                      <AttachmentLoader
                        isExtraShown={() => isStatusEnough(['in progress'])}
                        hideControls={!isStatusEnough(['in progress'])
                          || (user?.labStaff?.id !== service?.labStaff?.id
                            && !labReceiversIds.includes(user?.labStaff?.id || ''))}
                        defaultFiles={service?.resultFiles}
                        handleFileDelete={handleFileDelete}
                        onUpdate={uploadResultFiles}
                        uploadingFiles={resultUploadingFiles}
                        tempFiles={service?.resultFiles ? [] : undefined}
                        onFilesLoad={onFilesLoad}
                        isFilesLoaded={isFilesLoaded}
                        extra={(
                          <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={handleComplete}
                            disabled={!service?.resultFiles?.length}
                          >
                            Complete
                          </Button>
                        )}
                        addFileBtnLabel={getAddFileLabelByService(service)}
                        caseServiceId={service?.id}
                        percentCompleted={percentCompleted}
                        disabled={uploadCaseServiceFile.loading}
                      />
                    )}
                  {isStatusEnough(['assigned', 'rejected'])
                    && caseByIdInContext?.data?.status !== 'cancel'
                    && service?.labStaff?.id
                    && (user?.labStaff?.id === service?.labStaff?.id
                      || labReceiversIds.includes(user?.labStaff?.id || ''))
                    && (
                      <Button type="primary" icon={<CheckOutlined />} onClick={handleStartWork}>Start Work</Button>
                    )}
                </div>
              )}
              {isProposalView && service?.id && caseByIdInContext?.data?.status !== 'cancel' && (
                <div>
                  {isDeclineEditing ? (
                    <DeclineForm
                      form={declineForm}
                      onSave={handleDeclineSave}
                      onCancel={handleDeclineCancel}
                    />
                  ) : (
                    <Row gutter={20}>
                      <Col>
                        <Button
                          type="primary"
                          icon={<CheckOutlined />}
                          onClick={handleApprovePropos}
                          loading={updateCaseServiceStatus.loading && lastActionName === 'accepted'}
                          disabled={updateCaseServiceStatus.loading && lastActionName !== 'accepted'}
                        >
                          Accept
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="default"
                          icon={<Icon component={Close} />}
                          onClick={handleDecline}
                          loading={updateCaseServiceStatus.loading && lastActionName === 'declined'}
                          disabled={updateCaseServiceStatus.loading && lastActionName !== 'declined'}
                        >
                          Decline
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </Space>
          </Col>
        )}
      {!isProposalView && (
        <Col span={12}>
          <Space direction="vertical" size={20} className={styles.row}>
            {!isProposalView && (
              <Typography.Title level={4} className={styles.title}>Files for review</Typography.Title>
            )}
            {service?.rejectionReason
              && !isRoleEnough(['doctor', 'practice manager'])
              && isStatusEnough(['rejected'])
              && (
                <Alert
                  message={service.rejectionReason}
                  type="error"
                  showIcon
                />
              )}
            {service && caseByIdInContext?.data?.status !== 'cancel' && (
              <AttachmentLoader
                isExtraShown={() => !!service?.previewFiles?.length}
                hideControls={!isStatusEnough(['ready for approval'])
                  || (user?.labStaff?.id !== service?.labStaff?.id
                    && !labReceiversIds.includes(user?.labStaff?.id || ''))}
                defaultFiles={service?.previewFiles}
                handleFileDelete={handleFileDelete}
                onUpdate={uploadPreviewFiles}
                uploadingFiles={previewUploadingFiles}
                tempFiles={service?.previewFiles ? [] : undefined}
                onFilesLoad={onFilesLoad}
                isFilesLoaded={isFilesLoaded}
                extra={(
                  <Button
                    type="primary"
                    icon={<Icon component={Send} />}
                    onClick={handleSendForApproval}
                  >
                    Send for Approval
                  </Button>
                )}
                uploadHintText=""
                addFileBtnLabel="Add review files (pictures, videos, etc)"
                caseServiceId={service?.id}
                percentCompleted={percentCompleted}
                disabled={uploadCaseServiceFile.loading}
              />
            )}
            {(isRoleEnough(['practice manager', 'doctor'])
              && caseByIdInContext?.data?.status !== 'cancel'
              && isStatusEnough(['pending'])
              && caseByIdInContext?.data?.doctor?.id === user?.doctor?.id)
              && (
                <div>
                  {isRejectEditing ? (
                    <RejectForm
                      form={rejectForm}
                      onCancel={handleRejectCancel}
                      onSave={handleRejectSave}
                    />
                  ) : (
                    <Space>
                      <Button
                        type="primary"
                        onClick={handleApprove}
                      >
                        Accept
                      </Button>
                      <Button
                        type="default"
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </Space>
                  )}
                </div>
              )}
          </Space>
        </Col>
      )}
    </Row>
  );
};

ServiceFilesContent.defaultProps = {
  service: undefined,
  isProposalView: false,
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});

export default connect(mapStateToProps)(ServiceFilesContent);
