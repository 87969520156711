import React from 'react';
import clsx from 'clsx';

import styles from '../index.module.less';

interface ToothIconWrapper {
  showBridge: boolean;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  children: React.ReactNode;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const ToothIconWrapper: React.FC<ToothIconWrapper> = ({
  showBridge,
  onClick,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div style={{ display: 'flex' }}>
    {showBridge && (
    <div
      className={clsx(styles.bridgeDiv, styles.divBefore, className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
    )}
    {children}
    {showBridge && (
    <div
      className={clsx(styles.bridgeDiv, styles.divAfter, className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
    )}
  </div>
);

ToothIconWrapper.defaultProps = {
  className: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
};

export default ToothIconWrapper;
