import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Labs/Profile';
import { displayName } from '../../../config';

const LabProfile: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Lab info`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default LabProfile;
