import { FormInstance } from 'antd/es/form';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { JsonResult } from '../../../../../types';
import AttachmentLoader, { UploadingFile, IPercentCompleted } from '../../../../Common/AttachmentLoader';

import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import Loading from '../../../../Common/Loading';
import { useContextCaseFlow } from '../../../../../context/caseFlow';
import RestorativePlanStepForm from '../TeethTreatmentPlanForm/RestorativePlanStepForm';
import ServiceCard, { DescriptionItem } from '../../../../Common/ServiceCard';
import { formatAddressToString } from '../../../../../utils';
import { Tooth } from '../../../../../hooks/cases';
import { PriceLevelService } from '../../../../../hooks/priceLevels';
import NotFoundContent from '../../../../Common/NotFoundContent';
import { isRoleEnough } from '../../../../../utils/auth';
import { CaseTotalPriceProp } from '../SelectTreatmentPlanForm';
import PriceBlock from '../../../../Common/PriceBlock';

interface SummaryInfoForm {
  type?: 'create' | 'update' | 'info';
  initialData?: JsonResult;
  editMode?: boolean;
  isAdmin?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
  caseTotalPrice?: CaseTotalPriceProp;
  isInfoView?: boolean;
  handleFilesUpdate?: (files: UploadFile[]) => void;
  handleFileDelete?: (fileId: string) => void;
  uploadFilesCall?: () => void;
  filesUploadProps?: {
    clearFilesStateTrigger?: boolean;
    resetFilesTriggerState?: () => void;
    buttonLoading?: boolean;
    buttonDisabled?: boolean;
  };
  uploadingFiles?: UploadingFile[];
  percentCompleted?: IPercentCompleted[];
}

const SummaryInfoForm: React.FC<SummaryInfoForm> = (props) => {
  const {
    type,
    disabled,
    initialData,
    editMode,
    onSubmit,
    children,
    form,
    handleChange,
    caseTotalPrice,
    isInfoView,
    handleFilesUpdate,
    handleFileDelete,
    uploadFilesCall,
    filesUploadProps,

    uploadingFiles,
    percentCompleted,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {
    caseByIdInContext, selectTreatmentPlan, teethValues, printingInfo, materialImplantId, materialCrownId,
    foundSurgicalService, foundRestorativeService, foundPrintingService,
    practicePriceLevelServiceInContext, implantInfo,
  } = useContextCaseFlow();

  const servicesLength = selectTreatmentPlan.services?.length || 0;

  const [priceLevelServicesSelected, setPriceLevelServicesModified] = useState<PriceLevelService[]>([]);

  useEffect(() => {
    if (selectTreatmentPlan.services && (caseByIdInContext?.data || practicePriceLevelServiceInContext?.data)) {
      setPriceLevelServicesModified((caseByIdInContext?.data?.practice?.priceLevelServices
        || practicePriceLevelServiceInContext?.data?.priceLevel?.priceLevelServices)
        ?.filter((service) => (selectTreatmentPlan.services?.includes(service?.id || ''))) || []);
    }
  }, [selectTreatmentPlan, caseByIdInContext?.data, practicePriceLevelServiceInContext?.data]);

  const isDisabledField = disabled || !!(editMode && !isEditing) || caseByIdInContext?.data?.status === 'completed'
    || caseByIdInContext?.data?.status === 'cancel';

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
    setIsEditing(false);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const getCountBridges = (): string => {
    const countArr: Tooth[][] = [];
    let arrIndex = 0;
    let lastIndex: number | null = null;

    teethValues.sort((a, b) => a.index > b.index ? 1 : -1).forEach((tooth) => {
      if (tooth.isBridge) {
        if ((!lastIndex || tooth.index - lastIndex === 1)
          && (!lastIndex || (lastIndex <= 16 && tooth.index <= 16) || (lastIndex >= 17 && tooth.index >= 17))
        ) {
          lastIndex = tooth.index;
          countArr[arrIndex] = [...(countArr[arrIndex] || []), tooth];
        } else {
          arrIndex += 1;
          lastIndex = tooth.index;
          countArr[arrIndex] = [...(countArr[arrIndex] || []), tooth];
        }
      }
    });

    return `${countArr.length}`;
  };

  const getDescriptionServicesItems = (serviceId: string): DescriptionItem[] => {
    if (serviceId === foundSurgicalService?.id) {
      return [{
        label: 'Implant',
        content: `${teethValues.filter((tooth) => tooth.materials?.includes(materialImplantId))?.length}`,
      }, {
        label: 'Implant Manufacturer',
        content: implantInfo.manufacturer || '-',
      }, {
        label: 'Implant Family',
        content: implantInfo.family || '-',
      }];
    }

    if (serviceId === foundRestorativeService?.id) {
      return [{
        label: 'Crown',
        content: `${teethValues.filter((tooth) => tooth.materials?.includes(materialCrownId))?.length}`,
      }, {
        label: 'Bridge',
        content: getCountBridges(),
      }, {
        label: 'Tooth Shade',
        content: implantInfo.shade || '-',
      }];
    }

    if (serviceId === foundPrintingService?.id) {
      return [{
        label: 'Implant Manufacturer',
        content: foundPrintingService.priceLevelMaterials
          ?.find((material) => material.id === printingInfo?.material)?.material?.name || '',
        style: { gridTemplateColumns: '1fr' },
      }, {
        label: 'Delivery Address',
        content: formatAddressToString(
          practicePriceLevelServiceInContext?.data?.addresses
            ?.find((address) => (address.id === printingInfo?.address)),
        ) || '',
        style: { gridTemplateColumns: '1fr' },
      }];
    }

    return [{ label: '-', content: '-' }];
  };

  return (
    <div>
      {!isInfoView && (
        <Row justify="space-between" className="case-card-heading">
          <div className="typography-wrapper">
            <Typography.Title level={4} style={{ marginBottom: 0 }}>Summary</Typography.Title>
          </div>
          <div className="case-total-price">
            <Typography.Text>Total price per Case</Typography.Text>
            <div className="price">
              <PriceBlock
                price={caseTotalPrice?.price || 0}
                leadTimePrice={caseTotalPrice?.leadTimePrice || 0}
                loading={caseTotalPrice?.loading}
              />
            </div>
          </div>
        </Row>
      )}

      <ContentCard paddingSize="small" bordered={false}>
        <Row justify="start">
          <Col span={24}>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>Services</Typography.Title>
            <Row gutter={[20, 20]} style={{ minHeight: '60px' }}>
              <Loading
                visible={!!caseByIdInContext?.loading}
                absolute
              />
              {priceLevelServicesSelected.length ? priceLevelServicesSelected
                ?.sort((a, b) => (a?.name || '') < (b?.name || '') ? 1 : -1)
                ?.map((service) => (
                  <Col key={service.id} style={{ flex: `1 1 ${100 / servicesLength}%`, minWidth: '324px' }}>
                    <ServiceCard
                      title={service.name}
                      descriptions={{ items: getDescriptionServicesItems(service?.id || '') }}
                      additionalOptions={service.priceLevelAdditionalOptions?.filter((option) => (
                        selectTreatmentPlan?.[service?.id || '']?.includes(option.id)
                      ))?.map((item) => ({ content: item?.additionalOption?.name || '' })) || []}
                    />
                  </Col>
                )) : (
                  <NotFoundContent message="Not found any service." fullWidth />
              )}
            </Row>
          </Col>
        </Row>

        {/** Tooth view row. */}
        <Row justify="space-between" gutter={[20, 20]} style={{ marginTop: '30px' }}>
          <Col span={24} md={24} lg={24}>
            <Typography.Title level={4}>Treatment plan</Typography.Title>
            <RestorativePlanStepForm
              teethValues={teethValues}
              form={form}
              summaryView
            />
          </Col>
        </Row>

        {/** Attachments and Note view row. */}
        <Row justify="space-between" gutter={[20, 20]} style={{ marginTop: '30px' }}>
          <Col span={24} md={24} lg={12}>
            <Typography.Title level={4}>Attachments:</Typography.Title>
            <AttachmentLoader
              disabled={!isRoleEnough('doctor') || isDisabledField}
              hideControls={!isRoleEnough('doctor') || isDisabledField}
              onUpdate={handleFilesUpdate}
              defaultFiles={type === 'create' ? [] : caseByIdInContext?.data?.files}
              handleFileDelete={handleFileDelete}
              caseId={caseByIdInContext?.data?.id}
              clearFilesStateTrigger={filesUploadProps?.clearFilesStateTrigger}
              resetFilesTriggerState={filesUploadProps?.resetFilesTriggerState}
              extra={!!filesUploadProps && !filesUploadProps.buttonDisabled && (
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={uploadFilesCall ? () => uploadFilesCall() : undefined}
                  loading={filesUploadProps?.buttonLoading}
                  disabled={filesUploadProps?.buttonDisabled}
                >
                  Save files
                </Button>
              )}
              uploadingFiles={uploadingFiles || undefined}
              percentCompleted={percentCompleted}
            />
          </Col>
          <Col span={24} md={24} lg={12}>
            <Typography.Title level={4}>Case notes</Typography.Title>
            <Form
              form={form}
              layout="vertical"
              initialValues={initialData}
              onFinish={onSubmit && handleSubmit}
              onFieldsChange={handleChange}
            >
              <Form.Item
                label="Text field"
                name="note"
              >
                <Input.TextArea rows={4} placeholder="Notes" disabled={isInfoView || isDisabledField} />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        {editMode && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleFormSubmit}
            isFormValid
          />
        )}
        {children}
      </ContentCard>
    </div>
  );
};

SummaryInfoForm.defaultProps = {
  type: 'create',
  initialData: {},
  editMode: false,
  isAdmin: false,
  disabled: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  children: null,
  caseTotalPrice: undefined,
  isInfoView: false,
  handleFilesUpdate: undefined,
  handleFileDelete: undefined,
  uploadFilesCall: undefined,
  filesUploadProps: undefined,

  uploadingFiles: undefined,
  percentCompleted: undefined,
};

export default SummaryInfoForm;
