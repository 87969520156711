import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Input, message, Pagination, PaginationProps, Row, Spin, Typography } from 'antd';
import { DeleteFilled, EditFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { EventsGetParams, useEventsGet } from '../../../../hooks/events';
import Loading from '../../../Common/Loading';
import { RootState } from '../../../../store/reducers';
import { deleteEvent as actionDeleteEvent, moduleName } from '../../../../store/ducks/events';
import { Error } from '../../../../store/ducks/common';
import { getMessageInError } from '../../../../hooks/fetch';
import { Action } from '../../../../store';
import UpdateEvent from '../UpdateEvent';
import { onActionClick, queryFilterParams } from '../../../../utils';
import confirm from '../../../Common/ModalConfirm';
import NotFoundContent from '../../../Common/NotFoundContent';

import styles from './index.module.less';

interface CalendarList {
  eventCreateLoading: boolean;
  eventCreateError: Error | null;
  eventUpdateLoading: boolean;
  eventUpdateError: Error | null;
  eventDeleteLoading: boolean;
  eventDeleteError: Error | null;
  deleteEvent: (payload: { id: number; }) => Action;
}

const CalendarList: React.FC<CalendarList> = ({
  eventCreateLoading,
  eventCreateError,
  deleteEvent,
  eventUpdateLoading,
  eventUpdateError,
  eventDeleteLoading,
  eventDeleteError,
}) => {
  const eventsListGet = useEventsGet();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFirstLoadingFinished, setIsFirstLoadingFinished] = useState<boolean>(false);

  const [collapseState, setCollapseState] = useState<number | undefined>(undefined);

  const handleCollapseState = (key: number) => {
    if (collapseState) {
      confirm({
        title: 'Reset changes',
        content: 'Are you sure you want to reset your changes?',
        onOk: collapseState === key ? () => setCollapseState(undefined) : () => setCollapseState(key),
        centered: true,
      });
    } else {
      setCollapseState(key);
    }
  };

  const fetchEventsList = () => {
    const params: EventsGetParams = {
      page: 1,
      take: 100,
      orderBy: 'ASC',
      orderByColumn: 'eventDate',
    };

    if (searchParams.get('page')) {
      params.page = Number(searchParams.get('page')) || undefined;
    }

    if (searchParams.get('name')) {
      params.name = searchParams.get('name') || undefined;
    }

    eventsListGet.fetch(params)
      .then(() => setIsFirstLoadingFinished(true));
  };

  useEffect(() => {
    fetchEventsList();
  }, []);

  useEffect(() => {
    /** isFirstLoadingFinished marks is need to make initial request or not */
    if (isFirstLoadingFinished) {
      if (searchParams.get('name')) {
        const timeoutId = setTimeout(() => fetchEventsList(), 1500);

        return () => clearTimeout(timeoutId);
      }

      if (searchParams.get('name') === '' || searchParams.get('name') === null) {
        fetchEventsList();
      }
      setCollapseState(undefined);
    }

    return undefined;
  }, [searchParams.get('name')]);

  useEffect(() => {
    if (searchParams.get('page') && isFirstLoadingFinished) {
      fetchEventsList();
    }
  }, [searchParams.get('page')]);

  useEffect(() => {
    /** reload list data, after create/update/delete request finished without errors */
    if ((!eventCreateLoading && !eventCreateError)
      && (!eventUpdateLoading && !eventUpdateError)
      && (!eventDeleteLoading && !eventDeleteError)
      && isFirstLoadingFinished) {
      fetchEventsList();
      setCollapseState(undefined);
    }
  }, [eventCreateLoading, eventDeleteLoading, eventUpdateLoading]);

  useEffect(() => {
    if (eventsListGet.error) {
      message.error(getMessageInError(eventsListGet.error));
      eventsListGet.clearError();
    }
  }, [eventsListGet.error]);

  useEffect(() => {
    if (eventDeleteError?.message) {
      message.error(eventDeleteError.message);
    }
  }, [eventDeleteError]);

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setSearchParams(queryFilterParams({ page: `${page}`, name: searchParams.get('name') || '' }));
  };

  return (
    <div>
      <Col span={24}>
        <Col span={8} style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Input
            placeholder="Search description"
            value={searchParams.get('name') || ''}
            onChange={(e) => setSearchParams(queryFilterParams({ name: e.target.value }))}
            addonAfter={eventsListGet.loading
              ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} />} />
              : <SearchOutlined />}
          />
        </Col>
      </Col>
      <Collapse
        ghost
        bordered={false}
        accordion
        className="collapse-list-type"
        collapsible="icon"
        activeKey={collapseState}
        destroyInactivePanel
        style={{ position: 'relative' }}
      >
        <Loading
          absolute
          delay={50}
          visible={eventsListGet.loading || eventDeleteLoading}
          style={{ borderRadius: 10 }}
        />
        {eventsListGet.data?.data.length ? eventsListGet.data?.data.map(({ id, eventDate, name, repeat }) => (
          <Collapse.Panel
            key={id}
            header={(
              <Card
                key={id}
                size="small"
                style={{ borderRadius: '10px' }}
              >
                <Row gutter={[6, 6]}>
                  <Col span={4}>
                    <Typography.Text strong>
                      {repeat ? moment(eventDate).format('MMMM DD') : moment(eventDate).format('MMMM DD, YYYY')}
                    </Typography.Text>
                  </Col>
                  <Col span={13}>
                    <Typography.Text>
                      {name}
                    </Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Typography.Text>
                      {repeat ? 'Repeatable' : 'Not repeatable'}
                    </Typography.Text>
                  </Col>
                  <Col span={2}>
                    <Row style={{ gap: '6px' }}>
                      <Button
                        size="middle"
                        style={{ height: '24px', width: '24px' }}
                        type="primary"
                        icon={<EditFilled style={{ fontSize: 14 }} />}
                        onClick={() => handleCollapseState(id)}
                        title="Edit"
                      />
                      <Button
                        size="middle"
                        style={{ height: '24px', width: '24px' }}
                        type="primary"
                        icon={<DeleteFilled style={{ fontSize: 14 }} />}
                        onClick={() => onActionClick({
                          title: 'Delete event',
                          content: 'Are you sure you want to delete this event?',
                          actionName: 'delete event',
                          reduxAction: () => deleteEvent({ id }),
                        })}
                        title="Delete"
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
              )}
            showArrow={false}
          >
            <UpdateEvent id={id} />
          </Collapse.Panel>
        )) : <NotFoundContent style={{ height: 160 }} />}
      </Collapse>
      {eventsListGet.data?.meta && !!eventsListGet.data?.data.length && eventsListGet.data?.meta.itemCount > 100 && (
        <div className={styles.paginationRow}>
          <Pagination
            onChange={onPageChange}
            defaultCurrent={eventsListGet.data.meta.page}
            pageSize={eventsListGet.data.meta.take}
            total={eventsListGet.data.meta.itemCount}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default connect((state: RootState) => ({
  eventCreateLoading: state[moduleName].eventCreateLoading,
  eventCreateError: state[moduleName].eventCreateError,
  eventUpdateLoading: state[moduleName].eventUpdateLoading,
  eventUpdateError: state[moduleName].eventUpdateError,
  eventDeleteLoading: state[moduleName].eventDeleteLoading,
  eventDeleteError: state[moduleName].eventDeleteError,
}), {
  deleteEvent: actionDeleteEvent,
})(CalendarList);
