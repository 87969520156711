import { Button } from 'antd';
import React, { useState } from 'react';
import { JsonResult } from '../../../../types';
import PatientCreateDrawer from '../Create';

interface PatientCreateButton {
  title: string;
  id: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  type: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text';
  style?: JsonResult;
  isAdmin?: boolean;
}

const PatientCreateButton = (props: PatientCreateButton) => {
  const { id, type, children, icon, disabled, style } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        id={id}
        type={type}
        icon={icon}
        disabled={disabled}
        onClick={() => setIsDrawerOpen(true)}
        style={style}
      >
        {children}
      </Button>
      <PatientCreateDrawer
        isOpen={isDrawerOpen}
        close={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

PatientCreateButton.defaultProps = {
  icon: null,
  disabled: false,
  style: {},
  isAdmin: false,
};

export default PatientCreateButton;
