import React, { useEffect, useState } from 'react';
import { Button, Col, Form, message, Row, Space, Typography } from 'antd';
import Icon, { RightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserRoles } from '../../../../../../enums/user';
import { getMessageInError } from '../../../../../../hooks/fetch';
import { useUsersGet, User as UserHooks } from '../../../../../../hooks/users';
import DrawerModal from '../../../../../Common/DrawerModal';
import { Send } from '../../../../../Common/Icon';
import EmailForm from '../../../Forms/EmailForm';
import DoctorInfoForm from '../Forms/DoctorInfoForm';
import { RootState } from '../../../../../../store/reducers';
import {
  moduleName as doctorModuleName,
  CreateDoctor,
  createDoctor as actionCreateDoctor,
  assignDoctor as actionAssignDoctor, AssignDoctor,
} from '../../../../../../store/ducks/doctor';
import { moduleName as authModuleName, User } from '../../../../../../store/ducks/auth';
import { Action } from '../../../../../../store';
import { Error } from '../../../../../../store/ducks/common';
import { DefaultModalProps, JsonResult, Option } from '../../../../../../types';
import { prepareDoctorBody } from '../../../../../../utils/prepareData';

interface DoctorCompleteRegisterProps {
  loading?: boolean;
  hasPassword?: boolean;
  initialData?: JsonResult;
  practiceSelectOption?: Option;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: CreateDoctor) => void;
  title?: string;
  submitBtnText?: string;
  submitBtnIcon?: React.ReactNode;
}

interface DoctorCreateDrawer extends DoctorCompleteRegisterProps, DefaultModalProps {
  isManager?: boolean;
  withEmailForm?: boolean;
  // redux props \/
  createDoctor: (payload: CreateDoctor) => Action;
  assignDoctor: (payload: AssignDoctor) => Action;
  doctorLoading: boolean;
  doctorError: Error | null;
  doctorCreateError: Error | null;
  user: User | null;
}

const foundSmileUserMessage = (
  <div>
    <Typography>We found an existing account associated with this email.</Typography>
    <Typography>Please, review Doctor Info and verify by clicking "Send Registration Link"</Typography>
  </div>
);

const DoctorCreateDrawer: React.FC<DoctorCreateDrawer> = React.memo((props) => {
  const {
    isOpen,
    close,
    onReload,
    createDoctor,
    assignDoctor,
    doctorLoading,
    doctorError,
    doctorCreateError,
    isManager,
    user,
    initialData,
    practiceSelectOption,
    handleSave,
    title,
    loading,
    hasPassword,
    submitBtnText,
    submitBtnIcon,
    withEmailForm,
  } = props;
  const [form] = Form.useForm();
  const [emailForm] = Form.useForm();

  const { id: paramsPracticeId = '' } = useParams<{ id: string; }>();
  const totalSteps = withEmailForm ? 2 : 1;
  const [step, setStep] = useState<number>(1);
  const [smileUser, setSmileUser] = useState<UserHooks | null>();
  const [userEmail, setUserEmail] = useState<string>();
  const requestPracticeId = paramsPracticeId || (
    user?.role === 'practice manager' ? user?.doctor?.practice?.id : undefined);
  const getUsers = useUsersGet();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const emailForm1Value = Form.useWatch('email', emailForm);

  const handleClose = () => {
    emailForm.resetFields();
    form.resetFields();
    setStep(1);
    setUserEmail(undefined);
    setSmileUser(null);
    close();
  };

  const findSmileUser = (email: string) => {
    getUsers.fetch({ email })
      .then((response) => {
        const foundSmileUser = response?.data.find((user) => user.role === UserRoles.doctor && !user.doctor?.practice);

        if (response?.data?.length && !foundSmileUser) {
          emailForm.setFields([
            {
              name: 'email',
              errors: ['Email already exists'],
            },
          ]);
        }

        if (foundSmileUser) {
          setSmileUser(foundSmileUser);
          form?.setFieldsValue({ ...foundSmileUser });
        } else {
          setSmileUser(null);
          form?.setFieldsValue({
            email: emailForm1Value, // undefined, userEmail
            firstName: undefined,
            lastName: undefined,
            nickname: undefined,
            phone: undefined,
            position: undefined,
            specialty: undefined,
          });
        }

        if (!response?.data.length || foundSmileUser) {
          setStep((prev) => prev + 1);
        }
      })
      .catch((error) => {
        message.error(getMessageInError(error));
        getUsers.clearError();
      });
  };

  const handleNext = () => {
    if (withEmailForm && step === 1) {
      emailForm.validateFields().then(({ email }) => {
        setUserEmail(email);
        findSmileUser(email);
      });
    }
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = prepareDoctorBody(values, isManager ? UserRoles.practiceManager : UserRoles.doctor, values?.userId);

      if (smileUser && smileUser.doctor?.id) {
        assignDoctor({ practiceId: body.practice, doctorId: smileUser.doctor.id });

        return;
      }

      if (handleSave) {
        handleSave(body);
      } else {
        createDoctor(body);
      }
    });
  };

  useEffect(() => {
    if (doctorCreateError?.message) {
      message.error(doctorCreateError.message, 5);
    }
  }, [doctorCreateError]);

  useEffect(() => {
    if (!doctorLoading && !doctorError && isOpen) {
      handleClose();
      onReload?.();
    }
  }, [doctorLoading]);

  useEffect(() => {
    if (!initialData || !isOpen) return;

    form?.setFieldsValue(initialData);
  }, [initialData]);

  return (
    <DrawerModal
      title={title || (isManager ? 'Add New Practice Manager' : 'Add New Doctor')}
      open={isOpen}
      onClose={handleClose}
      extra={(
        <Space>
          {step < totalSteps && (
            <Button
              type="default"
              onClick={handleNext}
              id="nextBtn"
            >
              Next
              <RightOutlined style={{ fontSize: 12 }} />
            </Button>
          )}
          {step === totalSteps && (
            <Button
              id="sendRegistrationLink"
              type="primary"
              icon={submitBtnIcon || <Icon component={Send} />}
              disabled={!isFormValid}
              onClick={handleSubmit}
              loading={doctorLoading}
            >
              {submitBtnText || 'Send Registration Link'}
            </Button>
          )}
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => form.resetFields()}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          {withEmailForm && step === 1 && (
            <EmailForm
              title="Doctor Email"
              form={emailForm}
              loading={getUsers.loading}
              initialData={initialData?.smileUser?.email}
            />
          )}
          {(withEmailForm ? step === 2 : step === 1) && (
            <Form
              form={form}
              name="doctor_create"
              layout="vertical"
              autoComplete="off"
              initialValues={{
                ...(smileUser || initialData || { email: userEmail }),
                practice: requestPracticeId || undefined,
              }}
              onFieldsChange={() => setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length))}
            >
              <DoctorInfoForm
                message={smileUser && foundSmileUserMessage}
                formType="create"
                disabled={smileUser ? {
                  position: true,
                  firstName: true,
                  lastName: true,
                  nickname: true,
                  specialty: true,
                  email: true,
                  phone: true,
                } : undefined}
                cardLoading={loading}
                modalPracticeId={initialData?.practice || requestPracticeId}
                hasPassword={hasPassword}
                practiceSelectOption={practiceSelectOption}
              />
            </Form>
          )}
        </Col>
      </Row>
    </DrawerModal>
  );
});

DoctorCreateDrawer.displayName = 'DoctorCreateDrawer';

DoctorCreateDrawer.defaultProps = {
  isManager: false,
  withEmailForm: false,
  title: undefined,
  loading: false,
  hasPassword: false,
  initialData: undefined,
  practiceSelectOption: undefined,
  disabled: undefined,
  handleSave: undefined,
  submitBtnText: undefined,
  submitBtnIcon: undefined,
  onReload: undefined,
};

export default connect((state: RootState) => ({
  doctorLoading: state[doctorModuleName].doctorLoading,
  doctorError: state[doctorModuleName].doctorError,
  doctorCreateError: state[doctorModuleName].doctorCreateError,
  user: state[authModuleName].user,
}), {
  createDoctor: actionCreateDoctor,
  assignDoctor: actionAssignDoctor,
})(DoctorCreateDrawer);
