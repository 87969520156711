import React from 'react';
import { Layout } from 'antd';

import CaseFlowProvider from '../../context/caseFlow';
import Content from '../../components/Pages/Cases';
import { displayName } from '../../config';
import FileDownloadProvider from '../../context/fileDownload';

function Cases(): JSX.Element {
  document.title = `${displayName}: Cases`;

  return (
    <Layout>
      <CaseFlowProvider>
        <FileDownloadProvider>
          <Content />
        </FileDownloadProvider>
      </CaseFlowProvider>
    </Layout>
  );
}

export default Cases;
