import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Divider, List, Skeleton } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import React from 'react';
import { onActionClick } from '../../../../../utils';
import styles from '../index.module.less';
import { FetchDelete } from '../../../../../hooks/fetch';
import { FetchError, FetchSuccess } from '../../../../../types';

const getHeight = (dataLength: number): number => {
  if (dataLength >= 6) return 420;
  if (dataLength >= 5) return 330;
  if (dataLength >= 4) return 280;
  if (dataLength >= 3) return 220;
  if (dataLength >= 2) return 160;
  if (dataLength === 0) return 170;

  return 120;
};

interface InfiniteScrollList<T> {
  view: 'doctor' | 'labStaff';
  dataState: T[];
  loadMoreData: () => void;
  loading: boolean;
  receiverDeleteHook: FetchDelete<FetchSuccess, FetchError, string>;
  handleResetForm: (clearFormField: boolean) => void;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfiniteScrollList = <T extends { id: string; [key: string]: any; }>({
  view,
  dataState,
  loadMoreData,
  loading,
  // hooks
  receiverDeleteHook,
  handleResetForm,
  disabled,
}: InfiniteScrollList<T>) => (
  <div
    id="scrollableDiv"
    style={{ maxHeight: 600 }}
  >
    <InfiniteScroll
      height={getHeight(dataState.length)}
      dataLength={dataState.length}
      next={loadMoreData}
      hasMore={false}
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      endMessage={!loading
          && dataState.length !== 0 && (<Divider />)}
    >
      <List
        dataSource={dataState}
        loading={loading}
        renderItem={(notifReceiver) => (
          <List.Item
            key={notifReceiver.id}
            actions={[
              <Button
                key="delete"
                type="primary"
                style={{ height: '24px', width: '24px' }}
                icon={<DeleteFilled style={{ fontSize: 14 }} />}
                disabled={disabled}
                onClick={() => onActionClick({
                  title: 'Delete receiver',
                  content: 'Are you sure you want to remove this notification receiver from the case?',
                  actionName: 'delete event',
                  hook: () => receiverDeleteHook.fetch(notifReceiver.id).then(() => handleResetForm(false)),
                  centered: true,
                })}
                title="Delete"
              />,
            ]}
          >
            <List.Item.Meta
              title={(
                <div className={styles.flexRow}>
                  <div className={styles.nameRow}>
                    <span title="Email">{notifReceiver?.[view]?.user?.email}</span>
                    <span className={styles.name} title="Name">
                      {notifReceiver?.[view]?.user?.firstName}
                      {' '}
                      {notifReceiver?.[view]?.user?.lastName}
                    </span>
                  </div>
                  <span className={styles.extra} title="Position">
                    {notifReceiver?.[view]?.position || '-'}
                  </span>
                </div>
                )}
            />
          </List.Item>
        )}
      />
    </InfiniteScroll>
  </div>
  );

InfiniteScrollList.defaultProps = {
  disabled: false,
};

export default InfiniteScrollList;
