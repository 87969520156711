import { Space, Typography } from 'antd';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styles from './index.module.less';

interface Tile {
  title: string;
  icon: ReactNode;
  isActive?: boolean;
  onClick: () => void;
}

const Tile: React.FC<Tile> = (props) => {
  const { title, icon, isActive, onClick } = props;

  return (
    <div className={clsx(styles.wrapper, { [styles.active]: isActive })} onClick={onClick}>
      <Space direction="vertical" className={styles.content}>
        <div className={styles.icon}>{icon}</div>
        <Typography className={styles.title}>{title}</Typography>
      </Space>
    </div>
  );
};

Tile.defaultProps = {
  isActive: false,
};

export default Tile;
