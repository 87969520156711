import React, { useEffect, useState } from 'react';
import { Menu, Button } from 'antd';
import { Header } from 'antd/es/layout/layout';

import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon, { ContactsFilled, ReconciliationFilled } from '@ant-design/icons/lib';
import clsx from 'clsx';
import NotificationsModal from '../../../Common/NotificationsModal';
import styles from './index.module.less';
import Logo from '../Sider/Logo';
import { LogOut, Profile, UsersIcon } from '../../../Common/Icon';
import { moduleName, signOut as actionSignOut, User } from '../../../../store/ducks/auth';
import { Action } from '../../../../store';
import { getItem } from '../Sider/Menu';
import Patients from '../../../../pages/patients';
import Users from '../../../../pages/users';
import { Route } from '../../../../routes';
import { getRedirectRoutePath, isRoleEnough } from '../../../../utils/auth';
import { RootState } from '../../../../store/reducers';

const routes: Route[] = [
  {
    bind: {
      element: <Users />,
      path: '/cases',
    },
    name: 'Cases',
    icon: <ContactsFilled />,
    roleNeeded: 'lab staff',
  },
  {
    bind: {
      element: <Patients />,
      path: '/patients',
    },
    name: 'Patients',
    icon: <Icon component={UsersIcon} />,
    roleNeeded: ['doctor', 'practice manager'],
  },
  {
    bind: {
      element: <Users />,
      path: '/reporting',
    },
    name: 'Reporting',
    icon: <ReconciliationFilled />,
    roleNeeded: ['admin', 'root'],
  },
];

interface HeaderCommon {
  signOut: () => Action;
  user: User | null;
  roleList: string[] | null;
}

const HeaderCommon: React.FC<HeaderCommon> = ({ signOut, user, roleList }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [key, setKey] = useState('');

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  if (!key) {
    return null;
  }

  const shownRoutes = routes.filter((item) => (
    isRoleEnough(item.roleNeeded || 'patient', item.strictRoleComparison, user?.role, roleList)));

  const items = isRoleEnough(['doctor', 'lab staff'], undefined, user?.role, roleList)
    ? shownRoutes.map((route) => getItem(route)) : [];

  const handleMenuItemClick = (item: { key: string; }) => {
    navigate(item.key);
  };

  return (
    <Header className={styles.header}>
      <div className={styles.logo}>
        <Logo link={getRedirectRoutePath(user)} />
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        items={items}
        onClick={handleMenuItemClick}
        selectedKeys={[pathname, `/${key}`]}
        defaultOpenKeys={[`/${key}`]}
        defaultSelectedKeys={[pathname, `/${key}`]}
      />
      <div className={styles.actions}>
        <div className={styles.action}>
          <div style={{ width: '30px' }} className={clsx(styles['action-button'], styles.notifications)}>
            <NotificationsModal iconClassName={pathname === '/notifications' ? styles.active : undefined} />
          </div>
        </div>
        <div className={styles.action}>
          <Link
            to="/profile"
            className={clsx(styles['action-button'], pathname === '/profile' && styles.active)}
          >
            <Profile />
          </Link>
        </div>
        <div className={styles.action}>
          <Button type="text" onClick={signOut} className={styles['action-button']}>
            <LogOut />
          </Button>
        </div>
      </div>
    </Header>
  );
};

export default connect((state: RootState) => ({
  user: state[moduleName].user,
  roleList: state[moduleName].roleList,
}), {
  signOut: actionSignOut,
})(HeaderCommon);
