import React, { useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { connect } from 'react-redux';
import DrawerModal from '../../../Common/DrawerModal';
import { Patient, usePatientId, useUpdatePatientStatus } from '../../../../hooks/patients';
import { getMessageInError } from '../../../../hooks/fetch';
import { RootState } from '../../../../store/reducers';
import {
  clearPatientData as actionClearPatientData,
  moduleName as patientModuleName,
  UpdatePatient,
  updatePatient as actionUpdatePatient,
} from '../../../../store/ducks/patient';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import { isObjEqualLodash } from '../../../../utils';
import { moduleName as authModuleName, User } from '../../../../store/ducks/auth';
import { initialCountryData, preparePatientBody, preparePatientInitialData } from '../../../../utils/prepareData';
import PatientFormContent from '../Forms/PatientsFormContent';
import { DefaultModalProps } from '../../../../types';
import { UserStatuses } from '../../../../enums/user';

interface PatientInfoDrawer extends DefaultModalProps {
  id: number | string;
  // redux props \/
  updatePatient: (payload: UpdatePatient) => Action;
  patient: Patient | null;
  patientLoading: boolean;
  patientError: Error | null;
  user: User | null;
  clearPatientData: () => Action;
}

const PatientInfoDrawer: React.FC<PatientInfoDrawer> = React.memo((
  { id, isOpen, close, onReload, updatePatient, patientLoading, patient, patientError, clearPatientData, user },
) => {
  const [form] = Form.useForm();
  const patientById = usePatientId();
  const updatePatientStatus = useUpdatePatientStatus();

  const requestDoctorId = user?.role === 'doctor' ? user?.doctor?.id : undefined;
  const isDisabledEditForDoc = user?.role === 'doctor' && (patientById.data?.doctor.id !== user?.doctor?.id);

  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  useEffect(() => {
    setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
  }, [patient]);

  const fetchPatientId = () => {
    patientById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchPatientId();
    }
  }, [id]);

  const patientData = patient || patientById.data;
  const initialValues = {
    ...preparePatientInitialData(patientData),
    country: !!patientById.data && (
      patientData?.addresses?.[0]?.country ? JSON.stringify(patientData?.addresses?.[0]?.country) : initialCountryData),
  };
  // Note - in modals where we have country, we need to set initial country data like that

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = preparePatientBody(values);

      updatePatient({ ...body, id: `${id}` });
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsFormChanged(false);
    setIsFormValid(true);
  };

  const handleClose = () => {
    close();
    clearPatientData();
    patientById.clearResponse?.();
  };

  useEffect(() => {
    if (form && patientById.data && !patientById.error && form) {
      form.setFieldsValue(initialValues);
    }
  }, [form, patientById.data]);

  useEffect(() => {
    if (patientById.error && isOpen) {
      message.error(getMessageInError(patientById.error), 5);
      patientById.clearError();
    }
  }, [patientById.error]);

  useEffect(() => {
    if (patientError?.message && isOpen) {
      message.error(patientError.message, 5);
    }
  }, [patientError]);

  useEffect(() => {
    if (!patientLoading && !patientError && isOpen) {
      handleClose();
      onReload?.();
      message.success('Patient has been updated!');
    }
  }, [patientLoading]);

  useEffect(() => {
    if (updatePatientStatus.data && !updatePatientStatus.error && id && isOpen) {
      fetchPatientId();
      onReload?.();
    }
  }, [updatePatientStatus.data]);

  /* Uncomment if patient will need archive logic (after backend fix with services)
  const [lastActionName, setLastActionName] = useState<string>('');

  const items: (MenuItemType | SubMenuType | MenuItemGroupType)[] = [
    patientById.data?.removed ? {
      label: 'Unarchive',
      key: '6',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updatePatientStatus.fetch({ removed: false }, patientById.data?.id),
        });
        setLastActionName('activated');
      },
      className: 'unarchiveBtn',
    } : {
      label: 'Archive',
      key: '4',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Archive',
          actionName: 'archive',
          hook: () => updatePatientStatus.fetch({ removed: true }, patientById.data?.id),
        });
        setLastActionName('archived');
      },
      className: 'archiveBtn',
    },
  ];

  const menuButtons = (
    <Menu
      items={items}
    />
  );

  useEffect(() => {
    if (updatePatientStatus.data && !updatePatientStatus.error) {
      message.success(`${capitalizeFirstLetter(lastActionName)}!`);
    }
  }, [updatePatientStatus.data]);

  useEffect(() => {
    if (updatePatientStatus.error) {
      message.error(getMessageInError(updatePatientStatus.error));
      updatePatientStatus.clearError();
    }
  }, [updatePatientStatus.error]); */

  return (
    <DrawerModal
      title="Patient Profile"
      status={patientById.data?.removed ? UserStatuses.archived : UserStatuses.active}
      open={isOpen}
      onClose={handleClose}
      /* Uncomment if patient will need archive logic (after backend fix with services)
      extra={!isDisabledEditForDoc && (
        <Space>
          <Dropdown
            trigger={['click']}
            key="button"
            overlay={menuButtons}
          >
            <Button type="ghost" onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
              <Space>
                <SettingFilled />
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      )} */
      destroyOnClose
      afterOpenChange={() => {
        form.resetFields();
        setIsFormChanged(false);
      }}
      isFormChanged={isFormChanged}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="patient_info"
              layout="vertical"
              autoComplete="off"
              initialValues={initialValues}
              onFieldsChange={() => {
                setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
                setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
              }}
            >
              <PatientFormContent
                form={form}
                cardLoading={patientById.loading}
                editMode={isDisabledEditForDoc ? undefined
                  : {
                    onSubmit: handleSubmit,
                    isFormValid,
                    onCancel: handleCancel,
                    loading: patientLoading,
                  }}
                modalDoctorId={requestDoctorId}
                disabledEdit={isDisabledEditForDoc}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
});

PatientInfoDrawer.displayName = 'PatientInfoDrawer';

PatientInfoDrawer.defaultProps = {
  onReload: undefined,
};

export default connect((state: RootState) => ({
  patientLoading: state[patientModuleName].patientLoading,
  patient: state[patientModuleName].patient,
  patientError: state[patientModuleName].patientError,
  user: state[authModuleName].user,
}), {
  updatePatient: actionUpdatePatient,
  clearPatientData: actionClearPatientData,
})(PatientInfoDrawer);
