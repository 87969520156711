import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, message, Row, Space } from 'antd';
import Icon, { DownOutlined, LockFilled, SettingFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { MenuItemGroupType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import DrawerModal from '../../../Common/DrawerModal';
import { ArchiveFilled, Send } from '../../../Common/Icon';
import AdminInfoForm from '../Forms/AdminInfoForm';
import { User, useUserId, useUserResendLink, useUpdateUserStatus } from '../../../../hooks/users';
import { getMessageInError } from '../../../../hooks/fetch';
import { RootState } from '../../../../store/reducers';
import {
  clearUserData as actionClearUserData,
  moduleName as userModuleName,
  UpdateUser,
  updateUser as actionUpdateUser,
} from '../../../../store/ducks/user';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import { capitalizeFirstLetter, isObjEqualLodash, onActionClick } from '../../../../utils';
import { moduleName as authModuleName, User as AuthUser } from '../../../../store/ducks/auth';
import { prepareUserAdminBody, prepareUserInitialData } from '../../../../utils/prepareData';
import { DefaultModalProps } from '../../../../types';
import { usePasswordForgot } from '../../../../hooks/password';
import { isRoleEnough } from '../../../../utils/auth';

interface AdminInfoDrawer extends DefaultModalProps {
  id: number | string;
  // redux props \/
  updateUser: (payload: UpdateUser) => Action;
  clearUserData: () => Action;
  userLoading: boolean;
  user: User | null;
  userError: Error | null;
  authUser: AuthUser | null;
}

const AdminInfoDrawer: React.FC<AdminInfoDrawer> = React.memo((
  { id, isOpen, close, updateUser, userLoading, user, userError, clearUserData, authUser, onReload },
) => {
  const [form] = Form.useForm();
  const userById = useUserId();
  const userResendLink = useUserResendLink();
  const updateUserStatus = useUpdateUserStatus();
  const resetPassword = usePasswordForgot();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  useEffect(() => {
    setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
  }, [user]);

  const fetchUserrId = () => {
    userById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchUserrId();
    }
  }, [id]);

  const userData = user || userById.data;
  const initialValues = {
    ...prepareUserInitialData(userData),
  };

  useEffect(() => {
    if (form && userById.data && !userById.error && form) {
      form.setFieldsValue(initialValues);
    }
  }, [form, userById.data]);

  useEffect(() => {
    if (userById.error && isOpen) {
      message.error(getMessageInError(userById.error), 5);
      userById.clearError();
    }
  }, [userById.error]);

  useEffect(() => {
    if (userError?.message && isOpen) {
      message.error(userError.message, 5);
    }
  }, [userError]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = prepareUserAdminBody(values, initialValues.role);

      return updateUser({ ...body, id: `${id}` });
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsFormChanged(false);
    setIsFormValid(true);
  };

  const handleClose = () => {
    close();
    clearUserData();
  };

  useEffect(() => {
    if (!userLoading && !userError && isOpen) {
      handleClose();
      onReload?.();
      message.success('Admin has been updated!');
    }
  }, [userLoading]);

  useEffect(() => {
    if (updateUserStatus.data && !updateUserStatus.error && id && isOpen) {
      fetchUserrId();
      onReload?.();
    }
  }, [updateUserStatus.data]);

  const [lastActionName, setLastActionName] = useState<string>('');

  const items: (MenuItemType | SubMenuType | MenuItemGroupType)[] = [
    {
      label: 'Resend Link',
      key: '1',
      icon: <Icon component={Send} />,
      onClick: () => {
        onActionClick({
          title: 'Resend Link',
          content: 'Are you sure you want to resend link?',
          actionName: 'resend link',
          hook: () => userResendLink.fetch(undefined, `${id}/resend-link`),
        });
        setLastActionName('link was send');
      },
      className: 'resendLinkBtn',
    },
    {
      label: 'Reset Password',
      key: '2',
      icon: <LockFilled />,
      onClick: () => {
        onActionClick({
          title: 'Reset Password',
          content: 'Are you sure you want to reset password for this user?',
          actionName: 'reset password',
          hook: () => resetPassword.fetch({ email: userById.data?.email || '' }),
        });
        setLastActionName('Password reset link was send');
      },
      className: 'resetPasswordBtn',
    },
    userById.data?.status === 'deactivated' ? {
      label: 'Activate',
      key: '5',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updateUserStatus.fetch({ status: 'active' }, id),
        });
        setLastActionName('activated');
      },
      className: 'activateBtn',
    } : {
      label: 'Deactivate',
      key: '3',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          title: 'Deactivate',
          actionName: 'deactivate',
          hook: () => updateUserStatus.fetch({ status: 'deactivated' }, id),
        });
        setLastActionName('deactivated');
      },
      className: 'deactivateBtn',
    },
    userById.data?.status === 'archived' ? {
      label: 'Unarchive',
      key: '6',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Activate',
          actionName: 'activate',
          hook: () => updateUserStatus.fetch({ status: 'active' }, id),
        });
        setLastActionName('activated');
      },
      className: 'unarchiveBtn',
    } : {
      label: 'Archive',
      key: '4',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          title: 'Archive',
          actionName: 'archive',
          hook: () => updateUserStatus.fetch({ status: 'archived' }, id),
        });
        setLastActionName('archived');
      },
      className: 'archiveBtn',
    },
  ];

  const menuItems = items
    .filter((item) => userById.data?.status === 'pending'
      ? item.label === 'Resend Link' : item.label !== 'Resend Link')
    .filter((item) => (userById.data?.status === 'deactivated'
      || userById.data?.status === 'archived')
      ? item : (item.label !== 'Archive' && item.label !== 'Unarchive'));

  useEffect(() => {
    if ((updateUserStatus.data && !updateUserStatus.error) || (userResendLink.data && !userResendLink.error)
    || (resetPassword.data && !resetPassword.error)) {
      message.success(`${capitalizeFirstLetter(lastActionName)}!`);
    }
  }, [updateUserStatus.data, userResendLink.data, resetPassword.data]);

  useEffect(() => {
    if (updateUserStatus.error) {
      message.error(getMessageInError(updateUserStatus.error));
      updateUserStatus.clearError();
    }
  }, [updateUserStatus.error]);

  useEffect(() => {
    if (userResendLink.error) {
      message.error(getMessageInError(userResendLink.error));
      userResendLink.clearError();
    }
  }, [userResendLink.error]);

  useEffect(() => {
    if (resetPassword.error) {
      message.error(getMessageInError(resetPassword.error));
      resetPassword.clearError();
    }
  }, [resetPassword.error]);

  return (
    <DrawerModal
      title={initialValues.role === 'root' ? 'Superadmin Profile' : 'Admin Profile'}
      status={userById.data?.status || ''}
      open={isOpen}
      onClose={handleClose}
      extra={isRoleEnough('root') && (
        <Space>
          <Dropdown
            trigger={['click']}
            key="button"
            menu={{ items: menuItems }}
          >
            <Button type="ghost" onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
              <Space>
                <SettingFilled />
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => {
        form.resetFields();
        setIsFormChanged(false);
      }}
      isFormChanged={isFormChanged}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="doctor_info"
              layout="vertical"
              autoComplete="off"
              initialValues={initialValues}
              onFieldsChange={() => {
                setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
                setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
              }}
            >
              <AdminInfoForm
                cardLoading={userById.loading}
                editMode={{
                  onSubmit: handleSubmit,
                  isFormValid,
                  onCancel: handleCancel,
                  loading: userLoading,
                }}
                disableForm={initialValues.role === 'root' && authUser?.role !== 'root'}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
});

AdminInfoDrawer.displayName = 'AdminInfoDrawer';

AdminInfoDrawer.defaultProps = {
  onReload: undefined,
};

export default connect((state: RootState) => ({
  userLoading: state[userModuleName].userLoading,
  user: state[userModuleName].user,
  userError: state[userModuleName].userError,
  authUser: state[authModuleName].user,
}), {
  updateUser: actionUpdateUser,
  clearUserData: actionClearUserData,
})(AdminInfoDrawer);
