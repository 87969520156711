import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';

import { CaseModalState, ModalTypes } from '../../../types';
import CasesTable from './Table';
import CaseInfo from './Info';

function Cases(): JSX.Element {
  const [modal, setModal] = useState<CaseModalState | null>(null);

  const openModal = useCallback((state: CaseModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <CaseInfo
        title="Case Info"
        drawerOpen={modal?.type === ModalTypes.info && !!modal?.id}
        caseStatus={modal?.caseStatus}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <CasesTable openModal={openModal} />
    </Content>
  );
}

export default Cases;
