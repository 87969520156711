import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, FormInstance, message } from 'antd';

import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState, ModalTypes } from '../../../../types';
import { TablePatientRow, useTablePatientRow } from '../../../../hooks/patients';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { UsersIcon } from '../../../Common/Icon';
import { isRoleEnough } from '../../../../utils/auth';
import { UserRoles } from '../../../../enums/user';

interface TablePatients {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  isDoctorInfoPage?: boolean;
}

function TablePatients(props: TablePatients)
  : JSX.Element {
  const {
    params,
    openModal,
    selectedRows,
    onRowSelection,
    isDoctorInfoPage,
  } = props;
  const navigate = useNavigate();
  const formRef = useRef<FormInstance>();
  const patientsGet = useTablePatientRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (patientsGet.error) {
      message.error(getMessageInError(patientsGet.error));
      patientsGet.clearError();
    }
  }, [patientsGet.error]);

  const onReload = () => actionRef.current?.reload();

  const toolBarRender = () => [
    (!isDoctorInfoPage && (
      <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
        <FilterOutlined />
        {' '}
        Filter
      </Button>
    )),
    (isRoleEnough(['doctor', 'practice manager', 'admin', 'root']) && openModal) && (
      <Button
        type="primary"
        onClick={() => openModal?.({ type: ModalTypes.create, role: UserRoles.patient, onReload })}
        id="addButton"
        icon={<PlusOutlined />}
      >
        Add New Patient
      </Button>
    ),
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TablePatientRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    if (!isDoctorInfoPage) {
      setSearchParams({ ...args, ...getSorterParams(sorter) });
    }

    return patientsGet.fetch({
      ...newParams,
      ...params,
    }).then((data) => {
      if (data) {
        const { patients, total } = data;

        return ({ data: patients || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  /* const hideColumnInDoctorModalObj = {
    hideInForm: isDoctorInfoPage,
    hideInSearch: isDoctorInfoPage,
    hideInTable: isDoctorInfoPage,
    hideInSetting: isDoctorInfoPage,
    hideInDescriptions: isDoctorInfoPage,
  }; */

  const columns: ProColumns<TablePatientRow>[] = [
    {
      title: <div style={{ textAlign: 'center' }}>#</div>,
      dataIndex: 'index',
      valueType: 'index',
      width: '60px',
      className: 'textAlignCenter',
      sorter: false,
      ellipsis: true,
      hideInForm: !isDoctorInfoPage,
      hideInSearch: !isDoctorInfoPage,
      hideInTable: !isDoctorInfoPage,
      hideInSetting: !isDoctorInfoPage,
      hideInDescriptions: !isDoctorInfoPage,
    },
    {
      title: 'Patient Name',
      dataIndex: 'fullName',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => `${row?.firstName ? `${row?.firstName} ` : ''}${row?.lastName || ''}`,
    },
    {
      title: 'Associated Cases',
      dataIndex: 'cases',
      sorter: false,
      ellipsis: true,
      renderText: (_, row) => (row.cases?.map((itemCase) => (
        <Button
          ghost
          type="primary"
          key={itemCase?.id}
          onClick={() => navigate(`/cases/${itemCase?.id}?fromPage=patients`)}
        >
          {itemCase?.id}
        </Button>
      ))
      ),
      hideInSearch: true,
      hideInForm: true,
    },
    {
      title: 'Doctor Name',
      dataIndex: 'doctorFullName',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => (
        `${row.doctor?.user?.firstName ? `${row.doctor?.user?.firstName} ` : ''}${row?.doctor?.user?.lastName || ''}`
      ),
      hideInForm: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInSearch: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInTable: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInSetting: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInDescriptions: isDoctorInfoPage || isRoleEnough('doctor', true),
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      sorter: true,
      ellipsis: true,
      hideInForm: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInSearch: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInTable: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInSetting: isDoctorInfoPage || isRoleEnough('doctor', true),
      hideInDescriptions: isDoctorInfoPage || isRoleEnough('doctor', true),
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TablePatientRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      form={{ ignoreRules: false }}
      headerTitle={(
        <>
          {!isDoctorInfoPage && (<UsersIcon style={{ fill: '#1F1F1F' }} />)}
          Patients list
        </>
      )}
      rowSelection={!!onRowSelection && rowSelection}
      rowClassName={!isDoctorInfoPage ? 'cursor-pointer' : undefined}
      columnsState={{ persistenceKey: 'pro-table-patients', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => {
          if (!isDoctorInfoPage) {
            openModal?.({ type: ModalTypes.info, role: UserRoles.patient, id: record.id, onReload });
          }
        },
      })}
      search={isDoctorInfoPage ? false : undefined} // params?.doctor
    />
  );
}

TablePatients.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
  isDoctorInfoPage: false,
};

export default TablePatients;
