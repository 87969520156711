import { FormInstance, Rule } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import ControlsEditMode from '../ControlsEditMode';
import SelectCity from '../Selects/SelectCity';
import SelectCountry from '../Selects/SelectCountry';
import SelectState from '../Selects/SelectState';
import styles from './index.module.less';
import { required } from '../../../utils/inputRules';

interface Address {
  form?: FormInstance;
  hasName?: boolean;
  disabled?: boolean;
  editMode?: {
    onSubmit: () => void;
    onCancel?: () => void;
    isFormValid?: boolean;
    loading?: boolean;
  };
  isEditingOpen?: boolean;
  isShipping?: boolean;
  requiredFieldsType?: 'create' | 'completeRegistration';
  subClassName?: string;
}

const Address: React.FC<Address> = (props) => {
  const { form, hasName, editMode, isEditingOpen, isShipping, disabled, requiredFieldsType, subClassName } = props;
  const [isEditing, setIsEditing] = useState<boolean>(!!isEditingOpen);
  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');

  const isDisabledField = disabled || !!(editMode && !isEditing);

  const isCompleteRegistrationForm = requiredFieldsType === 'completeRegistration';
  const isRequiredCompleteRegisterRule: Rule[] = requiredFieldsType === 'completeRegistration' ? required : [];

  const handleCancel = () => {
    if (!editMode || !editMode.onCancel) {
      setIsEditing(false);

      return;
    }

    editMode.onCancel();

    setIsEditing(false);
  };

  const countryValue = Form.useWatch('country', form);
  const stateValue = Form.useWatch('state', form);

  useEffect(() => {
    setCountry(countryValue);
  }, [countryValue]);

  useEffect(() => {
    setState(stateValue);
  }, [stateValue]);

  const handleClear = (field: string) => {
    if (field === 'country') {
      form?.setFields([
        { name: 'state', value: null },
        { name: 'city', value: null },
      ]);
    }
    if (field === 'state') {
      form?.setFields([{ name: 'city', value: null }]);
    }
  };

  const handleSubmit = () => {
    if (!editMode) return;

    editMode.onSubmit();
    setIsEditing(false);
  };

  return (
    <div>
      <Row gutter={15}>
        <Form.Item name="id">
          <Input type="hidden" disabled />
        </Form.Item>
        <Form.Item name="billingAddressId">
          <Input type="hidden" disabled />
        </Form.Item>
        {hasName && (
          <Col span={24}>
            <Form.Item
              label="Address Name"
              name="name"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
                className={`${subClassName}-name`}
              />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <div>
            <Form.Item
              label="Address 1"
              name="street"
              className="with-bottom-text"
              rules={isShipping || isCompleteRegistrationForm ? required : undefined}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
                className={`${subClassName}-street`}
              />
            </Form.Item>
            <Typography.Text className={styles.text}>
              Street address, P.O. box, company name, c/o
            </Typography.Text>
          </div>
          <Form.Item
            label="Country"
            name="country"
            rules={isShipping || isCompleteRegistrationForm ? required : undefined}
          >
            <SelectCountry
              disabled={isDisabledField}
              onChange={(value) => {
                setCountry(value as string);
                handleClear('country');
              }}
              onClear={() => handleClear('country')}
              className={`${subClassName}-country`}
            />
          </Form.Item>
          <Form.Item
            label="City"
            // name="city"
            name="cityString"
            shouldUpdate
            rules={isShipping || isCompleteRegistrationForm ? required : undefined}
          >
            <Input
              placeholder="Type here"
              disabled={!(state || form?.getFieldValue('state')) || isDisabledField}
              className={`${subClassName}-city`}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <div>
            <Form.Item
              label="Address 2"
              name="apartment"
              className="with-bottom-text"
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
                className={`${subClassName}-apartment`}
              />
            </Form.Item>
            <Typography.Text className={styles.text}>
              Apartment, suite, unit, building, floor, etc.
            </Typography.Text>
          </div>

          <Form.Item
            label="State"
            name="state"
            rules={isShipping || isCompleteRegistrationForm ? required : undefined}
            shouldUpdate
          >
            <SelectState
              disabled={!(country || form?.getFieldValue('country')) || isDisabledField}
              country={country || form?.getFieldValue('country')}
              onChange={(value) => {
                setState(value as string);
                handleClear('state');
              }}
              onClear={() => handleClear('state')}
              className={`${subClassName}-state`}
            />
          </Form.Item>
          <Form.Item
            label="ZIP / Postal Code"
            name="code"
            rules={isShipping || isCompleteRegistrationForm ? required : undefined}
          >
            <Input
              placeholder="Type here"
              disabled={isDisabledField}
              className={`${subClassName}-code`}
            />
          </Form.Item>
        </Col>
      </Row>
      {editMode && (
        <ControlsEditMode
          isEditing={isEditing}
          onCancel={handleCancel}
          onChangeEditing={setIsEditing}
          onSubmit={handleSubmit}
          isFormValid={typeof editMode.isFormValid !== 'undefined' ? editMode.isFormValid : true}
          loading={editMode.loading}
        />
      )}
    </div>
  );
};

Address.defaultProps = {
  form: undefined,
  hasName: false,
  disabled: false,
  editMode: undefined,
  isEditingOpen: false,
  isShipping: false,
  requiredFieldsType: 'create',
  subClassName: 'billing',
};

export default Address;
