import React, { useEffect, useState } from 'react';
import { Button, Col, Form, message, Row, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import DrawerModal from '../../../Common/DrawerModal';
import { RootState } from '../../../../store/reducers';
import {
  moduleName as patientModuleName,
  CreatePatient,
  createPatient as actionCreatePatient,
} from '../../../../store/ducks/patient';
import { moduleName as authModuleName, User } from '../../../../store/ducks/auth';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import { initialCountryData, preparePatientBody } from '../../../../utils/prepareData';
import PatientFormContent from '../Forms/PatientsFormContent';
import { DefaultModalProps } from '../../../../types';

interface PatientCreateDrawer extends DefaultModalProps {
  // redux props \/
  createPatient: (payload: CreatePatient) => Action;
  patientLoading: boolean;
  patientError: Error | null;
  user: User | null;
}

const PatientCreateDrawer: React.FC<PatientCreateDrawer> = (
  { isOpen, close, onReload, createPatient, patientLoading, patientError, user },
) => {
  const [form] = Form.useForm();

  const requestDoctorId = user?.role === 'doctor' ? user?.doctor?.id : undefined;

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = preparePatientBody(values);

      createPatient(body);
    });
  };

  useEffect(() => {
    if (patientError?.message) {
      message.error(patientError.message, 5);
    }
  }, [patientError]);

  useEffect(() => {
    if (!patientLoading && !patientError && isOpen) {
      close();
      onReload?.();
      message.success('Patient has been created!');
    }
  }, [patientLoading]);

  return (
    <DrawerModal
      title="Create a new Patient"
      open={isOpen}
      zIndex={1001}
      onClose={close}
      extra={(
        <Space>
          <Button
            id="submitBtn"
            type="primary"
            icon={<CheckOutlined />}
            disabled={!isFormValid}
            onClick={handleSubmit}
            loading={patientLoading}
          >
            Create
          </Button>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => form.resetFields()}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="patient_create"
              layout="vertical"
              autoComplete="off"
              initialValues={{ doctor: requestDoctorId || undefined, country: initialCountryData }}
              onFieldsChange={() => setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length))}
            >
              <PatientFormContent
                form={form}
                formType="create"
                modalDoctorId={requestDoctorId}
                cardLoading={patientLoading}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
};

PatientCreateDrawer.displayName = 'PatientCreateDrawer';

PatientCreateDrawer.defaultProps = {
  onReload: undefined,
};

export default connect((state: RootState) => ({
  patientLoading: state[patientModuleName].patientLoading,
  patientError: state[patientModuleName].patientError,
  user: state[authModuleName].user,
}), {
  createPatient: actionCreatePatient,
})(PatientCreateDrawer);
