import React from 'react';
import { Layout } from 'antd';

import Content from '../../components/Pages/Reports';
import { displayName } from '../../config';

function Reports(): JSX.Element {
  document.title = `${displayName}: Reporting`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default Reports;
