import { Navigate, Routes } from 'react-router-dom';
import { Route as PublicRoute } from 'react-router';

import { connect } from 'react-redux';
import React from 'react';
import Auth from '../pages/auth';
import { routes } from '../components/Layout/Main/Sider/Menu';
import MainLayout from '../components/Layout/Main';
import Profile from '../pages/users/profile';
import Notifications from '../pages/notifications';
import { createChildrenRoutes, Route } from './index';
import { RootState } from '../store/reducers';
import { moduleName, User } from '../store/ducks/auth';
import { getRedirectRoutePath, isLoginPermitted } from '../utils/auth';

const beforeCreateChildrenRoutes: Route[] = [
  ...routes,
  {
    bind: {
      element: <Profile />,
      path: '/profile',
    },
    name: 'Profile',
  },
  {
    bind: {
      path: '/notifications',
      element: <Notifications />,
    },
    name: 'Notifications',
    privateRoute: true,
  },
];

const menuRoutes = createChildrenRoutes(beforeCreateChildrenRoutes);

interface IMain {
  authorized: boolean;
  user: User | null;
  roleList: string[] | null;
}

const Main: React.FC<IMain> = ({ authorized, user, roleList }) => {
  const filteredMenuRoutes = roleList ? menuRoutes.filter((route) => (
    Array.isArray(route.roleNeeded) ? route.roleNeeded.includes(user?.role || 'patient')
      : roleList?.indexOf(user?.role || 'patient') >= roleList?.indexOf(route?.roleNeeded || 'patient')
  )) : menuRoutes;

  const availableRoutes = isLoginPermitted(user) ? filteredMenuRoutes : [];

  return (
    <MainLayout>
      <Routes>
        {availableRoutes.map(({ privateRoute, bind: { element, ...bind } }) => (
          <PublicRoute
            key={`${bind.path}`}
            {...bind}
            element={(privateRoute && authorized) || !privateRoute ? element : <Auth />}
          />
        ))}
        <PublicRoute path="*" element={<Navigate to={getRedirectRoutePath(user)} replace />} />
      </Routes>
    </MainLayout>
  );
};

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  user: state[moduleName].user,
  roleList: state[moduleName].roleList,
}))(Main);
