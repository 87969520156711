import React, { useEffect, useState } from 'react';
import { ProColumns } from '@ant-design/pro-table';
import moment from 'moment';
import { Space, Typography } from 'antd';
import { TableLogRow, useLogId } from '../../../../hooks/loggers';
import Table from '../../Table';
import { Case } from '../../../../hooks/cases';
import { CaseStatus, CaseStatuses } from '../../../../enums/case';
import Status from '../../Status';
import Loading from '../../Loading';
import { LogCaseAdditionalData } from '../index';

interface ExpandedRowItem {
  row: TableLogRow | undefined;
  caseAdditionalData?: LogCaseAdditionalData;
}

const ExpandedRowItem: React.FC<ExpandedRowItem> = ({ row, caseAdditionalData }) => {
  /** Adding load log by id with interface of Case in 'before' and 'after' */
  const logById = useLogId<Case>();

  const fetchLogById = (labId: string) => {
    if (!labId) return;
    logById.fetch(undefined, labId);
  };

  useEffect(() => {
    if (row?.id) {
      fetchLogById(`${row.id}`);
    }
  }, [row?.id]);

  const [tableLogsData, setTableLogsData] = useState<Case[]>([]);

  useEffect(() => {
    if (logById.data && logById.data.before && logById.data.after) {
      // then show in table this data with dataSource={tableLogsData}
      setTableLogsData([{ rowName: 'Before', ...logById.data.before }, { rowName: 'After', ...logById.data.after }]);
    }
  }, [logById.data]);

  const columns: ProColumns<Case>[] = [
    {
      key: 'rowName',
      title: '',
      dataIndex: 'rowName',
      hideInSetting: true,
    },
    // {
    //   key: 'id',
    //   title: 'ID',
    //   dataIndex: 'id',
    //   hideInSetting: true,
    // },
    {
      key: 'services',
      title: 'Selected Services',
      dataIndex: 'teeth2',
      renderText: (_, row) => (
        <Space direction="vertical">
          {caseAdditionalData?.servicesData?.filter((priceService) => (
            row?.teeth?.services?.find((serv) => serv.id === priceService.id)))
            ?.map((priceService) => (
              <Typography key={priceService?.id}>{priceService?.name}</Typography>
            ))}
        </Space>
      ),
      hideInSetting: true,
      ellipsis: true,
    },
    {
      key: 'teeth',
      title: 'Teeth length',
      dataIndex: 'teeth',
      renderText: (_, row) => (
        <Space direction="vertical">
          <Typography>
            {row?.teeth?.teeth?.filter((tooth) => (
              tooth.materials?.includes(caseAdditionalData?.materialImplantId || '')))?.length}
            {' '}
            Implants
          </Typography>
          <Typography>
            {row?.teeth?.teeth?.filter((tooth) => (
              tooth.materials?.includes(caseAdditionalData?.materialCrownId || '')))?.length}
            {' '}
            Crowns
          </Typography>
          <Typography>
            {row?.teeth?.teeth?.filter((tooth) => (
              !tooth?.materials || tooth?.materials?.length === 0))?.length}
            {' '}
            Other
          </Typography>
          <Typography>
            {row?.teeth?.teeth?.length}
            {' '}
            Total
          </Typography>
        </Space>
      ),
      hideInSetting: true,
      ellipsis: true,
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      renderText: (status: CaseStatus) => (
        <Status status={CaseStatuses[status.toLowerCase() as CaseStatus]} />
      ),
      hideInSetting: true,
    },
    {
      key: 'totalPrice',
      title: 'Total Price',
      dataIndex: 'totalPrice',
      renderText: (totalPrice) => totalPrice ? `$${totalPrice}` : '',
      hideInSetting: true,
    },
    {
      key: 'note',
      title: 'Note',
      dataIndex: 'note',
      renderText: (note) => note,
      hideInTable: logById.data?.before?.note === logById.data?.after?.note,
      hideInSetting: true,
    },
    {
      key: 'updatedAt',
      title: 'Updated at',
      dataIndex: 'updatedAt',
      renderText: (updatedAt) => moment(updatedAt).format('MMM DD, YYYY hh:mm A'),
      hideInSetting: true,
    },
  ];

  return (
    <div>
      <Loading visible={logById.loading} size="default" />
      <div>
        <Typography style={{ marginLeft: '24px' }}>
          {logById.data?.event === 'create' && !logById.data?.description && (
            <>
              <Typography.Text type="secondary">Description: </Typography.Text>
              <Typography.Text code>
                {`Case created by ${logById.data?.user?.firstName || '-'} ${logById.data?.user?.lastName}`}
              </Typography.Text>
            </>
          )}
          {logById.data?.description && (
            <>
              <Typography.Text type="secondary">Description: </Typography.Text>
              <Typography.Text code>{logById.data?.description}</Typography.Text>
            </>
          )}
        </Typography>
        {logById.data?.event === 'update' && logById.data.action === '' && tableLogsData?.length && (
          <Table<Case>
            loading={logById.loading}
            columns={columns}
            dataSource={tableLogsData}
            toolBarRender={false}
            headerTitle={false}
            options={false}
            pagination={false}
            showSorterTooltip={false}
            search={false}
            style={{ padding: '24px' }}
          />
        )}
      </div>
    </div>
  );
};

ExpandedRowItem.defaultProps = {
  caseAdditionalData: undefined,
};

export default ExpandedRowItem;
