import React from 'react';
import clsx from 'clsx';

import moment, { Moment } from 'moment';
import styles from './index.module.less';

interface DateColorStatus {
  date?: string | Moment;
  format?: string;
  beforeContent?: React.ReactNode;
}

const DateColorStatus: React.FC<DateColorStatus> = ({ date, format, beforeContent }) => (
  <div className={clsx(styles.date, {
    // [styles.green]: moment(date).diff(moment(), 'hours') >= 24,
    [styles.green]: moment(date).diff(moment(), 'days') >= 1,
    [styles.yellow]: moment(date).diff(moment(), 'days') < 1,
    [styles.red]: moment(date).diff(moment(), 'days') < 0,
  })}
  >
    {beforeContent && `${beforeContent} `}
    {moment(date)?.format(format)}
  </div>
);

DateColorStatus.defaultProps = {
  date: '',
  format: 'DD MMM, YYYY',
  beforeContent: undefined,
};

export default DateColorStatus;
