import { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Row, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { InfoCircleOutlined } from '@ant-design/icons';
import { JsonResult } from '../../../../../types';
import ContentCard from '../../../../Common/ContentCard';
import { findToothByKey, getArrayWithBridgesArrays, numberFromToothIconKey } from '../../../../../utils/teeth';
import RestorativePlanStepForm from './RestorativePlanStepForm';
import { useContextCaseFlow } from '../../../../../context/caseFlow';
import { Tooth } from '../../../../../hooks/cases';

import styles from './index.module.less';

/** Header rendering component */
export interface HeadingContentRow {
  items: {
    icon: React.ReactNode;
    label: string;
    onClick?: () => void;
    isActive?: boolean;
    disabled?: boolean;
    disabledText?: string;
  }[];
  infoTooltipContent?: React.ReactNode;
}

export const HeadingContentRow = ({ items, infoTooltipContent }: HeadingContentRow) => (
  <Row justify="center" gutter={[24, 8]} style={{ marginBottom: '20px' }}>
    {items.map(({ icon, label, onClick, isActive, disabled, disabledText }) => (
      <Tooltip title={disabled && disabledText ? disabledText : ''} key={label}>
        <div style={disabled ? { cursor: 'not-allowed' } : undefined}>
          <Col
            key={label}
            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            className={clsx({
              [styles.headingContentCol]: !!onClick,
              [styles.activeCol]: isActive,
              [styles.disabledCol]: disabled,
            })}
            onClick={onClick}
          >
            {icon}
            <span className={clsx({ [styles.disabledRowLabel]: disabled })}>
              {label}
            </span>
          </Col>
        </div>
      </Tooltip>
    ))}
    {infoTooltipContent && (
      <Col style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Tooltip
          placement="bottom"
          title={infoTooltipContent}
          overlayStyle={{ maxWidth: '300px' }}
        >
          <InfoCircleOutlined style={{ fontSize: '16px' }} />
        </Tooltip>
      </Col>
    )}
  </Row>
);

HeadingContentRow.defaultProps = { infoTooltipContent: undefined };

/** Teeth Treatment Plan Form Component */

interface TeethTreatmentPlanForm {
  editMode?: boolean;
  isAdmin?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
  selectTreatmentPlanForm?: FormInstance;
  teethErrors?: string[];
  setTeethErrors?: React.Dispatch<React.SetStateAction<string[]>>;
}

const TeethTreatmentPlanForm: React.FC<TeethTreatmentPlanForm> = (props) => {
  const {
    onSubmit,
    children,
    form,
    selectTreatmentPlanForm,
    teethErrors,
    setTeethErrors,
  } = props;
  const {
    materialCrownId, materialImplantId, teethValues, setTeethValues, foundSurgicalService, foundRestorativeService,
    setHistoryTeethValues, historyTeethValues, setFutureTeethValues, futureTeethValues,
  } = useContextCaseFlow();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
  };

  const servicesArrValues: string[] = Form.useWatch('services', selectTreatmentPlanForm);

  const isServiceSelected = (serviceId: string | undefined) => !!servicesArrValues?.includes(serviceId || '');

  const defaultToothObj: Tooth = {
    index: 0,
    isMissing: false,
    toBeExtracted: false,
    materials: [],
    isBridge: false,
  };

  const [isUndoLoading, setIsUndoLoading] = useState<boolean>(false);

  const setNewHistory = () => {
    setHistoryTeethValues((oldHistory) => [...oldHistory, teethValues]);
    setFutureTeethValues([]);
  };

  /** Update history while teeth values don't update for some time */
  useEffect(() => {
    if (teethValues && !isUndoLoading) {
      const timeoutId = setTimeout(setNewHistory, 150);

      return () => clearTimeout(timeoutId);
    }

    setIsUndoLoading(false);

    return undefined;
  }, [teethValues]);

  const handleUndo = () => {
    if (historyTeethValues.length > 1) {
      setIsUndoLoading(true);
      const newHistory = historyTeethValues.slice(0, historyTeethValues.length - 1);

      setTeethValues(newHistory[newHistory.length - 1]);
      setHistoryTeethValues(newHistory);
      const newFuture = [...futureTeethValues, teethValues];

      setFutureTeethValues(newFuture);
    }
  };

  const handleRedo = () => {
    if (futureTeethValues.length > 0) {
      setIsUndoLoading(true);
      const newTeethValues = futureTeethValues[futureTeethValues.length - 1];
      const newFuture = futureTeethValues.slice(0, futureTeethValues.length - 1);
      const newHistory = [...historyTeethValues, newTeethValues];

      setTeethValues(newTeethValues || []);
      setHistoryTeethValues(newHistory);
      setFutureTeethValues(newFuture);
    }
  };

  /** Add event listeners on keys "CTRL + z" and for "CTRL + Shift + Z" to call Undo/Redo  */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && !event.shiftKey && event.code === 'KeyZ') {
        event.preventDefault();
        handleUndo();
      }

      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.code === 'KeyZ') {
        event.preventDefault();
        handleRedo();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo, historyTeethValues]);

  const handleUncheckTooth = (key: string) => {
    setTeethValues((prevTeeth) => prevTeeth.filter((tooth) => tooth.index
      !== numberFromToothIconKey(key)));
  };

  const handleRemoveMaterialsTooth = (key: string, removeMaterialId?: string, isShift?: boolean) => {
    if (!isShift && teethValues.find((tooth) => (
      tooth.index === numberFromToothIconKey(key) && tooth.isBridge
      && removeMaterialId === materialCrownId // remove this line, if need to delete bridge not only with crowns
    ))) {
      handleRemoveBridge(key);
    }

    setTeethValues((prevTeeth) => prevTeeth.map((tooth) => {
      if (tooth.index === numberFromToothIconKey(key)) {
        return {
          ...tooth,
          isBridge: !isShift ? tooth.isBridge : false,
          materials: removeMaterialId ? tooth?.materials?.filter((material) => material !== removeMaterialId) : [],
        };
      }

      return tooth;
    })
      .filter((tooth) => (
        tooth.isMissing || tooth.toBeExtracted || tooth.materials?.length || tooth.isBridge
      )));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddToBeExtracted = (key: string) => {
    setTeethValues((oldTeeth) => (
      [...oldTeeth, {
        ...defaultToothObj,
        index: numberFromToothIconKey(key),
        toBeExtracted: true,
      }]));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddMissingTooth = (key: string) => {
    setTeethValues((oldTeeth) => (
      [...oldTeeth, {
        ...defaultToothObj,
        index: numberFromToothIconKey(key),
        isMissing: true,
      }]));
  };

  const handleAddCrownTooth = (key: string) => {
    setTeethValues((oldTeeth) => {
      const foundTooth = findToothByKey(key, oldTeeth);

      /** If both services selected */
      const materialsArr = isServiceSelected(foundSurgicalService?.id) && isServiceSelected(foundRestorativeService?.id)
        && foundTooth?.materials
        ? [...foundTooth.materials.filter((materialId) => materialId !== materialCrownId), materialCrownId]
        : [materialCrownId];

      if (foundTooth) {
        return oldTeeth.map((tooth) => {
          if (tooth.index === numberFromToothIconKey(key)) {
            return {
              ...tooth,
              isMissing: tooth.toBeExtracted ? tooth.isMissing : false,
              materials: materialsArr,
            };
          }

          return tooth;
        });
      }

      return [...oldTeeth, {
        ...defaultToothObj,
        index: numberFromToothIconKey(key),
        isMissing: false,
        materials: materialsArr,
      }];
    });
  };

  const handleAddImplantTooth = (key: string) => {
    setTeethValues((oldTeeth) => {
      const foundTooth = findToothByKey(key, oldTeeth);
      /** If both services selected */
      const materialsArr = isServiceSelected(foundSurgicalService?.id) && foundTooth?.materials
        ? [...foundTooth.materials.filter((materialId) => materialId !== materialImplantId), materialImplantId]
        : [materialImplantId];

      /** if tooth with this key already selected in step 1, then we update this object, if not - create new one */
      if (foundTooth) {
        return oldTeeth.map((tooth) => {
          if (tooth.index === numberFromToothIconKey(key)) {
            return {
              ...tooth,
              /** If tooth already was selected in step 1, then need to check is it toBeExtracted or not */
              isMissing: tooth.toBeExtracted ? tooth.isMissing : false,
              materials: materialsArr,
            };
          }

          return tooth;
        });
      }

      return [...oldTeeth, {
        ...defaultToothObj,
        index: numberFromToothIconKey(key),
        isMissing: false,
        materials: materialsArr,
      }];
    });
  };

  const handleAddBridgeTooth = (key: string) => {
    setTeethValues((oldTeeth) => {
      const foundTooth = findToothByKey(key, oldTeeth);

      /** if tooth with this key already selected in step 1, then we update this object, if not - create new one */
      if (foundTooth) {
        return oldTeeth.map((tooth) => {
          if (tooth.index === numberFromToothIconKey(key)) return { ...tooth, isBridge: true };

          return tooth;
        });
      }

      return [...oldTeeth, {
        ...defaultToothObj,
        index: numberFromToothIconKey(key),
        isBridge: true,
      }];
    });
  };

  const handleAddBridgeBetweenTooth = (fromKey: string, toKey: string) => {
    setTeethValues((oldTeeth) => {
      const newTeethArray = [...oldTeeth];
      const [fromToothIndex, toToothIndex] = [numberFromToothIconKey(fromKey), numberFromToothIconKey(toKey)]
        .sort((a, b) => a > b ? 1 : -1);

      for (let i = fromToothIndex; i <= toToothIndex; i++) {
        const foundTooth = findToothByKey(`Tooth${i}`, newTeethArray);

        if (!foundTooth) {
          newTeethArray.push({
            ...defaultToothObj,
            index: i,
            isBridge: true,
          });
        } else {
          const toothArrIndex = newTeethArray.indexOf(foundTooth);

          newTeethArray[toothArrIndex] = {
            ...foundTooth,
            isBridge: true,
          };
        }
      }

      return newTeethArray;
    });
  };

  const handleRemoveBridge = (key: string) => {
    let newTeethArray: Tooth[] = [...teethValues];

    let prevUsedToothIndexRight = numberFromToothIconKey(key);
    let prevUsedToothIndexLeft = numberFromToothIconKey(key) - 1;

    /** Sort from 0 to 32 */
    newTeethArray = newTeethArray.sort((a, b) => a.index > b.index ? 1 : -1).map((tooth) => {
      if (tooth.index === prevUsedToothIndexRight && tooth.isBridge) {
        /** Handle remove only bridge from current teeth row. */
        if ((numberFromToothIconKey(key) <= 16 && tooth.index <= 16)
          || (numberFromToothIconKey(key) >= 17 && tooth.index >= 17)) {
          prevUsedToothIndexRight += 1;

          return {
            ...tooth,
            isBridge: false,
          };
        }
      }

      return tooth;
    });

    /** Sort from 32 to 0 */
    newTeethArray = newTeethArray.sort((a, b) => a.index < b.index ? 1 : -1).map((tooth) => {
      if (prevUsedToothIndexLeft === tooth.index && tooth.isBridge) {
        /** Handle remove only bridge from current teeth row. */
        if ((numberFromToothIconKey(key) <= 16 && tooth.index <= 16)
          || (numberFromToothIconKey(key) >= 17 && tooth.index >= 17)) {
          prevUsedToothIndexLeft -= 1;

          return {
            ...tooth,
            isBridge: false,
          };
        }
      }

      return tooth;
    });

    /** Clear tooth, which was unselected, and now they not bridge and don't have any material */
    setTeethValues(newTeethArray.filter((tooth) => (
      tooth.isMissing || tooth.toBeExtracted || tooth.materials?.length || tooth.isBridge
    )));
  };

  return (
    <div>
      {teethErrors && teethErrors.map((error) => (
        <div key={`${error}-${Date.now()}`} style={{ marginBottom: '10px' }}>
          <Alert
            type="error"
            onClose={() => setTeethErrors?.((prevErrors) => prevErrors.filter((err) => err !== error))}
            message={error}
            closable
            showIcon
          />
        </div>
      ))}
      <Typography.Title level={4}>Treatment plan</Typography.Title>
      <ContentCard paddingSize="small" toothMapView>
        <RestorativePlanStepForm
          teethValues={teethValues}
          setTeethTreatmentPlan={setTeethValues}
          form={form}
          servicesArrValues={servicesArrValues}
          isServiceSelected={isServiceSelected}
          // Add functions
          uncheckTooth={handleUncheckTooth}
          removeMaterials={handleRemoveMaterialsTooth}
          addCrownTooth={handleAddCrownTooth}
          addImplantTooth={handleAddImplantTooth}
          addBridgeTooth={handleAddBridgeTooth}
          addBridgeBetweenTooth={handleAddBridgeBetweenTooth}
          removeBridge={handleRemoveBridge}
          // Undo/Redo functions
          handleUndo={handleUndo}
          handleRedo={handleRedo}
        />
        {children}
      </ContentCard>
    </div>
  );
};

TeethTreatmentPlanForm.defaultProps = {
  selectTreatmentPlanForm: undefined,
  teethErrors: [],
  setTeethErrors: undefined,
  editMode: false,
  isAdmin: false,
  disabled: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  children: null,
};

export default TeethTreatmentPlanForm;
