import { PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React from 'react';
import { AdditionalOptionsTypes } from '../../../../../enums/services';
import { JsonResult } from '../../../../../types';
import PriceRow from '../PriceRow';

interface Options {
  isEditing?: boolean;
  name: number;
  restField: {
    fieldKey?: number;
  };
  prices?: JsonResult[];
  materialsView?: boolean;
}

const Prices = (props: Options) => {
  const { isEditing, name: fieldName, restField, prices, materialsView } = props;

  const getMaxCount = () => (
    [...prices || []]
      .sort((a: JsonResult, b: JsonResult) => +a.maxRange < +b.maxRange ? 1 : -1)[0]
      ?.maxRange as number || 1
  );

  return (
    <Form.List name={[fieldName, 'prices']} {...restField}>
      {(fields, { add, remove }) => (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          {fields.map(({ key, name, ...rest }) => (
            <Form.Item
              key={key}
              shouldUpdate
              className="no-space-form-item"
            >
              {() => (
                <PriceRow
                  name={name}
                  restField={rest}
                  isEditing={isEditing}
                  remove={remove}
                  prices={prices}
                  materialsView={materialsView}
                />
              )}
            </Form.Item>
          ))}
          {isEditing && (
            <div style={{ width: '130px', display: 'flex', alignItems: 'flex-end', alignSelf: 'flex-end' }}>
              <Form.Item className="no-space-form-item">
                <Button
                  type="text"
                  onClick={() => add({
                    minRange: getMaxCount() + 1,
                    maxRange: getMaxCount() + 1,
                    type: AdditionalOptionsTypes.perTooth,
                  })}
                  icon={<PlusOutlined />}
                >
                  Add Price
                </Button>
              </Form.Item>
            </div>
          )}
        </div>
      )}

    </Form.List>
  );
};

Prices.defaultProps = {
  isEditing: false,
  prices: undefined,
  materialsView: false,
};

export default Prices;
