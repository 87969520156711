import { SettingFilled } from '@ant-design/icons';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, FormInstance, Space, message } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PracticeStatuses } from '../../../../enums/practice';
import { getMessageInError } from '../../../../hooks/fetch';
import { PriceLevelsTableRow, useTablePriceLevelsRow } from '../../../../hooks/priceLevels';
import { ModalState, ModalTypes } from '../../../../types';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { isRoleEnough } from '../../../../utils/auth';
import { PriceChange } from '../../../Common/Icon';

import Status from '../../../Common/Status';
import Table from '../../../Common/Table';

interface TablePriceLevels {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
}

const TablePriceLevels = (props: TablePriceLevels): JSX.Element => {
  const { params, openModal, selectedRows, onRowSelection } = props;
  const formRef = useRef<FormInstance>();
  const priceLevelsGet = useTablePriceLevelsRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (priceLevelsGet.error) {
      message.error(getMessageInError(priceLevelsGet.error));
      priceLevelsGet.clearError();
    }
  }, [priceLevelsGet.error]);

  const onReload = () => actionRef.current?.reload();

  const toolBarRender = () => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    openModal ? (
      <Button
        type="primary"
        onClick={() => {
          openModal?.({ type: ModalTypes.create, key: 'priceLevel', onReload });
        }}
        id="addPriceLevelButton"
      >
        <Space>
          <PlusOutlined />
          Add New Price Level
        </Space>
      </Button>
    ) : null,
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<PriceLevelsTableRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return priceLevelsGet.fetch({
      ...newParams,
      ...params,
    }).then((data) => {
      if (data) {
        const { priceLevels, total } = data;

        return ({ data: priceLevels || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const columns: ProColumns<PriceLevelsTableRow>[] = [
    {
      title: 'Level Name',
      dataIndex: 'name',
      ellipsis: true,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      sorter: true,
      valueEnum: {
        true: 'Active',
        false: 'Deactivated',
      },
      renderText: (isActive) => (
        <Status status={isActive ? PracticeStatuses.active : PracticeStatuses.deactivated} />
      ),
    },
    {
      title: '',
      align: 'center',
      hideInSearch: true,
      hideInDescriptions: true,
      hideInForm: true,
      hideInSetting: true,
      width: 60,
      renderText: (_, row) => (
        <Button type="text" onClick={() => handlePriceLevelEdit(row.id)}>
          <SettingFilled />
        </Button>
      ),
    },
  ];

  const handlePriceLevelEdit = (id: string) => {
    openModal?.({ type: ModalTypes.update, key: 'priceLevel', id, onReload });
  };

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<PriceLevelsTableRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      headerTitle={(
        <>
          <PriceChange />
          Price Level
        </>
      )}
      rowSelection={!!onRowSelection && !!isRoleEnough('admin') && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-price-levels', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => { handlePriceLevelEdit(record.id); },
      })}
    />
  );
};

TablePriceLevels.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
};

export default TablePriceLevels;
