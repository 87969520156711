import { SaveFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Space, Switch, Tabs, Typography } from 'antd';
import Tooltip from 'antd/es/tooltip';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FieldData } from 'rc-field-form/lib/interface';
import { PracticeStatuses } from '../../../../enums/practice';
import { CreatePriceLevelService, usePriceLevelId } from '../../../../hooks/priceLevels';
import { useServicesGet } from '../../../../hooks/services';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import {
  moduleName,
  priceLevelUpdate as actionPriceLevelUpdate,
  priceLevelCreate as actionPriceLevelCreate,
  PriceLevelPayload,
} from '../../../../store/ducks/priceLevel';
import { RootState } from '../../../../store/reducers';
import { JsonResult } from '../../../../types';
import { capitalize, isObjEqualLodash } from '../../../../utils';
import { required } from '../../../../utils/inputRules';
import {
  preparePriceLevelServiceInitialData,
  preparePriceLevelServicesBody,
} from '../../../../utils/prepareData';
import ContentCard from '../../../Common/ContentCard';
import DrawerModal from '../../../Common/DrawerModal';
import Loading from '../../../Common/Loading';
import LabServiceView from '../../Labs/Forms/LabServiceView';
import AssignedPracticesTable from '../AssignedPracticesTable';

interface PriceLevelDrawer {
  practiceId?: string;
  priceLevelId?: string | number;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  onReload?: (id?: string) => void;
  priceLevel: JsonResult | null;
  loading: boolean;
  error: Error | null;
  priceLevelUpdate: (payload: PriceLevelPayload) => Action;
  priceLevelCreate: (payload: PriceLevelPayload) => Action;
}

const PriceLevelDrawer = (props: PriceLevelDrawer) => {
  const {
    priceLevel,
    priceLevelId,
    drawerOpen,
    setDrawerOpen,
    onReload,
    loading,
    error,
    priceLevelUpdate,
    priceLevelCreate,
  } = props;
  const priceLevelById = usePriceLevelId();
  const servicesGet = useServicesGet();

  const [form] = Form.useForm();
  const [serviceForm] = Form.useForm();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [isPriceLevelLoaded, setIsPriceLevelLoaded] = useState<boolean>(false);
  const [services, setServices] = useState<CreatePriceLevelService[] | undefined>();
  const [activeService, setActiveService] = useState<CreatePriceLevelService>();
  const [activeKey, setActiveKey] = useState<string>('0');
  const isEditingState = useState<boolean>(false);
  const [isEditing, setIsEditing] = isEditingState;

  /** Needed for 'exit confirmation modal' */
  const [fieldsValue, setFieldsValue] = useState();
  const [fieldChangedValue, setFieldChangedValue] = useState<FieldData[]>([]);

  const resetFormsTouchedState = () => {
    setIsFormTouched(false);
    setFieldsValue(undefined);
    setFieldChangedValue([]);
  };

  const initialValues = {
    name: priceLevelById.data?.name,
    isActive: priceLevelById.data?.isActive,
  };

  useEffect(() => {
    if (!drawerOpen) return;
    setIsEditing(false);

    priceLevelById.fetch(undefined, priceLevelId);
    servicesGet.fetch({ status: 'active' });
  }, [drawerOpen]);

  useEffect(() => {
    if (!drawerOpen) return;
    if (!servicesGet.data?.data || !priceLevelById.data) return;

    setServices(
      preparePriceLevelServiceInitialData(
        servicesGet.data?.data,
        priceLevelById.data?.priceLevelServices,
        !priceLevelId,
      ),
    );
  }, [priceLevelById.data, servicesGet.data]);

  useEffect(() => {
    if (!loading && !error && drawerOpen) {
      onReload?.(priceLevel?.id);
      message.success(`Price level has been ${priceLevelId ? 'updated' : 'created'}!`);
      if (!priceLevelId) {
        handleClose();
      }
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      message.error(error.message, 5);
    }
  }, [error]);

  useEffect(() => {
    if (!services) return;

    setActiveService(services[+activeKey]);
  }, [services]);

  useEffect(() => {
    if (!activeService || isPriceLevelLoaded) return;

    setIsPriceLevelLoaded(true);
  }, [activeService]);

  useEffect(() => {
    if (!drawerOpen || loading || error) return;

    priceLevelById.fetch(undefined, priceLevelId);
  }, [loading]);

  useEffect(() => {
    if (!drawerOpen || loading || error) return;

    if (priceLevelById?.data?.isActive) {
      setIsActive(priceLevelById.data.isActive);
    }

    form.setFieldsValue(initialValues);
    /** After update initial form values setFormNotTouched  */
    resetFormsTouchedState();
  }, [priceLevelById.data]);

  const handleStatusChange = (checked: boolean) => {
    setIsActive(checked);
  };

  const updateService = (
    values: CreatePriceLevelService,
    callback?: (newServices: CreatePriceLevelService[]) => void,
  ) => {
    if (!services) return;

    const newServices = [...services];

    newServices.splice(+activeKey, 1, values);

    if (callback) {
      callback(newServices);
    } else {
      setIsEditing(false);
      setServices(newServices);
    }
  };

  const handleServiceSubmit = () => {
    serviceForm.validateFields()
      .then((values: CreatePriceLevelService) => {
        updateService(values, (newServices) => {
          form.validateFields().then((formValues: JsonResult) => {
            priceLevelUpdate({
              id: priceLevelId,
              ...formValues,
              isActive,
              priceLevelServices: preparePriceLevelServicesBody(newServices),
            });
            setIsEditing(false);
            setServices(newServices);
          });
        });
      })
      .catch((error) => {
        if (error.errorFields.some((field: JsonResult) => field.name.includes('minRange')
          || field.name.includes('maxRange'))) {
          message.error('Tooth ranges must not overlap.', 5);
        }
        if (error.errorFields.some((field: JsonResult) => field.name.includes('price'))) {
          message.error('Price is required', 5);
        }
      });
  };

  const handleTabsChange = (key: string) => {
    if (isEditing) return;
    serviceForm.validateFields().then((values: CreatePriceLevelService) => {
      updateService(values);
      setActiveService(services?.filter((service) => service.service)[+key]);
      setActiveKey(key);
    });
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setActiveService(undefined);
    setServices(undefined);
    setIsPriceLevelLoaded(false);
    resetFormsTouchedState();
  };

  const handleFieldsChange = () => {
    setIsFormTouched(!isObjEqualLodash(initialValues, { ...form.getFieldsValue(), isActive }));
  };

  const handleSubmit = () => {
    serviceForm.validateFields()
      .then((values: CreatePriceLevelService) => {
        updateService(values, (newServices) => {
          form.validateFields().then((formValues: JsonResult) => {
            priceLevelCreate({
              ...formValues,
              isActive,
              priceLevelServices: preparePriceLevelServicesBody(newServices),
            });
            setServices(newServices);
          });
        });
        resetFormsTouchedState();
      })
      .catch((error) => {
        if (error.errorFields.some((field: JsonResult) => field.name.includes('minRange')
          || field.name.includes('maxRange'))) {
          message.error('Tooth ranges must not overlap.', 5);
        }
        if (error.errorFields.some((field: JsonResult) => field.name.includes('price'))) {
          message.error('Price is required', 5);
        }
      });
  };

  /** When any form field changes setFieldsValue called with a delay to not overload the CPU with the .getFieldsValue */
  useEffect(() => {
    if (fieldChangedValue) {
      const id = setTimeout(() => {
        setFieldsValue(serviceForm.getFieldsValue());
      }, 350);

      return () => clearTimeout(id);
    }

    return undefined;
  }, [fieldChangedValue]);

  return (
    <div>
      <DrawerModal
        title={priceLevelId ? `Price Level ${priceLevelById?.data?.name}` : 'Add New Price Level'}
        open={drawerOpen && isPriceLevelLoaded}
        onClose={handleClose}
        isFormChanged={isFormTouched}
        checkIsObjEqual
        isObjEqualFormProps={{ obj1: activeService, obj2: fieldsValue }}
        destroyOnClose
        extra={!priceLevelId ? (
          <Space>
            <Button
              id="priceLevelCancelButton"
              type="ghost"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              id="savePriceLevelButton"
              type="primary"
              onClick={handleSubmit}
              icon={<SaveFilled />}
              loading={loading}
            >
              Save Price Level
            </Button>
          </Space>
        ) : undefined}
      >
        <Row justify="center">
          <Col span={24} md={22} lg={21} xxl={17}>
            <Typography.Title level={4}>Services</Typography.Title>
            <ContentCard>
              <Space direction="vertical">
                <Form
                  form={form}
                  layout="inline"
                  initialValues={initialValues}
                  onFieldsChange={handleFieldsChange}
                >
                  <Space className="service-row" style={{ width: '100%' }}>
                    <Form.Item name="name" rules={required}>
                      <Input placeholder="Type Here" disabled={!!priceLevelId && !isEditing} />
                    </Form.Item>
                    <div className="switch-wrapper">
                      <Typography className="label">
                        {`Status ${capitalize(isActive
                          ? PracticeStatuses.active
                          : PracticeStatuses.deactivated)}`}
                      </Typography>
                      <Tooltip
                        title={priceLevelById.data?.practices?.length
                          ? 'Impossible to deactivate. There are assigned practices to this price level.'
                          : ''}
                        placement="top"
                      >
                        <Switch
                          checked={isActive}
                          onChange={handleStatusChange}
                          disabled={!!priceLevelById.data?.practices?.length || (!!priceLevelId && !isEditing)}
                        />
                      </Tooltip>
                    </div>
                  </Space>
                </Form>
                <Typography.Title level={5}>
                  Select Service:
                </Typography.Title>
                <Typography>
                  Please define prices for each service and additional option. If the price is not defined, the
                  additional option will be free and will not be included in the calculation of the total price of
                  the case.
                </Typography>
                <Tabs
                  key="labServicesTabs"
                  activeKey={activeKey}
                  onChange={handleTabsChange}
                  items={services?.map((service, index) => ({
                    label: service?.name,
                    key: index.toString(),
                    children: (
                      <LabServiceView
                        key={service.id}
                        form={serviceForm}
                        serviceName={service?.name || ''}
                        service={activeService}
                        isEditingState={isEditingState}
                        materialsName="priceLevelMaterials"
                        additionalOptionsName="priceLevelAdditionalOptions"
                        editMode={priceLevelId
                          ? { handleSubmit: handleServiceSubmit }
                          : undefined}
                        withPrices
                        hideToggle
                        onFormFieldsChange={(changedFields) => {
                          setFieldChangedValue(changedFields);
                        }}
                      />
                    ),
                  }))}
                />
              </Space>
            </ContentCard>
            <Typography.Title level={4}>Assign Practices</Typography.Title>
            <ContentCard>
              <AssignedPracticesTable priceLevel={priceLevelId} />
            </ContentCard>
          </Col>
        </Row>
      </DrawerModal>
      <Loading absolute visible={drawerOpen && !isPriceLevelLoaded} />
    </div>
  );
};

PriceLevelDrawer.defaultProps = {
  priceLevelId: undefined,
  onReload: undefined,
  practiceId: undefined,
};

const mapStateToProps = (state: RootState) => ({
  priceLevel: state[moduleName].priceLevel,
  loading: state[moduleName].loading,
  error: state[moduleName].error,
});

const mapDispatchToProps = {
  priceLevelUpdate: actionPriceLevelUpdate,
  priceLevelCreate: actionPriceLevelCreate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceLevelDrawer);
