import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { store } from './store';

import Routes from './routes';

import './App.less';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ConfigProvider locale={enUS}>
        <Routes />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
