import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Practices/RequestPage';
import { displayName } from '../../../config';

const PracticeRequest: React.FC = (): JSX.Element => {
  document.title = `${displayName}: Practice request`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default PracticeRequest;
